import { Auth, getAuth } from '@firebase/auth';
import {
    Analytics,
    AnalyticsCallOptions,
    CustomParams,
    EventParams,
    getAnalytics,
    initializeAnalytics,
    logEvent as logEventOrg,
    setAnalyticsCollectionEnabled,
} from 'firebase/analytics';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getCookieConsentValue } from 'react-cookie-consent';
import { cookieConsentName, isAnalyticsEnabled } from '~/app';
import { GameType, UserSubscription } from '~/server/src/dtos';
import { ControllerType } from '~/server/src/interfaces';

const config = {
    apiKey: 'AIzaSyAFibe7PoDx5Dbs2YOVopRzUcL3qw2X6u8',
    authDomain: 'turfmaster-2e795.firebaseapp.com',
    databaseURL: 'https://turfmaster-2e795.firebaseio.com',
    projectId: 'turfmaster-2e795',
    storageBucket: 'turfmaster-2e795.appspot.com',
    messagingSenderId: '527367093871',
    appId: '1:527367093871:web:4d23ad4ad87fe0f505579a',
    measurementId: 'G-2T23E1PDKS',
};

type SetupEventParams = CustomParams & {
    connectionType?: ControllerType;
    gameType?: GameType;
    horseCountPerPlayer?: number;
    playerCount?: number;
};

export interface FirebaseAnalytics {
    analytics: Analytics;

    setAnalyticsCollectionEnabled: (enabled: boolean) => void;

    logEvent(
        eventName: Parameters<typeof logEventOrg>[1],
        eventParams: Parameters<typeof logEventOrg>[2],
        options?: Parameters<typeof logEventOrg>[3],
    ): ReturnType<typeof logEventOrg>;

    /** Subscription */
    logEvent(
        eventName: 'begin_subscription_checkout',
        eventParams?: {
            subscription?: UserSubscription;
            [key: string]: unknown;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'cancel_subscription_checkout',
        eventParams?: {
            subscription?: UserSubscription;
            [key: string]: unknown;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'purchase_subscription',
        eventParams?: {
            subscription?: UserSubscription;
            [key: string]: unknown;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'delete_subscription',
        eventParams?: {
            subscription?: UserSubscription;
            [key: string]: unknown;
        },
        options?: AnalyticsCallOptions,
    ): void;

    /** Profile */
    logEvent(
        eventName: 'logout',
        eventParams?: {
            method?: EventParams['method'];
            [key: string]: unknown;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'update_username',
        eventParams?: {
            [key: string]: unknown;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'update_description',
        eventParams?: {
            [key: string]: unknown;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'update_country',
        eventParams?: {
            [key: string]: unknown;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'update_horse_name',
        eventParams?: {
            [key: string]: unknown;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'update_horse_color',
        eventParams?: {
            color?: number;
            [key: string]: unknown;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'update_horse_jockeyname',
        eventParams?: {
            [key: string]: unknown;
        },
        options?: AnalyticsCallOptions,
    ): void;

    /** Start / Join / Restore a game */
    logEvent(eventName: 'start_game', eventParams?: SetupEventParams, options?: AnalyticsCallOptions): void;

    logEvent(eventName: 'join_game', eventParams?: SetupEventParams, options?: AnalyticsCallOptions): void;

    logEvent(eventName: 'restore_game', eventParams?: SetupEventParams, options?: AnalyticsCallOptions): void;

    /** Game */
    logEvent(eventName: 'open_table', eventParams?: CustomParams, options?: AnalyticsCallOptions): void;
    logEvent(eventName: 'open_worldRankings', eventParams?: CustomParams, options?: AnalyticsCallOptions): void;
    logEvent(eventName: 'show_statistics', eventParams?: CustomParams, options?: AnalyticsCallOptions): void;

    logEvent(
        eventName: 'show_horse_info',
        eventParams?: CustomParams & {
            isOwnHorse?: boolean;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(eventName: 'set_camera_auto', eventParams?: CustomParams, options?: AnalyticsCallOptions): void;

    logEvent(eventName: 'set_camera_manual', eventParams?: CustomParams, options?: AnalyticsCallOptions): void;

    logEvent(
        eventName: 'select_move_from_right_bar',
        eventParams?: CustomParams & {
            turnType?: number;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'select_move_from_board',
        eventParams?: CustomParams & {
            turnType?: number;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'use_bonuscards',
        eventParams?: CustomParams & {
            round?: number;
            turnType?: number;
            isCurrentOwnHorse?: boolean;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'begin_report',
        eventParams?: CustomParams & {
            round?: number;
            turnType?: number;
            gameType?: GameType;
            isCustom?: boolean;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'send_report',
        eventParams?: CustomParams & {
            round?: number;
            turnType?: number;
            gameType?: GameType;
            isCustom?: boolean;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'cancel_report',
        eventParams?: CustomParams & {
            round?: number;
            turnType?: number;
            gameType?: GameType;
            isCustom?: boolean;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'view_horse_cards',
        eventParams?: CustomParams & {
            round?: number;
            turnType?: number;
            gameType?: GameType;
            isCurrent?: boolean;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'use_fullscreen_mode',
        eventParams?: CustomParams & {
            isMobile?: boolean;
        },
        options?: AnalyticsCallOptions,
    ): void;

    logEvent(
        eventName: 'use_window_mode',
        eventParams?: CustomParams & {
            isMobile?: boolean;
        },
        options?: AnalyticsCallOptions,
    ): void;
}

let firebaseInstance: FirebaseApp | undefined = undefined;

export const getFirebase = (): FirebaseApp | undefined => {
    if (typeof window !== 'undefined') {
        if (firebaseInstance) return firebaseInstance;
        firebaseInstance = initializeApp(config);

        if (isAnalyticsEnabled) {
            try {
                initializeAnalytics(firebaseInstance);
            } catch (e) {
                console.log('firebase initialization error:', e);
            }
        }

        return firebaseInstance;
    }
    return undefined;
};

export const getFirebaseAuth = (): Auth | undefined => {
    const firebase = getFirebase();
    if (firebase) {
        const auth = getAuth(firebase);
        return auth;
    }
};

export const getFirebaseAnalytics = (): FirebaseAnalytics | undefined => {
    const firebase = getFirebase();
    if (firebase) {
        const analytics = getAnalytics(firebase);
        if (analytics) {
            const cookieConsentEnabled = getCookieConsentValue(cookieConsentName) === 'true';
            setAnalyticsCollectionEnabled(analytics, cookieConsentEnabled);
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return {
            analytics,
            setAnalyticsCollectionEnabled: (enabled: boolean) => setAnalyticsCollectionEnabled(analytics, enabled),
            logEvent: (...params) => {
                if (analytics) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    logEventOrg(analytics, ...params);
                }
            },
        };
    }
};
