import { Unchecked, UserSubscription } from '.';

export const getUserSubscriptionForPlanId = (planId: string): UserSubscription => {
    if (planId === 'turfmaster_v1_membership_premium') {
        return UserSubscription.Premium;
    }

    return UserSubscription.Basic;
};

export const getPlanIdForUserSubscription = (userSubscription: UserSubscription): string => {
    if (userSubscription === UserSubscription.Premium) {
        return 'turfmaster_v1_membership_premium';
    }

    return '';
};

export const getPriceForUserSubscription = (userSubscription: UserSubscription): string => {
    if (userSubscription === UserSubscription.Premium) {
        return '5';
    }

    return '';
};

// currency codes according to https://en.wikipedia.org/wiki/ISO_4217
export enum CurrenciesAvailable {
    EUR = 'EUR',
    USD = 'USD',
}

export interface SubscriptionOverviewItem {
    subscription: UserSubscription;
    price: string;
    currency: CurrenciesAvailable;
}

export interface PaymentInitClientResult {
    clientToken: string;
    subscriptionOverview: SubscriptionOverviewItem[];
}

export interface PaymentCreateSubscriptionRequest {
    paymentMethodNonce: string;
    subscription: UserSubscription;
}

export const isPaymentCreateSubscriptionRequest = (
    uncheckedReq?: Unchecked<PaymentCreateSubscriptionRequest>,
): uncheckedReq is PaymentCreateSubscriptionRequest => {
    const typedReq = uncheckedReq as PaymentCreateSubscriptionRequest | undefined;
    return (
        typedReq !== undefined &&
        typedReq !== null &&
        typeof typedReq === 'object' &&
        typeof typedReq.paymentMethodNonce === 'string' &&
        Object.values(UserSubscription).includes(typedReq.subscription) &&
        typedReq.paymentMethodNonce !== ''
    );
};

export interface PaymentCreateSubscriptionResponse {
    message: string;
    subscriptionId: string;
}

export interface PaymentDeleteSubscriptionRequest {
    subscription: UserSubscription;
}

export const isPaymentDeleteSubscriptionRequest = (
    uncheckedReq?: Unchecked<PaymentDeleteSubscriptionRequest>,
): uncheckedReq is PaymentDeleteSubscriptionRequest => {
    const typedReq = uncheckedReq as PaymentDeleteSubscriptionRequest | undefined;
    return (
        typedReq !== undefined &&
        typedReq !== null &&
        typeof typedReq === 'object' &&
        Object.values(UserSubscription).includes(typedReq.subscription)
    );
};
