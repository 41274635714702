import { useMutation } from '@tanstack/react-query';
import {
    DebugSetUserRoleRequest,
    DebugSetUserSubscriptionRequest,
    DebugSetUserToplistLeagueRequest,
} from '~/server/src/dtos';
import { accountCacheKey, getFn, MessageResponse, postFn, queryClient, RequestError, usersCacheKey } from './api';

export const getDebug = `/debug`;
export const getClearDatabase = `${getDebug}/clearDatabase`;
export const getClearDatabaseLocal = `${getDebug}/clearDatabaseLocal`;
export const getClearDatabaseFirebase = `${getDebug}/clearDatabaseFirebase`;
export const postSetUserRole = `${getDebug}/setUserRole`;
export const postSetUserToplistLeague = `${getDebug}/setUserToplistLeague`;
export const postSetUserSubscription = `${getDebug}/setUserSubscription`;
export const getUpdateAllRankSilver = `${getDebug}/updateAllRankSilver`;
export const getUpdateAllRankGold = `${getDebug}/updateAllRankGold`;

export const useClearDatabase = () => {
    return useMutation<MessageResponse, RequestError>((args) => getFn(getClearDatabase, args), {
        onSuccess: () => {
            queryClient.invalidateQueries([usersCacheKey]);
            queryClient.invalidateQueries([accountCacheKey]);
        },
    });
};

export const useClearDatabaseLocal = () => {
    return useMutation<MessageResponse, RequestError>((args) => getFn(getClearDatabaseLocal, args), {
        onSuccess: () => {
            queryClient.invalidateQueries([usersCacheKey]);
            queryClient.invalidateQueries([accountCacheKey]);
        },
    });
};

export const useClearDatabaseFirebase = () => {
    return useMutation<MessageResponse, RequestError>((args) => getFn(getClearDatabaseFirebase, args), {
        onSuccess: () => {
            queryClient.invalidateQueries([usersCacheKey]);
            queryClient.invalidateQueries([accountCacheKey]);
        },
    });
};

export const useSetUserRole = () => {
    return useMutation<MessageResponse, RequestError, DebugSetUserRoleRequest>(
        (args) => postFn(postSetUserRole, args),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([usersCacheKey]);
                queryClient.invalidateQueries([accountCacheKey]);
            },
        },
    );
};

export const useSetUserToplistLeague = () => {
    return useMutation<MessageResponse, RequestError, DebugSetUserToplistLeagueRequest>(
        (args) => postFn(postSetUserToplistLeague, args),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([usersCacheKey]);
                queryClient.invalidateQueries([accountCacheKey]);
            },
        },
    );
};

export const useSetUserSubscription = () => {
    return useMutation<MessageResponse, RequestError, DebugSetUserSubscriptionRequest>(
        (args) => postFn(postSetUserSubscription, args),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([usersCacheKey]);
                queryClient.invalidateQueries([accountCacheKey]);
            },
        },
    );
};

export const useUpdateAllRankSilver = () => {
    return useMutation<MessageResponse, RequestError>((args) => getFn(getUpdateAllRankSilver, args), {
        onSuccess: () => {
            queryClient.invalidateQueries([usersCacheKey]);
            queryClient.invalidateQueries([accountCacheKey]);
        },
    });
};

export const useUpdateAllRankGold = () => {
    return useMutation<MessageResponse, RequestError>((args) => getFn(getUpdateAllRankGold, args), {
        onSuccess: () => {
            queryClient.invalidateQueries([usersCacheKey]);
            queryClient.invalidateQueries([accountCacheKey]);
        },
    });
};
