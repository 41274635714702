import { useEffect } from 'react';
import { usePreviousBoolean } from 'react-hooks-use-previous';
import useKeyPressed from './useKeyPressed';

const useKeyPress = (target: number | ((key: number) => boolean), handler: () => void): void => {
    const keyPressed = useKeyPressed(target);
    const prevKeyPressed = usePreviousBoolean(keyPressed);
    useEffect(() => {
        if (keyPressed && !prevKeyPressed) handler();
    }, [keyPressed, handler, prevKeyPressed]);
};

export default useKeyPress;
