import React from 'react';
import { useDispatch } from 'react-redux';
import { useSnapshots } from '~/apis';
import { useLocaleNavigation } from '~/app';
import { startDebug } from '~/game';
import SnapshotItem from './SnapshotItem';

interface SnapshotListProps {
    onClose: () => void;
}

type CommonSnapshot = {
    message?: string;
    gameName?: string;
    playerCount?: number;
    horseCount?: number;
};

const commonSnapshots: CommonSnapshot[] = [
    { playerCount: 2, horseCount: 1 },
    { playerCount: 2, horseCount: 2 },
    { playerCount: 3, horseCount: 3 },
    { gameName: 'debugGameA', message: 'A: ...' },
    { gameName: 'debugGameB', message: 'B: ...' },
    { gameName: 'debugGameC', message: 'C: Erste Runde, Kurve vor dem Ziel' },
    { gameName: 'debugGameD', message: 'D: Zweite Runde beginnt, Kartenauswahl' },
    { gameName: 'debugGameE', message: 'E: Zweite Runde, Kurve vor dem Ziel, Würfelauswahl' },
    { gameName: 'debugGameF', message: 'F: Dritte Runde beginnt, Kartenauswahl, Hürdenrunde' },
    { gameName: 'debugGameG', message: 'G: Dritte Runde, Kurve vor dem Ziel, letzte Hürde, Würfelauswahl' },
    { gameName: 'debugGameH', message: 'H: Dritte Runde, ein Schritt vor dem Spielende' },
    { gameName: 'debugGameI', message: 'I: Turnier Bronze, Dritte Runde, ein Schritt vor dem Spielende' },
];

const SnapshotList = ({ onClose }: SnapshotListProps): JSX.Element => {
    const pathname = typeof window !== 'undefined' ? window.location.pathname : '';

    // replace every page excluding the start page which should not be overwritten while navigating
    const replaceNavigation = pathname.length > 1;

    const dispatch = useDispatch();
    const { navigate } = useLocaleNavigation();

    const { data: snapshots } = useSnapshots();

    const onStartCommonSnapshot = ({ gameName, playerCount = 3, horseCount = 2 }: CommonSnapshot) => {
        dispatch(startDebug({ gameName, playerCount, horseCount }));
        setTimeout(() => navigate('/freegame', { replace: replaceNavigation }), 1000);
        onClose();
    };

    const onStartSnapshot = (snapshotId: string, variant = 0) => {
        dispatch(startDebug({ gameName: `snapshot_${snapshotId}_${variant}` }));
        setTimeout(() => navigate('/freegame', { replace: replaceNavigation }), 1000);
        onClose();
    };

    return (
        <div className="mb-4">
            <h2 className="mb-2 font-bold">Typische Spielsituationen</h2>
            <div className="grid gap-2" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(20rem, 1fr))' }}>
                {commonSnapshots.map((snapshot, index) => {
                    const message =
                        snapshot.message ?? `${snapshot.playerCount ?? 0} Spieler | ${snapshot.horseCount ?? 0} Pferde`;
                    return (
                        <SnapshotItem {...{ message }} key={index} onStart={() => onStartCommonSnapshot(snapshot)} />
                    );
                })}
            </div>

            <h2 className="mt-4 mb-2 font-bold">Server Snapshots</h2>
            <div className="grid gap-2" style={{ gridTemplateColumns: 'repeat(auto-fill, minmax(20rem, 1fr))' }}>
                {snapshots && Array.isArray(snapshots)
                    ? snapshots.map(({ snapshotId, message, userAgent, userId, timestamp, gameState }) => (
                          <SnapshotItem
                              {...{ message, userAgent, userId, timestamp }}
                              key={snapshotId}
                              enablePrevState={gameState.statePrev !== undefined}
                              enablePrevPrevState={gameState.statePrevPrev !== undefined}
                              onStart={(variant) => onStartSnapshot(snapshotId, variant)}
                          />
                      ))
                    : null}
            </div>
        </div>
    );
};

export default SnapshotList;
