import { Link } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import { useUser } from '~/apis';

export interface Snapshot {
    message: string;
}

interface SnapshotProps extends Snapshot {
    className?: string;
    enablePrevState?: boolean;
    enablePrevPrevState?: boolean;
    onStart: (variant?: number) => void;
    userId?: string;
    userAgent?: string;
    timestamp?: string;
}

const SnapshotItem = ({
    className,
    message,
    enablePrevState,
    enablePrevPrevState,
    onStart,
    userId,
    userAgent,
    timestamp,
}: SnapshotProps): JSX.Element => {
    const { data: user } = useUser(userId);

    return (
        <div className={cn('relative flex flex-col p-2 border border-gray-400 rounded-md', className)}>
            <span className="text-xs font-thin">{userAgent}</span>

            {message && (
                <div className="pl-2 my-2 border-l-2 border-green-700">
                    <blockquote className="font-light">"{message}"</blockquote>
                    <cite>
                        <span className="font-bold">{user?.username}</span>{' '}
                        {timestamp && `- ${new Date(timestamp).toLocaleString()}`}
                    </cite>
                </div>
            )}

            <Link underline="hover" onClick={() => onStart(0)} className="cursor-pointer">
                Starte Snapshot
            </Link>
            {enablePrevState && (
                <Link underline="hover" onClick={() => onStart(1)}>
                    Starte Snapshot mit State t-1
                </Link>
            )}
            {enablePrevPrevState && (
                <Link underline="hover" onClick={() => onStart(2)}>
                    Starte Snapshot mit State t-2
                </Link>
            )}
        </div>
    );
};

export default SnapshotItem;
