import { RetryDelayValue } from '@tanstack/query-core/src/retryer';
import {
    QueryClient,
    useMutation as _useMutation,
    UseMutationOptions as _UseMutationOptions,
    UseMutationResult as _UseMutationResult,
    useQuery as _useQuery,
    UseQueryOptions as _UseQueryOptions,
    UseQueryResult as _UseQueryResult,
    DefaultOptions,
    MutationFunction,
    QueryFunction,
    QueryKey,
} from '@tanstack/react-query';
import axios, { AxiosError, AxiosRequestHeaders, Method } from 'axios';
import { config } from '~/app';

export const accountCacheKey = 'account';
export const authCacheKey = 'auth';
export const horsesCacheKey = 'horses';
export const usersCacheKey = 'users';
export const tableCacheKey = 'table';
export const toplistsCacheKey = 'toplists';
export const gamesCacheKey = 'games';
export const tournamentsCacheKey = 'tournament';
export const snapshotsCacheKey = `${gamesCacheKey}/snapshots`;
export const paymentCacheKey = 'payment';

export const axiosClient = axios.create({ baseURL: config.baseUrl });

export const requestFn = async <T>(
    method: Method,
    url: string,
    data?: unknown,
    headers?: AxiosRequestHeaders,
): Promise<T> => {
    try {
        const response = await axiosClient.request<T>({ method, url, data, headers });
        return response.data;
    } catch (e) {
        const axiosError = e as AxiosError<MessageResponse>;
        const status = axiosError.response?.status;
        const message = axiosError.response?.data?.message ?? axiosError.response?.statusText;
        if (axiosError.isAxiosError && status) {
            const requestError: RequestError = {
                status,
                message,
            };
            return Promise.reject(requestError);
        }
        throw e;
    }
};

export const getFn = <T>(url: string, data?: unknown, headers?: AxiosRequestHeaders) => {
    return requestFn<T>('GET', url, data, headers);
};

export const postFn = async <T>(url: string, data?: unknown, headers?: AxiosRequestHeaders) => {
    return requestFn<T>('POST', url, data, headers);
};

export const putFn = async <T>(url: string, data?: unknown, headers?: AxiosRequestHeaders) => {
    return requestFn<T>('PUT', url, data, headers);
};

export const deleteFn = async <T>(url: string, data?: unknown, headers?: AxiosRequestHeaders) => {
    return requestFn<T>('DELETE', url, data, headers);
};

export type RequestError = { status: number } & Partial<MessageResponse>;
export type MessageResponse = { message?: string };

export type UseQueryOptions<T> = _UseQueryOptions<T, RequestError>;

export type UseQueryResult<T> = _UseQueryResult<T, RequestError>;

export const useQuery = <T>(
    queryKey: QueryKey,
    queryFn: QueryFunction<T>,
    options?: UseQueryOptions<T>,
): UseQueryResult<T> => {
    return _useQuery<T, RequestError, T>(queryKey, queryFn, options);
};

export type UseMutationOptions<T = MessageResponse, V = void> = _UseMutationOptions<T, RequestError, V>;

export type UseMutationResult<T = MessageResponse, V = void> = _UseMutationResult<T, RequestError, V>;

export const useMutation = <T = MessageResponse, V = void>(
    muationFn: MutationFunction<T, V>,
    options?: UseMutationOptions<T, V>,
): UseMutationResult<T, V> => {
    return _useMutation(muationFn, options);
};

const retryDelay: RetryDelayValue<RequestError> = (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 30000);

type ShouldRetryFunction<TError = unknown> = (failureCount: number, error: TError) => boolean;
export const retry: ShouldRetryFunction<RequestError> = (failureCount, { status }): boolean => {
    if (status === 400) {
        console.log('blocked retry for "400 Bad Request"');
        return false;
    } else if (status === 401) {
        console.log('blocked retry for "401 Unauthorized"');
        return false;
    } else if (status === 404) {
        console.log('blocked retry for "404 Not Found"');
        return false;
    }
    if (failureCount <= 3) {
        console.log(`failureCount is ${failureCount}, retrying...`);
        return true;
    }
    console.log('stop retrying');
    return false;
};

const defaultOptions: DefaultOptions<RequestError> = {
    queries: {
        staleTime: 30000,
        keepPreviousData: true,
        cacheTime: 5 * 60 * 1000,
        retryDelay,
        retry,
    },
    mutations: {
        retryDelay,
        retry,
    },
};

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore // TODO: QueryClient does not offer possibility to customize error anymore
export const queryClient = new QueryClient({ defaultOptions });
