import {
    Horse,
    HorseChangeColorRequest,
    HorseChangeColorResponse,
    HorseChangeJockeyNameRequest,
    HorseChangeJockeyNameResponse,
    HorseChangeNameRequest,
    HorseChangeNameResponse,
} from '~/server/src/dtos';
import {
    accountCacheKey,
    getFn,
    horsesCacheKey,
    putFn,
    queryClient,
    useMutation,
    UseMutationOptions,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from './api';
import { useFirebaseAnalytics } from './useFirebase';

export const getHorse = (horseId: string): string => `/horses/${horseId}`;
export const putHorseChangeName = (horseId: string): string => `${getHorse(horseId)}/name`;
export const putHorseChangeColor = (horseId: string): string => `${getHorse(horseId)}/color`;
export const putHorseChangeJockeyName = (horseId: string): string => `${getHorse(horseId)}/jockeyName`;

export const useHorse = (horseId = '', options?: UseQueryOptions<Horse>): UseQueryResult<Horse> => {
    return useQuery<Horse>([horsesCacheKey, horseId], () => getFn(getHorse(horseId)), {
        ...options,
        enabled: !!horseId && (options?.enabled === undefined || options.enabled === true),
    });
};

export type HorseRequest<T> = { horseId: string } & T;

export const useChangeHorseName = (
    options?: UseMutationOptions<HorseChangeNameResponse, HorseRequest<HorseChangeNameRequest>>,
) => {
    const analytics = useFirebaseAnalytics();
    return useMutation<HorseChangeNameResponse, HorseRequest<HorseChangeNameRequest>>(
        ({ horseId, ...data }) => putFn(putHorseChangeName(horseId), data),
        {
            ...options,
            onSuccess: (...response) => {
                queryClient.invalidateQueries([horsesCacheKey]);
                queryClient.invalidateQueries([accountCacheKey]);
                options?.onSuccess?.(...response);
                analytics?.logEvent('update_horse_name');
            },
        },
    );
};

export const useChangeHorseColor = (
    options?: UseMutationOptions<HorseChangeColorResponse, HorseRequest<HorseChangeColorRequest>>,
) => {
    const analytics = useFirebaseAnalytics();
    return useMutation<HorseChangeColorResponse, HorseRequest<HorseChangeColorRequest>>(
        ({ horseId, ...data }) => putFn(putHorseChangeColor(horseId), data),
        {
            ...options,
            onSuccess: (...response) => {
                queryClient.invalidateQueries([horsesCacheKey]);
                queryClient.invalidateQueries([accountCacheKey]);
                options?.onSuccess?.(...response);
                analytics?.logEvent('update_horse_color', { color: response[0].color });
            },
        },
    );
};

export const useChangeHorseJockeyName = (
    options?: UseMutationOptions<HorseChangeJockeyNameResponse, HorseRequest<HorseChangeJockeyNameRequest>>,
) => {
    const analytics = useFirebaseAnalytics();
    return useMutation<HorseChangeJockeyNameResponse, HorseRequest<HorseChangeJockeyNameRequest>>(
        ({ horseId, ...data }) => putFn(putHorseChangeJockeyName(horseId), data),
        {
            ...options,
            onSuccess: (...response) => {
                queryClient.invalidateQueries([horsesCacheKey]);
                queryClient.invalidateQueries([accountCacheKey]);
                options?.onSuccess?.(...response);
                analytics?.logEvent('update_horse_jockeyname');
            },
        },
    );
};
