/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { createAction } from '@reduxjs/toolkit';
import { HorseSelectionData } from '~/components/HorseSelection';
import { GameMode } from '~/game';
import { GameType } from '~/server/src/dtos';
import { TournamentOverviewParsed } from './tournament/utils';

export interface CreateGameParameters {
    gameName: string;
    playerCount: number;
    horseCount: number;
    horseSelection: HorseSelectionData;
}

export interface JoinGameParameters {
    gameId: string;
    gameType: GameType;
    horseSelection: HorseSelectionData;
}

export interface JoinTournamentParameters {
    tournament: TournamentOverviewParsed;
    gameType: GameType;
    horseSelection: HorseSelectionData;
}

export interface RestoreGameParameters {
    mode: GameMode;
}

export const isRestoreGameParameters = (params: unknown): params is RestoreGameParameters => {
    return typeof (params as RestoreGameParameters).mode === 'string';
};

export const createFree = createAction<CreateGameParameters>('createFree');
export const joinFree = createAction<JoinGameParameters>('joinFree');
export const restoreGame = createAction<RestoreGameParameters>('restoreGame');
export const rejoinGame = createAction('rejoinGame');
export const joinTournament = createAction<JoinTournamentParameters>('joinTournament');
