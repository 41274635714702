/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GameOverview, GameType, TournamentOverview } from '~/server/src/dtos';

type RestoreState = 'none' | 'loading' | 'error' | 'success';

type RejoinState =
    | {
          type: 'none' | 'loading' | 'error';
      }
    | { type: 'success'; mode: 'training' | 'free' | 'tournament' };

export interface State {
    gameId?: string;
    gameName: string;
    gameType?: GameType;
    gameStartDate?: Date;
    games?: GameOverview[];
    tournaments?: TournamentOverview[];
    playerId: number;
    horseAmount: number;

    // flag to differentiate between new setup game and a reload of the page that should send a restore event
    isNewGame: boolean;

    // if restoring this is indicating the state of the restore, so that if it is failing game screen
    // could e.g. navigate back to the home screen or show an error message
    restoreState: RestoreState;
    rejoinState: RejoinState;
}

export const initialState: State = {
    gameName: '',
    playerId: -1,
    horseAmount: 0,
    isNewGame: false,
    restoreState: 'none',
    rejoinState: { type: 'none' },
};

const slice = createSlice({
    name: 'setup',
    initialState,
    reducers: {
        setGameId: (state: State, action: PayloadAction<string>) => {
            state.gameId = action.payload;
        },
        setGameName: (state: State, action: PayloadAction<string>) => {
            state.gameName = action.payload;
        },
        setGameType: (state: State, action: PayloadAction<GameType | undefined>) => {
            state.gameType = action.payload;
        },
        setGameStartDate: (state: State, action: PayloadAction<Date>) => {
            state.gameStartDate = action.payload;
        },
        setGames: (state: State, action: PayloadAction<GameOverview[]>) => {
            state.games = action.payload;
        },
        setTournaments: (state: State, action: PayloadAction<TournamentOverview[]>) => {
            state.tournaments = action.payload;
        },
        setPlayerId: (state: State, action: PayloadAction<number>) => {
            state.playerId = action.payload;
        },
        setHorseAmount: (state: State, action: PayloadAction<number>) => {
            state.horseAmount = action.payload;
        },
        setRestoreState: (state: State, action: PayloadAction<RestoreState>) => {
            state.restoreState = action.payload;
        },
        setRejoinState: (state: State, action: PayloadAction<RejoinState>) => {
            state.rejoinState = action.payload;
        },
    },
    extraReducers: {
        startTraining: (state: State, action): State => ({ ...initialState, isNewGame: true }),
        startFree: (state: State, action): State => ({ ...initialState, isNewGame: true }),
        startTournament: (state: State, action): State => ({ ...initialState, isNewGame: true }),
        startDebug: (state: State, action): State => ({ ...initialState, isNewGame: true }),
    },
});

const setupActions = slice.actions;
const reducer = slice.reducer;
const name = slice.name;

export { setupActions as actions, reducer, name };
export default slice;
