/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { Checkbox, FormControlLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React, { useState } from 'react';
import { useTranslation } from '~/app';

interface Props {
    open: boolean;
    onClose: () => void;
    onAccept: (dontAskAgain: boolean) => void;
}

const FullscreenDialog = ({ open, onClose, onAccept }: Props): JSX.Element => {
    const { t } = useTranslation();
    const [isDontAskAgainSelected, setIsDontAskAgainSelected] = useState(false);
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="fullscreenDialogTitle">
            <DialogTitle id="fullscreenDialogTitle">{t('settings_fullscreenDialogTitle')}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t('settings_fullscreenDialogContent')}</DialogContentText>
                <FormControlLabel
                    control={
                        <Checkbox
                            value={isDontAskAgainSelected}
                            checked={isDontAskAgainSelected}
                            onChange={({ target: { checked } }) => setIsDontAskAgainSelected(checked)}
                        />
                    }
                    label={t('settings_fullscreenDialogDisable')}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {t('settings_fullscreenDialogCancel')}
                </Button>
                <Button onClick={() => onAccept(isDontAskAgainSelected)} color="primary">
                    {t('settings_fullscreenDialogAccept')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FullscreenDialog;
