exports.components = {
  "component---src-app-404-tsx": () => import("./../../../src/app/404.tsx" /* webpackChunkName: "component---src-app-404-tsx" */),
  "component---src-debug-debug-tsx": () => import("./../../../src/debug/Debug.tsx" /* webpackChunkName: "component---src-debug-debug-tsx" */),
  "component---src-end-end-tsx": () => import("./../../../src/end/End.tsx" /* webpackChunkName: "component---src-end-end-tsx" */),
  "component---src-game-game-tsx": () => import("./../../../src/game/Game.tsx" /* webpackChunkName: "component---src-game-game-tsx" */),
  "component---src-play-play-tsx": () => import("./../../../src/play/Play.tsx" /* webpackChunkName: "component---src-play-play-tsx" */),
  "component---src-profile-profile-tsx": () => import("./../../../src/profile/Profile.tsx" /* webpackChunkName: "component---src-profile-profile-tsx" */),
  "component---src-setup-free-setup-free-tsx": () => import("./../../../src/setup/free/SetupFree.tsx" /* webpackChunkName: "component---src-setup-free-setup-free-tsx" */),
  "component---src-setup-tournament-setup-tournament-tsx": () => import("./../../../src/setup/tournament/SetupTournament.tsx" /* webpackChunkName: "component---src-setup-tournament-setup-tournament-tsx" */),
  "component---src-setup-training-setup-training-tsx": () => import("./../../../src/setup/training/SetupTraining.tsx" /* webpackChunkName: "component---src-setup-training-setup-training-tsx" */),
  "component---src-start-start-tsx": () => import("./../../../src/start/Start.tsx" /* webpackChunkName: "component---src-start-start-tsx" */),
  "component---src-toplist-global-toplist-tsx": () => import("./../../../src/toplist/GlobalToplist.tsx" /* webpackChunkName: "component---src-toplist-global-toplist-tsx" */),
  "component---src-toplist-table-tsx": () => import("./../../../src/toplist/Table.tsx" /* webpackChunkName: "component---src-toplist-table-tsx" */)
}

