/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import React, { memo } from 'react';
import { useFirebaseAnalytics } from '~/apis/useFirebase';
import {
    horseColorIndex,
    HorseColorValues,
    selectCurrentHorse,
    selectCurrentState,
    selectHorse,
    selectHorseCardDeckCurrentMeta,
    selectHorseDropped,
    selectHorseHandi,
    selectHorseRanksCorrected,
    selectHorses,
    selectIsOwnHorseIndex,
    selectPlayerCountry,
    selectPlayerName,
    selectRound,
    selectTurnDicesPlayerId,
    State,
    useSelector,
} from '~/app';
import CardDeckIcon from '~/components/CardDeckIcon';
import HorseIcon from '~/components/HorseIcon';
import HorseInfo from '~/components/HorseIcon/HorseInfo';
import Turfmaster from '~/server/src/tmmodel';

interface BarTopHorseProps {
    className?: string;
    horseIndexOfPlayer: number;
    horseIndex: number;
    preview?: boolean;
}

/**
 * Each horse in the top bar is represented by this separate React Component to enable rerender on reference changes
 * on horse props like color / rank / handi etc.
 */
const BarTopHorse = ({ className, horseIndexOfPlayer, horseIndex, preview: isPreview = false }: BarTopHorseProps) => {
    const analytics = useFirebaseAnalytics();

    const currentState = useSelector(selectCurrentState);
    const currentRound = useSelector(selectRound);
    const turnDicesPlayerId = useSelector(selectTurnDicesPlayerId);
    const currentHorse = useSelector(selectCurrentHorse);
    const horses = useSelector(selectHorses);
    const horse = useSelector((state: State) => selectHorse(state, horseIndex));
    const horseColor = isPreview ? horseColorIndex.BLACK : horse ? horse.color : 0;
    const horseRealColor = horse?.realColor ?? -1;
    const horseName = isPreview ? '' : horse?.name ?? '';
    const { cardsCount, hasBonuscards } = useSelector((state) => selectHorseCardDeckCurrentMeta(state, horseIndex));
    const horsePlayerId = horse ? horse.playerId : -1;
    const isDropped = useSelector((state: State) => selectHorseDropped(state, horseIndex));
    const { totalRank } = useSelector((state) => selectHorseRanksCorrected(state, horseIndex));
    const horseHandi = useSelector((state: State) => selectHorseHandi(state, horseIndex));
    const playerName = useSelector((state: State) => selectPlayerName(state, horsePlayerId));
    const playerCountry = useSelector((state: State) => selectPlayerCountry(state, horsePlayerId));

    const isOwnHorse = useSelector((state) => selectIsOwnHorseIndex(state, horseIndex));

    const turnDicesPlayerHorsesIndex = horses
        .filter((horse) => horse.playerId === turnDicesPlayerId)
        .map((horse) => horses.indexOf(horse));

    const selected =
        // prevent horse being selected while last turn in round is shown with a delay
        currentState !== Turfmaster.stateMachine.SELECTMOVEOPP &&
        // prevent horse being selected before first and after last round
        currentRound >= 0 &&
        currentRound < Turfmaster.rounds &&
        (currentState === Turfmaster.stateMachine.SELECTDICES
            ? turnDicesPlayerHorsesIndex.includes(horseIndex)
            : horseIndex === currentHorse?.index) && // TODO: undefined check has to be forced
        !isDropped &&
        !isPreview;

    return (
        <div
            key={HorseColorValues[horseColor] + horseIndexOfPlayer}
            className="relative flex items-center self-start justify-around">
            <HorseIcon
                {...{ color: horseColor, isDropped, isPreview, selected }}
                className={className}
                onOpenToolTip={() => analytics?.logEvent('show_horse_info', { isOwnHorse })}>
                <HorseInfo
                    {...{ playerName, horseName, isPreview, isDropped }}
                    country={playerCountry}
                    horseTotalRank={currentRound > 0 ? totalRank : undefined}
                    handicap={Turfmaster.handi[horseHandi]}
                    jockeyName={horse?.jockeyName}
                    {...(horseColor !== horseRealColor && { horseRealColor: HorseColorValues[horseRealColor] })}
                />
            </HorseIcon>

            {cardsCount !== undefined ? (
                <div className="-bottom-3 absolute right-0 flex justify-center bg-white rounded-md shadow-2xl pointer-events-none">
                    <div className="flex p-1 space-x-0.5 items-center text-xs font-light">
                        <CardDeckIcon className="w-3 h-3" />
                        <span>
                            {cardsCount}
                            {hasBonuscards && '+'}
                        </span>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default memo(BarTopHorse);
