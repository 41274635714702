import { TournamentOverview, TournamentRequest } from '~/server/src/dtos';
import {
    getFn,
    MessageResponse,
    postFn,
    queryClient,
    tournamentsCacheKey,
    useMutation,
    UseMutationOptions,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from './api';

export const getTournaments = `/tournaments`;
export const getTournament = (tournamentid: string) => `${getTournaments}/${tournamentid}`;
export const postTournament = getTournaments;

export const useTournaments = (
    options: UseQueryOptions<TournamentOverview[]> = {},
): UseQueryResult<TournamentOverview[]> => {
    return useQuery<TournamentOverview[]>([tournamentsCacheKey], () => getFn(getTournaments), options);
};

export const useTournament = (
    tournamentId = '',
    options?: UseQueryOptions<TournamentOverview>,
): UseQueryResult<TournamentOverview> => {
    return useQuery<TournamentOverview>([tournamentsCacheKey], () => getFn(getTournament(tournamentId)), {
        ...options,
        enabled: !!tournamentId && (options?.enabled === undefined || options.enabled === true),
    });
};

export const useCreateTournament = (options?: UseMutationOptions<MessageResponse, TournamentRequest>) => {
    return useMutation<MessageResponse, TournamentRequest>((data) => postFn(postTournament, data), {
        ...options,
        onSuccess: (...response) => {
            queryClient.invalidateQueries([tournamentsCacheKey]);
            options?.onSuccess?.(...response);
        },
    });
};
