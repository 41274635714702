/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { MobileView } from 'react-device-detect';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from '~/apis';
import {
    FullscreenContext,
    selectConnected,
    selectCurrentState,
    selectGameName,
    selectIsNewGame,
    selectRestoreState,
    useLocaleNavigation,
    useTranslation,
} from '~/app';
import { localGameSnapshotKey } from '~/app/controllerLocalWrapper';
import Board from '~/board';
import FullscreenDialog from '~/components/FullscreenDialog';
import SEO from '~/components/SEO';
import Overlay from '~/overlay';
import { UserSubscription } from '~/server/src/dtos';
import Turfmaster from '~/server/src/tmmodel';
import { restoreGame } from '~/setup';
import * as styles from './Game.module.css';
import { GameMode } from '.';

const Game = () => {
    const dispatch = useDispatch();
    const { t, urlPath } = useTranslation();
    const { data: account, isLoading: isAccountLoading } = useAccount();
    const { navigate } = useLocaleNavigation();
    const currentState = useSelector(selectCurrentState);
    const gameName = useSelector(selectGameName);
    const connected = useSelector(selectConnected);
    const isNewGame = useSelector(selectIsNewGame);
    const restoreState = useSelector(selectRestoreState);

    const { isFullscreen, setIsFullscreen, dontAskAgain, setDontAskAgain } = useContext(FullscreenContext);
    const [isFullscreenDialogOpen, setIsFullscreenDialogOpen] = useState(!isFullscreen && !dontAskAgain);

    const mode = useMemo<GameMode>(() => {
        switch (urlPath) {
            case '/training':
                return 'training';
            case '/freegame':
                return 'free';
            case '/tournament':
                return 'tournament';
            default:
                return 'training';
        }
    }, [urlPath]);

    const hasCustomName = mode === 'free' || mode === 'tournament';

    useEffect(() => {
        if (currentState === Turfmaster.stateMachine.ENDGAME) {
            // remove persisted training state when game is finished
            if (mode === 'training') localStorage.removeItem(localGameSnapshotKey);
            navigate('/end', { replace: true });
        }
    }, [currentState, navigate, mode]);

    useEffect(() => {
        if (connected) console.log('CONNECTED', { currentState });
        else {
            if (process.env.NODE_ENV === 'development') console.log('NOT CONNECTED', { currentState });
        }
    }, [connected, currentState, dispatch, isNewGame, mode]);

    useEffect(() => {
        // if is new setup game don't send a restore, only if page was visited without a restore and restore is not ongoing
        // wait for auth to be ready before restoring
        if (!isNewGame && restoreState === 'none' && (mode === 'training' || account !== undefined)) {
            dispatch(restoreGame({ mode }));
        }
    }, [account, dispatch, isNewGame, mode, restoreState]);

    useEffect(() => {
        // if restore was unsuccessful navigate to home
        if (!isNewGame && restoreState === 'error') navigate('/', { replace: true });
    }, [isNewGame, navigate, restoreState]);

    useEffect(() => {
        // check if account exists, loading state of account is allowed
        if (!account && !isAccountLoading) {
            // all other modes except training need account, so redirect if not available
            if (mode !== 'training') {
                console.log('not training and no account, redirect to home');
                navigate('/', { replace: true });
            }
        } else if (account && mode === 'tournament') {
            // if account is available but subscription in tournament mode is invalid redirect as well
            const subscriptionValid =
                account?.subscription !== UserSubscription.Premium ||
                (account?.subscriptionValidUntil &&
                    new Date(account?.subscriptionValidUntil).getTime() >= new Date().getTime());
            if (!subscriptionValid) {
                console.log('tournament mode but no valid subscription, redirect to home');
                navigate('/', { replace: true });
            }
        } else if (account && mode === 'free') {
            // if account is available but subscription in free mode is invalid redirect as well
            const subscriptionValid =
                account?.subscription !== UserSubscription.Premium ||
                (account?.subscriptionValidUntil &&
                    new Date(account?.subscriptionValidUntil).getTime() >= new Date().getTime());
            if (!subscriptionValid) {
                console.log('free mode but no valid subscription, redirect to home');
                navigate('/', { replace: true });
            }
        }
    }, [account, isAccountLoading, navigate, mode]);

    return (
        <div className={styles.Root}>
            <SEO
                title={
                    hasCustomName && gameName && gameName.length > 0
                        ? gameName
                        : t(
                              mode === 'training'
                                  ? 'game_titleTraining'
                                  : mode === 'tournament'
                                  ? 'game_titleTournament'
                                  : 'game_titleFree',
                          )
                }
            />

            <Board />

            <Overlay
                onCloseHandler={() => {
                    // remove persisted training state when user exits game
                    if (mode === 'training') localStorage.removeItem(localGameSnapshotKey);
                    navigate('/', { replace: true });
                }}
            />

            {!isFullscreen && !dontAskAgain && (
                <MobileView>
                    <FullscreenDialog
                        open={isFullscreenDialogOpen}
                        onClose={() => {
                            setIsFullscreenDialogOpen(false);
                        }}
                        onAccept={(dontAskAgain) => {
                            setIsFullscreenDialogOpen(false);
                            setIsFullscreen(true);
                            if (dontAskAgain) setDontAskAgain(true);
                        }}
                    />
                </MobileView>
            )}
        </div>
    );
};

export default memo(Game);
