/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { PayloadAction } from '@reduxjs/toolkit';
import { isArray } from 'lodash';
import { EventChannel, Task } from 'redux-saga';
import { all, cancel, delay, fork, put, take } from 'redux-saga/effects';
import { getFirebaseAnalytics } from '~/apis/firebase';
import { config } from '~/app';
import { setConnected, setCurrentState } from '~/game';
import { TournamentOverview } from '~/server/src/dtos';
import { Horse, JoinTournamentRequest, Player } from '~/server/src/dtos/controller';
import { Controller, View } from '~/server/src/interfaces';
import Turfmaster from '~/server/src/tmmodel';
import { joinTournament, JoinTournamentParameters } from '../actions';
import { actions } from '../state';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* sagaSetupTournamentPullGames(view: View, controller: Controller, channel: EventChannel<any>) {
    while (true) {
        controller.receiveMsg(Turfmaster.stateMachine.GETTOURNAMENTS, {
            view,
        });
        const { state, params } = yield take(channel);
        if (state === Turfmaster.stateMachine.GETTOURNAMENTS) {
            console.log('controller => client:', 'GETTOURNAMENTS', params);
            const tournaments = (isArray(params.tournaments) ? params.tournaments : []) as TournamentOverview[];
            yield put(actions.setTournaments(tournaments));
        }
        yield delay(config.tournamentPullInterval);
    }
}

function* sagaSetupTournament(
    view: View,
    controller: Controller,
    channel: EventChannel<{ state: number; params: unknown }>,
) {
    if (!view || !controller || !channel) return;

    const analytics = getFirebaseAnalytics();

    // fork a separate process constantly pulling tournaments to join
    const pullGamesTask: Task = yield fork(sagaSetupTournamentPullGames, view, controller, channel);

    try {
        // wait for a redux action requesting game creation or join
        const joinTournamentRequest: PayloadAction<JoinTournamentParameters> = yield take(joinTournament.type);

        yield cancel(pullGamesTask);

        const { tournament, gameType, horseSelection } = joinTournamentRequest.payload;
        const player: Player = {
            horses: horseSelection.map<Horse>(({ horseId }) => ({
                horseid: horseId,
            })),
        };

        const joinTournamentParams: JoinTournamentRequest = {
            tournamentid: tournament.tournamentId,
            player,
            view,
        };
        console.log('client => controller: JOINTOURNAMENT', joinTournamentParams);
        controller.receiveMsg(Turfmaster.stateMachine.JOINTOURNAMENT, joinTournamentParams);

        while (true) {
            const { state, params } = yield take(channel);
            yield put(setCurrentState(state));

            if (state === Turfmaster.stateMachine.JOINGAME) {
                console.log('controller => client:', 'JOINGAME', params);

                const gameId = params.gameid;
                const game = controller.getGame();
                const board = controller.getBoard();

                // make sure join was successful and game / board are valid
                if (!params.error && game && board) {
                    const nowTime = new Date().getTime();
                    const nextStartDate = tournament.startingTimes.find((date) => date.getTime() > nowTime);

                    yield all([
                        put(actions.setGameId(gameId)),
                        put(actions.setGameType(gameType)),
                        ...(nextStartDate ? [put(actions.setGameStartDate(nextStartDate))] : []),
                        put(actions.setPlayerId(params.playerid)),
                        put(setConnected(true)),
                    ]);

                    // make sure game and board are valid
                    if (!controller.getGame() || !controller.getBoard()) {
                        console.log('sagaSetup exit join after game creation because game or board is not defined');
                        return;
                    }

                    analytics?.logEvent('join_game', {
                        connectionType: controller.getType(),
                        gameType,
                        playerCount: config.tournamentPlayerCount,
                        horseCountPerPlayer: config.tournamentPlayerCount,
                    });

                    // everything worked so notify mainSaga that game started successfully
                    // join game was successful so break the 'endless' loop
                    return true;
                }
            }
        }
    } finally {
        // make sure pullGamesTask cancellation is guaranteed even if this saga is interrupted
        yield cancel(pullGamesTask);
    }
}

export default sagaSetupTournament;
