/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import cn from 'classnames';
import React from 'react';
import Card, { CardValue } from '../bar-bottom/Card';

interface CardRowProps {
    cards: CardValue[];
    isSelected: (card: { value: CardValue; index: number }) => boolean;
    onCardClicked: (card: { value: CardValue; index: number }) => void;
    onBonusCardClicked?: () => void;
    removedCards?: CardValue[];
    bonusCardsEnabled?: boolean;
    color: number;
    cardWidth: number;
}

const CardRow = ({
    cards,
    isSelected,
    onCardClicked,
    removedCards = [],
    bonusCardsEnabled = false,
    color,
    cardWidth,
    onBonusCardClicked,
}: CardRowProps): JSX.Element => (
    <>
        {cards.map((value, index) => (
            <Card
                key={index}
                value={value}
                color={color}
                className={cn(
                    'transition-all cursor-pointer transform-gpu active:scale-95',
                    isSelected({ value, index }) ? 'opacity-100' : 'opacity-30 hover:opacity-60',
                )}
                style={{ width: `${cardWidth}%` }}
                onClick={() => onCardClicked({ value, index })}
            />
        ))}
        {removedCards.length > 0 && (
            <div
                className="flex flex-row items-center mx-2"
                style={{
                    width: `${cardWidth * removedCards.length}%`,
                }}>
                {removedCards.map((card, index) => (
                    <Card
                        key={index}
                        value={card}
                        color={color}
                        className="transition-all cursor-pointer active:scale-95 opacity-30 hover:opacity-60 transform-gpu"
                        removed
                    />
                ))}
            </div>
        )}
        {bonusCardsEnabled && (
            <Card
                key={'bonus'}
                value={0}
                className="ml-4 transition-all opacity-100 cursor-pointer active:scale-95 transform-gpu"
                color={color}
                onClick={onBonusCardClicked}
                style={{ width: `${cardWidth}%` }}
            />
        )}
    </>
);

export default CardRow;
