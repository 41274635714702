import { useMemo } from 'react';
import { useAuthDispatch, useAuthState } from '~/auth';
import {
    AccountChangeCountryRequest,
    AccountChangeCountryResponse,
    AccountChangeDescriptionRequest,
    AccountChangeDescriptionResponse,
    AccountChangeUserNameRequest,
    AccountChangeUserNameResponse,
    UserInternal,
} from '~/server/src/dtos';
import { accountCacheKey, getFn, putFn, queryClient, useMutation, useQuery, UseQueryOptions } from './api';
import { useFirebaseAnalytics } from './useFirebase';

export const getAccount = `/account`;
export const putAccountUsername = `${getAccount}/username`;
export const putAccountDescription = `${getAccount}/description`;

export const useAccount = (options?: UseQueryOptions<UserInternal>) => {
    const { isAuthorized, state } = useAuthState();
    const authDispatch = useAuthDispatch();

    const query = useQuery<UserInternal>([accountCacheKey], () => getFn(getAccount), {
        ...options,
        enabled: isAuthorized && (options?.enabled === undefined || options.enabled === true),
        onError: (error) => {
            // if user id could not be found something is wrong / db was cleared so logout
            if (error.status === 404) {
                authDispatch({ type: 'logout' });
            }
        },
    });
    return useMemo(
        () => ({ ...query, isLoading: (isAuthorized || state === 'initializing') && query.isLoading }),
        [isAuthorized, query, state],
    );
};

export const prefetchAccount = async () => {
    return await queryClient.prefetchQuery([accountCacheKey], () => getFn(getAccount));
};

export const useChangeUsername = () => {
    const analytics = useFirebaseAnalytics();
    return useMutation<AccountChangeUserNameResponse, AccountChangeUserNameRequest>(
        (data) => putFn(putAccountUsername, data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([accountCacheKey]);
                analytics?.logEvent('update_username');
            },
        },
    );
};

export const useChangeDescription = () => {
    const analytics = useFirebaseAnalytics();
    return useMutation<AccountChangeDescriptionResponse, AccountChangeDescriptionRequest>(
        (data) => putFn(putAccountDescription, data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([accountCacheKey]);
                analytics?.logEvent('update_description');
            },
        },
    );
};

export const useChangeCountry = () => {
    const analytics = useFirebaseAnalytics();
    return useMutation<AccountChangeCountryResponse, AccountChangeCountryRequest>(
        (data) => putFn(putAccountDescription, data),
        {
            onSuccess: () => {
                queryClient.invalidateQueries([accountCacheKey]);
                analytics?.logEvent('update_country');
            },
        },
    );
};
