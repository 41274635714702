import { User } from '~/server/src/dtos';
import { getFn, useQuery, UseQueryOptions, UseQueryResult, usersCacheKey } from './api';

export const getUser = (userId: string): string => `/users/${userId}`;

export const useUser = (userId = '', options?: UseQueryOptions<User>): UseQueryResult<User> => {
    return useQuery<User>([usersCacheKey, userId], () => getFn(getUser(userId)), {
        ...options,
        enabled: !!userId && (options?.enabled === undefined || options.enabled === true),
    });
};
