/* eslint-disable import/no-named-as-default-member */
import { DialogContent, Slide, useMediaQuery, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import React, { forwardRef, lazy } from 'react';
import { useTranslation } from '~/app';

/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

interface LoginProps {
    modalOpen: boolean;
    onLoginSuccess: () => void;
    onRegistrationRequired: (email: string, password: string, firebaseIdToken?: string) => void;
    onLoginCancelled: () => void;
}

const Transition = forwardRef(function Transition(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const LoginFirebaseLazy = lazy(() => import('./LoginFirebase'));

const Login = ({ modalOpen, onLoginSuccess, onRegistrationRequired, onLoginCancelled }: LoginProps): JSX.Element => {
    const { t } = useTranslation();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            open={modalOpen}
            TransitionComponent={Transition}
            onClose={onLoginCancelled}
            keepMounted
            aria-labelledby="loginDialogTitle"
            {...{ fullScreen }}>
            <DialogTitle id="loginDialogTitle">{t('start_accountLoginDialogTitle')}</DialogTitle>
            <DialogContent>
                {typeof window !== 'undefined' && (
                    <React.Suspense fallback={<div />}>
                        <LoginFirebaseLazy {...{ onLoginSuccess, onRegistrationRequired }} />
                    </React.Suspense>
                )}
            </DialogContent>
        </Dialog>
    );
};

export default Login;
