/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { Coordinates, Position, SetEntityPropertyParams } from 'app';
import { startDebug, startFree, startTournament, startTraining } from '~/game';
import {
    HorseField,
    moveCamera,
    MoveCameraPayload,
    setCameraMode,
    setHorse,
    setHorseField,
    setHorses,
    setHorsesField,
    setHurdles,
    setMoves,
} from './actions';

/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

export interface Horse {
    // index of horse in controller horse array
    index: number;

    // index of player in controller player array
    playerId: number;

    // in-game color of horse, can differ between clients
    color: number;

    // jockey name of horse set by server in remote game
    jockeyName: string;

    // real color if available for real horses before assigning in-game color
    // if not set or computer horse this is always -1
    realColor: number;

    // horse name set by server in remote game
    name: string;

    // date string of the next date when this horse gets replaced by a bot or skipped depending on game mode
    // will be used to create progress circle animation
    nextTimeoutDateString?: string;
}

export type CameraMode = 'fixed' | 'follow';

export interface CameraState extends Position {
    mode: CameraMode;
}

export interface BoardState {
    hurdles: Coordinates[][];
    moves: Coordinates[][];
    horses: Horse[];
    horseFields: HorseField[];
    camera: CameraState;
}

export const initialBoardState: BoardState = {
    hurdles: [],
    moves: [],
    horses: [],
    horseFields: [],
    camera: { x: 0.5, y: 0.5, scale: 0.6, rotation: 0, mode: 'follow' },
};

export default createReducer(initialBoardState, {
    [startTraining.type]: () => initialBoardState,
    [startFree.type]: () => initialBoardState,
    [startDebug.type]: () => initialBoardState,
    [startTournament.type]: () => initialBoardState,

    [setHurdles.type]: (state, action: PayloadAction<Coordinates[][]>) => {
        state.hurdles = action.payload;
    },
    [setMoves.type]: (state, action: PayloadAction<Coordinates[][]>) => {
        state.moves = action.payload;
    },
    [setHorses.type]: (state, action: PayloadAction<Horse[]>) => {
        state.horses = action.payload;
    },
    [setHorse.type]: (state, action: PayloadAction<Horse>) => {
        const horse = action.payload;
        state.horses = state.horses.map((h) => (h.index === horse.index ? horse : h));
    },
    [setHorsesField.type]: (state, action: PayloadAction<HorseField[]>) => {
        const fields = action.payload;
        state.horseFields = fields.map((fieldPayload) => {
            const { x, y, move } = fieldPayload;
            const field = { x, y, move } as HorseField;
            if (!field.move) field.move = [{ x: field.x, y: field.y }];
            return field;
        });
    },
    [setHorseField.type]: (state, action: PayloadAction<SetEntityPropertyParams<HorseField>>) => {
        const field = action.payload.value;
        if (!field.move) field.move = [{ x: field.x, y: field.y }];
        state.horseFields[action.payload.index] = field;
    },
    [moveCamera.type]: (state, action: PayloadAction<MoveCameraPayload>) => {
        state.camera.x = action.payload.x;
        state.camera.y = action.payload.y;
        state.camera.scale = action.payload.scale;
    },
    [setCameraMode.type]: (state, action: PayloadAction<CameraMode>) => {
        state.camera.mode = action.payload;
    },
});
