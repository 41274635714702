/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { Fab } from '@mui/material';
import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from '~/app';
import CardDeckIcon from './CardDeckIcon';
import HorseIcon from './HorseIcon';

interface ViewSwitchButtonProps {
    isMinimized: boolean;
    setMinimized: Dispatch<SetStateAction<boolean>>;
}

const ViewSwitchButton = ({ isMinimized, setMinimized }: ViewSwitchButtonProps): JSX.Element => {
    const { t } = useTranslation();
    return (
        <div
            className="fixed top-0 right-0 m-4 transition-transform pointer-events-none z-1300"
            style={isMinimized ? { transform: 'translate3d(-10vw, 15vh, 0)' } : {}}>
            <div className="animate-shrink">
                <Fab className="pointer-events-auto" variant="extended" onClick={() => setMinimized((value) => !value)}>
                    {isMinimized ? (
                        <>
                            <CardDeckIcon className="block w-8 h-8 pr-3" />
                            {t('game_buttonMergeCards')}
                        </>
                    ) : (
                        <>
                            <HorseIcon className="block w-8 h-8 pr-2" />
                            {t('game_buttonViewBoard')}
                        </>
                    )}
                </Fab>
            </div>
        </div>
    );
};

export default ViewSwitchButton;
