/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

/**
 * Capitalizes the first letter of an arbitary string.
 *
 * @param word string that should get its first letter capitalized
 */
export const capitalizeFirstLetter = (word: string): string => {
    return word.charAt(0).toUpperCase() + word.slice(1);
};

export const removeTrailingSlash = (v: string): string => (v && v.length > 1 ? v.replace(/\/$/, '') : v);

export const getInitials = (name: string): string => {
    const parts = name.split(' ');
    let initials = '';
    parts.forEach((part) => {
        if (part.length > 0 && part !== '') {
            initials += part[0];
        }
    });
    return initials.slice(0, 2);
};
