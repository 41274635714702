/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { EventChannel } from 'redux-saga';
import { put, take } from 'redux-saga/effects';
import { GameMode } from '~/game';
import { GameState, GameType } from '~/server/src/dtos';
import { Controller, View } from '~/server/src/interfaces';
import Turfmaster from '~/server/src/tmmodel';
import { actions as setupActions } from '../state';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* rejoinGame(controller: Controller, view: View, channel: EventChannel<any>) {
    controller.receiveMsg(Turfmaster.stateMachine.RESTOREGAME, { view });

    const { state, params } = yield take(channel);

    if (state === Turfmaster.stateMachine.RESTOREGAME) {
        console.log('controller => client:', 'RESTOREGAME', { state, params });
        const rejoinableGameState: GameState | undefined = params?.game;

        if (rejoinableGameState) {
            const mode: GameMode = (() => {
                if (
                    rejoinableGameState.gameType === GameType.FreeBronze ||
                    rejoinableGameState.gameType === GameType.FreeSilver ||
                    rejoinableGameState.gameType === GameType.FreeGold
                ) {
                    return 'free';
                } else if (
                    rejoinableGameState.gameType === GameType.TournamentBronze ||
                    rejoinableGameState.gameType === GameType.TournamentSilver ||
                    rejoinableGameState.gameType === GameType.TournamentGold
                ) {
                    return 'tournament';
                } else return 'training';
            })();
            yield put(setupActions.setRejoinState({ type: 'success', mode }));
            return true;
        } else {
            // tbd no game available
            console.log('game is null');
        }
    } else {
        // tbd wrong response
        console.log('controller => client:', 'UNKNOWN RESPONSE TO RESTOREGAME', { state, params });
    }

    return false;
}

function* sagaRejoinGame(
    view: View,
    localController: Controller,
    remoteController: Controller,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    channel: EventChannel<any>,
) {
    if (!view || !localController || !remoteController || !channel) return;

    yield put(setupActions.setRejoinState({ type: 'loading' }));

    const remoteJoinSuccessful = yield rejoinGame(remoteController, view, channel);
    if (!remoteJoinSuccessful) {
        const localJoinSuccessful = yield rejoinGame(localController, view, channel);

        if (!localJoinSuccessful) {
            yield put(setupActions.setRejoinState({ type: 'error' }));
        }
    }

    return false;
}

export default sagaRejoinGame;
