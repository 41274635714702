import { useState, useEffect, useCallback } from 'react';

const useKeyPressed = (target: number | ((key: number) => boolean)): boolean => {
    // State for keeping track of whether key is pressed
    const [keyPressed, setKeyPressed] = useState(false);

    // If pressed key is our target key then set to true
    const downHandler = useCallback(
        (event: KeyboardEvent) => {
            const key = event.which || event.keyCode;
            if (
                !keyPressed &&
                ((typeof target === 'number' && key === target) || (typeof target === 'function' && target(key)))
            )
                setKeyPressed(true);
        },
        [keyPressed, target],
    );

    // If released key is our target key then set to false
    const upHandler = useCallback(
        (event: KeyboardEvent) => {
            const key = event.which || event.keyCode;
            if (
                keyPressed &&
                ((typeof target === 'number' && key === target) || (typeof target === 'function' && target(key)))
            )
                setKeyPressed(false);
        },
        [keyPressed, target],
    );

    // Add event listeners
    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        // Remove event listeners on cleanup
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, [downHandler, upHandler]); // Empty array ensures that effect is only run on mount and unmount

    return keyPressed;
};

export default useKeyPressed;
