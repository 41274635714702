import * as React from 'react';

interface CheckIconProps {
    className?: string;
    color?: string;
}

const CheckIcon = ({ className, color = '#000' }: CheckIconProps): JSX.Element => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 140 140" className={className}>
            <path
                d="M135.625 4.37L47.588 130.13a12.833 12.833 0 01-20.819.344L4.375 100.62"
                fill="none"
                stroke={color}
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={8.749995}
            />
        </svg>
    );
};

export default CheckIcon;
