import ISO6391 from 'iso-639-1';
import React from 'react';
import ReactCountryFlag from 'react-country-flag';
import { useTranslation } from '~/app';
interface HorseInfoProps {
    isPreview?: boolean;
    isDropped?: boolean;
    horseName: string;
    horseRealColor?: string;
    jockeyName?: string;
    horseTotalRank?: number;
    playerName: string;
    country?: string;
    handicap?: number;
}

/**
 * Content of tooltip while hovering / clicking horse icon
 */
const HorseInfo = ({
    isPreview,
    isDropped,
    playerName,
    country,
    horseName,
    horseRealColor,
    jockeyName,
    horseTotalRank,
    handicap,
}: HorseInfoProps): JSX.Element => {
    const { t } = useTranslation();

    return (
        <div className="flex flex-col p-1 space-y-2 text-sm align-middle">
            <div className="flex flex-row items-center space-x-2 text-xs font-thin">
                {country && (
                    <ReactCountryFlag
                        className="w-auto h-3"
                        title={ISO6391.getNativeName(country)}
                        aria-label={ISO6391.getNativeName(country)}
                        countryCode={country}
                        svg
                    />
                )}

                <div>{playerName}</div>
            </div>

            <div className="flex flex-col">
                {jockeyName && (
                    <span className="text-xs font-light text-gray-600">
                        {t('horseWithJockeyLabel', { placeholders: { jockeyName } })}
                    </span>
                )}
                <span>
                    <strong>{horseName}</strong>
                </span>
            </div>

            {horseRealColor && (
                <div className="flex flex-row items-center">
                    {t('game_playerInfoOrgColor')}
                    <div className="ml-1.5 w-3.5 h-3.5  rounded" style={{ backgroundColor: horseRealColor }} />
                </div>
            )}

            <div className="w-full h-px bg-gray-200 rounded" />

            {isPreview ? (
                t('game_playerInfoWaiting')
            ) : (
                <div>
                    {horseTotalRank && (
                        <div>{t('game_playerInfoRankTotal', { placeholders: { totalRank: horseTotalRank + 1 } })}</div>
                    )}

                    {!isDropped ? (
                        <div>
                            {handicap
                                ? t('game_playerInfoHandicap', { placeholders: { handicap: handicap + 1 } })
                                : t('game_playerInfoNoHandicap')}
                        </div>
                    ) : (
                        <>
                            <div className="w-full h-px my-2 bg-gray-200 rounded" />
                            <div className="font-bold">{t('game_playerInfoDropped')}</div>
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

export default HorseInfo;
