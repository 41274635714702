import React from 'react';
import { CellProps, Renderer } from 'react-table';
import { HorseStatistic, useTranslation } from '~/app';
import HorseIcon from '~/components/HorseIcon';

export interface HorseCellProps {
    name: string;
    color: number;
    jockeyName: string;
}

const HorseCell: Renderer<CellProps<HorseStatistic, HorseCellProps>> = ({
    cell: {
        value: { name, color, jockeyName },
    },
}) => {
    const { t } = useTranslation();
    return (
        <div className="flex flex-row items-center space-x-4">
            <HorseIcon color={color} className="w-8 h-8" />

            <div className="flex flex-col">
                {jockeyName && (
                    <span className="text-xs font-light text-gray-600">
                        {t('horseWithJockeyLabel', { placeholders: { jockeyName } })}
                    </span>
                )}
                <span>{name}</span>
            </div>
        </div>
    );
};

export default HorseCell;
