import { useMemo } from 'react';
import { useTranslation } from '~/app';
import { UserSubscription } from '~/server/src/dtos';

const useSubscriptionLabel = (subscription?: UserSubscription): string => {
    const { t } = useTranslation();
    return useMemo(() => {
        switch (subscription) {
            case UserSubscription.Basic:
                return t('subscriptionBasic');
            case UserSubscription.Premium:
                return t('subscriptionPremium');
            default:
                return '';
        }
    }, [t, subscription]);
};

export default useSubscriptionLabel;
