/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { createReducer, PayloadAction } from '@reduxjs/toolkit';
import { SetEntityPropertyParams } from 'app';
// eslint-disable-next-line import/order
import { cloneDeep } from 'lodash';
import {
    HorseRanks,
    notifyConnectionTimeout,
    resetConnectionTimeout,
    setConnected,
    setCurrentState,
    setHorseDropped,
    setHorseFinished,
    setHorseHandi,
    setHorsePoints,
    setHorseRanks,
    setHorsesBehindGoal,
    setHorsesDropped,
    setHorsesFinished,
    setHorsesHandi,
    setHorsesPoints,
    setHorsesRanks,
    setHorsesStarted,
    setPlayers,
    setRound,
    setTurnDicePlayerId,
    setTurnDices,
    setTurnDicesCur,
    setTurnOrder,
    setTurnPos,
    setTurnType,
    startDebug,
    startFree,
    startTournament,
    startTraining,
} from './actions';

export interface Player {
    id: number;
    userId: string;
    name: string;
    type: number;
    cardType: number;
    totalRank: number;
    playerName: string;
    country: string;
}

export interface GameState {
    // flag to indicate ui if socket of remote controller is connected
    readonly connected: boolean;
    readonly connectionTimeout: boolean;

    readonly currentState: number;
    readonly round: number;

    readonly turnpos: number;
    readonly turnorder: number[];
    readonly turndices: number[];
    readonly turndicescur: number;
    readonly turntype: number;
    readonly turndiceplayerid: number;

    readonly players: Player[];

    readonly horsesRanks: HorseRanks[];
    readonly horsesHandi: number[];
    readonly horsesPoints: number[][];
    readonly horsesFinished: boolean[];
    readonly horsesDropped: boolean[];
    readonly horsesStarted: boolean[];
    readonly horsesBehindGoal: boolean[];
}

export const initialGameState: GameState = {
    connected: false,
    connectionTimeout: false,

    currentState: 0,
    round: 0,

    turnpos: 0,
    turnorder: [],
    turndices: [],
    turndicescur: 0,
    turntype: 0,
    turndiceplayerid: 0,

    players: [],

    horsesRanks: [],
    horsesHandi: [],
    horsesPoints: [],
    horsesFinished: [],
    horsesDropped: [],
    horsesStarted: [],
    horsesBehindGoal: [],
};

export default createReducer(initialGameState, {
    [setConnected.type]: (state, action: PayloadAction<boolean>) => {
        state.connected = action.payload;
    },
    [resetConnectionTimeout.type]: (state) => {
        state.connectionTimeout = false;
    },
    [notifyConnectionTimeout.type]: (state) => {
        state.connectionTimeout = true;
    },

    [startTraining.type]: (_state, _action) => cloneDeep(initialGameState),
    [startFree.type]: (_state, _action) => cloneDeep(initialGameState),
    [startTournament.type]: (_state, _action) => cloneDeep(initialGameState),
    [startDebug.type]: (_state, _action) => cloneDeep(initialGameState),

    [setCurrentState.type]: (state, action: PayloadAction<number>) => {
        state.currentState = action.payload;
    },
    [setRound.type]: (state, action: PayloadAction<number>) => {
        state.round = action.payload;
    },

    [setTurnPos.type]: (state, action: PayloadAction<number>) => {
        state.turnpos = action.payload;
    },
    [setTurnOrder.type]: (state, action: PayloadAction<number[]>) => {
        state.turnorder = action.payload;
    },
    [setTurnDices.type]: (state, action: PayloadAction<number[]>) => {
        state.turndices = action.payload;
    },
    [setTurnDicesCur.type]: (state, action: PayloadAction<number>) => {
        state.turndicescur = action.payload;
    },
    [setTurnType.type]: (state, action: PayloadAction<number>) => {
        state.turntype = action.payload;
    },
    [setTurnDicePlayerId.type]: (state, action: PayloadAction<number>) => {
        state.turndiceplayerid = action.payload;
    },

    [setPlayers.type]: (state, action: PayloadAction<Player[]>) => {
        state.players = action.payload;
    },

    [setHorsesRanks.type]: (state, action: PayloadAction<HorseRanks[]>) => {
        state.horsesRanks = action.payload;
    },
    [setHorseRanks.type]: (state, action: PayloadAction<SetEntityPropertyParams<HorseRanks>>) => {
        state.horsesRanks[action.payload.index] = action.payload.value;
    },
    [setHorsesHandi.type]: (state, action: PayloadAction<number[]>) => {
        state.horsesHandi = action.payload;
    },
    [setHorseHandi.type]: (state, action: PayloadAction<SetEntityPropertyParams<number>>) => {
        state.horsesHandi[action.payload.index] = action.payload.value;
    },
    [setHorsesPoints.type]: (state, action: PayloadAction<number[][]>) => {
        state.horsesPoints = action.payload;
    },
    [setHorsePoints.type]: (state, action: PayloadAction<SetEntityPropertyParams<number[]>>) => {
        state.horsesPoints[action.payload.index] = action.payload.value;
    },
    [setHorsesFinished.type]: (state, action: PayloadAction<boolean[]>) => {
        state.horsesFinished = action.payload;
    },
    [setHorseFinished.type]: (state, action: PayloadAction<SetEntityPropertyParams<boolean>>) => {
        state.horsesFinished[action.payload.index] = action.payload.value;
    },
    [setHorsesDropped.type]: (state, action: PayloadAction<boolean[]>) => {
        state.horsesDropped = action.payload;
    },
    [setHorseDropped.type]: (state, action: PayloadAction<SetEntityPropertyParams<boolean>>) => {
        state.horsesDropped[action.payload.index] = action.payload.value;
    },
    [setHorsesStarted.type]: (state, action: PayloadAction<boolean[]>) => {
        state.horsesStarted = action.payload;
    },
    [setHorsesBehindGoal.type]: (state, action: PayloadAction<boolean[]>) => {
        state.horsesBehindGoal = action.payload;
    },
});
