/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import cn from 'classnames';
import React, { memo, useEffect, useState } from 'react';
import { getRandomIntInclusive } from '~/utils';
import * as styles from './BarRight.module.css';
import Dice, { Props as DiceProps } from './Dice';

interface Props extends DiceProps {
    isShuffling?: boolean;
    isPulsating?: boolean;
}

const values = [1, 2, 3, 4, 5, 6];

const AnimatedDice = ({ isShuffling = false, isPulsating = true, className, ...restProps }: Props) => {
    const [value, setValue] = useState(() => values[getRandomIntInclusive(0, 5)]);

    useEffect(() => {
        if (isShuffling) {
            const timeout = setTimeout(
                // setValue has to be dependent on value so that animation is triggered in all desired cases
                () => setValue(values.filter((v) => v !== value)[getRandomIntInclusive(0, 4)]),
                100,
            );
            return () => clearTimeout(timeout);
        }
    }, [isShuffling, value]);

    return (
        <Dice
            className={cn(className, {
                [styles.DiceShuffling]: isShuffling,
                [styles.DicePulsating]: isPulsating && !isShuffling,
            })}
            {...restProps}
            {...(isShuffling ? { value } : {})}
        />
    );
};

export default memo(AnimatedDice);
