/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import React, { useEffect, useState } from 'react';
import { batch } from 'react-redux';
import { LoginDialog, RegisterDialog, useAuthDispatch, useAuthState } from '~/auth';

const AuthDialogManager = (): JSX.Element => {
    const { isAuthorized, state: authState } = useAuthState();
    const authDispatch = useAuthDispatch();

    const [prefilledEmail, setPrefilledEmail] = useState('');
    const [prefilledPassword, setPrefilledPassword] = useState('');
    const [preFilledFirebaseIdToken, setPrefilledFirebaseIdToken] = useState<string>();

    useEffect(() => {
        if (authState !== 'idle' && isAuthorized) {
            batch(() => {
                authDispatch({ type: 'cancel' });
                setPrefilledEmail('');
                setPrefilledPassword('');
                setPrefilledFirebaseIdToken('');
            });
        }
    }, [authDispatch, authState, isAuthorized]);

    const onOpenRegister = (email: string, password: string, firebaseIdToken?: string) => {
        batch(() => {
            setPrefilledEmail(email);
            setPrefilledPassword(password);
            setPrefilledFirebaseIdToken(firebaseIdToken);
            authDispatch({ type: 'register' });
        });
    };

    return (
        <>
            <LoginDialog
                modalOpen={authState === 'login'}
                onLoginCancelled={() => authDispatch({ type: 'cancel' })}
                onLoginSuccess={() => authDispatch({ type: 'cancel' })}
                onRegistrationRequired={onOpenRegister}
            />

            <RegisterDialog
                modalOpen={authState === 'register'}
                email={prefilledEmail}
                password={prefilledPassword}
                firebaseIdToken={preFilledFirebaseIdToken}
                onRegistrationCancelled={() => authDispatch({ type: 'cancel' })}
                onRegistrationSuccess={() => authDispatch({ type: 'cancel' })}
            />
        </>
    );
};

export default AuthDialogManager;
