import countries from 'i18n-iso-countries';
import { getRandomUniqueNumbers } from './utilRandom';

class RandomNames {
    public static readonly horseNames = [
        'Adios',
        'Adios Butler',
        'Affirmed',
        'Ajax',
        'Albatross',
        'Allez France',
        'Alydar',
        'American Pharoah',
        'Animal Kingdom',
        'Archer',
        'Aristides',
        'Arrogate',
        'Arkle',
        'Assault',
        'Barbaro',
        'Beholder',
        'Bernborough',
        'Ben Nevis',
        'Bend Or',
        'Best Mate',
        'Big Brown',
        'Black Caviar',
        'Bold Forbes',
        'Bold Ruler',
        'Bret Hanover',
        'Brigadier Gerard',
        'Bucephalus',
        'Buckpasser',
        'California Chrome',
        'Carbine',
        'Cardigan Bay',
        'Castleshane',
        'Cicero',
        'Cigar',
        'Citation',
        'Country House',
        'Crisp',
        'Curlin',
        'Dan Patch',
        'Danehill',
        'Dance Smartly',
        'Dawn Run',
        'Deep Impact',
        'Desert Gold',
        'Desert Orchid',
        'Dr. Fager',
        'Doncaster',
        'Easy Goer',
        'Eclipse',
        'Eight Belles',
        'Exterminator',
        'Frankel',
        'Funny Cide',
        'Flyingbolt',
        'Fair Play',
        'Gainsborough',
        'Galileo',
        'Genuine Risk',
        'Gloaming',
        'Go Man Go',
        'Golden Miller',
        'Goldsmith Maid',
        'Greyhound',
        'Hambletonian 10',
        'Hurricane Fly',
        'Hyperion',
        'Hastings',
        'Incitatus',
        'Iroquois',
        'Invasor',
        "I'll Have Another",
        'Irish War Cry',
        'Isinglass',
        'Isonomy',
        'Jay Trump',
        'John Henry',
        'Johnstown',
        'Justify',
        'Kalgoorlie Kid',
        'Kelso',
        'Kincsem',
        'Kindergarten',
        'Kingston',
        'Kingston Town',
        'Kissin George',
        'La Troienne',
        'Lexington',
        'Longfellow',
        'Lonesome Glory',
        'Lottery',
        'Lookin At Lucky',
        'Makybe Diva',
        "Man o' War",
        'Marengo',
        'Master Charlie',
        'Maximum Security',
        'Might and Power',
        'Mr. Prospector',
        'Moifaa',
        'Mahubah',
        'Nasrullah',
        'Native Dancer',
        'Needles',
        'Niatross',
        'Night Raid',
        'Nijinsky II',
        'Northern Dancer',
        'Overdose',
        'Orfevre',
        'Oeidipus',
        'Peter Pan',
        'Phar Lap',
        'Pleasant Colony',
        'Quevega',
        'Queensway',
        'Rachel Alexandra',
        'Roy Olcott',
        'Real Quiet',
        'Red Rum',
        'Regret',
        'Ribot',
        'Rock Sand',
        'Round Table',
        'Ruffian',
        'Ruthless',
        "Sadler's Wells",
        'Sardar',
        'Sea Bird',
        'Sea the Stars',
        'Seabiscuit',
        'Seattle Slew',
        'Secretariat',
        'Shergar',
        'Silky Sullivan',
        'Sir Winston',
        'Skewball',
        'Sleipnir',
        'Smarty Jones',
        'Spectacular Bid',
        'Steel Dust',
        'Storm Cat',
        'Sunday Silence',
        'Sunline',
        'Swale',
        'Tanya',
        'Tapwrit',
        'Ta Wee',
        'The Duke',
        'Tiznow',
        'Tonalist',
        'Tuscalee',
        'Twenty Grand',
        'Two Lea',
        'Unbreakable',
        'Unbridled',
        "Unbridled's Song",
        'Vain',
        'Varenne',
        'Vo Rouge',
        'Voltaire',
        'War Admiral',
        'War Of Will',
        'Whistlejacket',
        'Winning Colors',
        'Winx',
        'Wise Dan',
        'Whirlaway',
        'Whisk Broom II',
        'Xaar',
        'Xtra Heat',
        'Yeats',
        'Your Host',
        'Zabeel',
        'Zaccio',
        'Zenyatta',
        'Zev',
        'Zippy Chippy',
    ];

    public static readonly jockeyNames = [
        'Eddie Ahern',
        'Robby Albarado',
        'Anna Lee Aldred',
        'Goncalino Almeida',
        'Junior Alvarado',
        'Kim Andersen',
        'Jack Anthony',
        'Chris Antley',
        'Eddie Arcaro',
        'Fred Archer',
        'John Arnull',
        'Sam Arnull',
        'Cash Asmussen',
        'Andrea Atzeni',
        'Mary Bacon',
        'Jerry Bailey',
        'Lester Balaski',
        'Ron Barry',
        'Donna Barton Brothers',
        'Michael Baze',
        'Russell Baze',
        'Tyler Baze',
        'Bobby Beasley',
        'William Beasley',
        'Martin Becher',
        'Kaye Bell',
        'Terry Biddlecombe',
        'Dominique Boeuf',
        'Calvin Borel',
        'Glen Boss',
        'Joe Bravo',
        'Scobie Breasley',
        'Paddy Brennan',
        'Charlotte Brew',
        'Shaun Bridgmohan',
        'William Buick',
        'David Campbell',
        'Nina Carberry',
        'Paul Carberry',
        'Tommy Carberry',
        'Eliza Carpenter',
        'Willie Carson',
        'G. R. Carter',
        'Jesús Castañón',
        'Javier Castellano',
        'Eddie Castro',
        'Steve Cauthen',
        'Bob Champion',
        'Jorge Chavez',
        'Joe Childs',
        'Eibar Coa',
        'Ray Cochrane',
        'Davy Condon',
        'Patricia Cooksey',
        'Bryan Cooper',
        'Ángel Cordero Jr.',
        'Jean Cruguet',
        'Diane Crump',
        'Anthony S. Cruz',
        'Jim Culloty',
        'Charlie Cunningham',
        'Luke Currie',
        'Robbie Davis',
        'Wantha Davis',
        'Pat Day',
        'Eddie Delahoussaye',
        'Kent Desormeaux',
        'Frankie Dettori',
        'Guy Disney',
        'Ramon Domínguez',
        'Steve Donoghue',
        'James Doyle',
        'Jimmy Duggan',
        'Richard Dunwoody',
        'Martin Dwyer',
        'Shane Dye',
        'Penny Ann Early',
        'Masayoshi Ebina',
        'Pat Eddery',
        'Stewart Elliott',
        'Victor Espinoza',
        'Kieren Fallon',
        'Mick Fitzgerald',
        'Brian Fletcher',
        'David Romero Flores',
        'Jose Flores',
        'Jimmy Fortune',
        'Freddie Fox',
        'Richard Fox',
        'Dick Francis',
        'Manuel Franco',
        'John Francome',
        'Tyler Gaffalione',
        'Alan Garcia',
        'Martin Garcia',
        'Barry Geraghty',
        'Florent Geroux',
        'Josh Gifford',
        'Campbell Gillies',
        'Avelino Gomez',
        'Garrett K. Gomez',
        'Josephine Gordon',
        'Aaron Gryder',
        'Mario Gutierrez',
        'Bill Hartack',
        'Sandy Hawley',
        'Seamie Heffernan',
        'Brian Hernandez Jr.',
        'Roy Higgins',
        'Michael Hills',
        'Richard Hills',
        'Darryll Holland',
        'Rosemary Homeister Jr.',
        'Richard Hughes',
        'Patrick Husbands',
        'Simon Husbands',
        'Michael Hussey',
        'Chris Hayes',
        'Yasunari Iwata',
        'Daryl Jacob',
        'Billy Jacobson',
        'Richard Johnson',
        'Willy Kan',
        'Colin Keane',
        'Lizzie Kelly',
        'Pandu Khade',
        'Michael Kinane',
        'Julie Krone',
        'Geoff Laidlaw',
        'Lucien Laurin',
        'Graham Lee',
        'Christophe Lemaire',
        'Julien Leparoux',
        'Geoff Lewis',
        'Jose Lezcano',
        'Carl Llewellyn',
        'Tommy Loates',
        'Johnny Loftus',
        'Johnny Longden',
        'Paco Lopez',
        'Len Lungo',
        'Mike Luzzi',
        'Harold Russell Maddock',
        'Jason Maguire',
        'Manfred K. L. Man',
        'John Mangle',
        'Ryan Mania',
        'Eddie Maple',
        'Rajiv Maragh',
        'Chris McCarron',
        'Tony McCoy',
        'Miguel Mena',
        'Joe Mercer',
        'Richard Migliore',
        'Kirsty Milczarek',
        'Martin Molony',
        'George Moore',
        'Jamie Moore',
        'Ryan Moore',
        'David Mullins',
        'Isaac Murphy',
        'Johnny Murtagh',
        'Corey Nakatani',
        'Rosie Napravnik',
        'Suraj Narredu',
        'Peter Niven',
        "Joseph O'Brien",
        'Damien Oliver',
        'Henry Oliver',
        "Jonjo O'Neill",
        'Irad Ortiz Jr.',
        'José Ortiz',
        "Lance O'Sullivan",
        'Stéphane Pasquier',
        'Billy Pearson',
        'Martin Pedroza',
        'Dave Penna',
        'T. J. Pereira',
        'Olivier Peslier',
        'Lester Piggott',
        'Yasin Pilavcılar',
        'Laffit Pincay Jr.',
        'Red Pollard',
        'Robbie Power',
        'Edgar Prado',
        'David Probert',
        'Tom Queally',
        'Jimmy Quinn',
        'Richard Quinn',
        'Jorge Ricardo',
        'Sir Gordon Richards',
        'Michael Roberts',
        'Philip Robinson',
        'Willie Robinson',
        'Randy Romero',
        'Joel Rosario',
        'Jeremy Rose',
        'Katri Rosendahl',
        'Chris Russell',
        'Davy Russell',
        'Gabriel Saez',
        'Yves Saint-Martin',
        'Jean-Luc Samyn',
        'Emanuel Jose Sanchez',
        'Ricardo Santana Jr.',
        'José Santos',
        'Peter Schiergen',
        'Michael Scudamore',
        'Peter Scudamore',
        'Art Sherman',
        'Yoshitomi Shibata',
        'Blake Shinn',
        'Bill Shoemaker',
        'Pesi Shroff',
        'Eurico Rosa da Silva',
        'Bill Skelton',
        'Bob Skelton',
        'Doug Smith',
        'Eph Smith',
        'Mike Smith',
        'Virginia Pinky Smith',
        'Pat Smullen',
        'Alex Solis',
        'Christophe Soumillon',
        'Cedric Segeon',
        'Jamie Spencer',
        'Y. S. Srinath',
        'Tommy Stack',
        'Andrasch Starke',
        'Greville Starkey',
        'Silvestre de Sousa',
        'Georges Stern',
        'Gary Stevens',
        'George Stevens',
        'Kayla Stra',
        'Andreas Suborics',
        'Chantal Sutherland',
        'Charlie Swan',
        'Wally Swinburn',
        'Walter Swinburn',
        'Pat Taaffe',
        'Joe Talamo',
        'Koshiro Take',
        'Yutaka Take',
        'Brian Taylor',
        'Brent Thomson',
        'Terry J. Thompson',
        'Alicia Thornton',
        'Andrew Thornton',
        'Liam Treadwell',
        'Daniel Tudhope',
        'Ron Turcotte',
        'Hayley Turner',
        'Bobby Ussery',
        'Pat Valenzuela',
        'Alice Van-Springsteen',
        'Josef Váňa',
        'Jacinto Vásquez',
        'Cornelio Velásquez',
        'John Velazquez',
        'Francine Villeneuve',
        'Michael Walker',
        'Ruby Walsh',
        'Fulke Walwyn',
        'Tommy Weston',
        'Jack Westrope',
        'Elijah Wheatley',
        'Evan Williams',
        'Emma-Jayne Wilson',
        'Rick Wilson',
        'Fred Winter',
        'Otto Wonderly',
        'Hedley Woodhouse',
        'George Woolf',
        'Harry Wragg',
        'Wayne D. Wright',
        'Manuel Ycaza',
        'Raymond York',
    ];

    public static readonly studFarmNames = [
        'Keiko Tog',
        'Tava Darkstar',
        'Yevelda',
        'Klyn Vectivus',
        'Prax Amor',
        'Flora Kenobi',
        'Vorg Lunis',
        'Rangrim Torunn',
        'Westra Pisacar',
        'Urhur Kung',
        'Perra Myastan',
        'Mishann Clethtinthiallor',
        'Medrash Daardendrian',
        'Norg Song',
        'Neega',
        'Enina Song',
        'Irini Starcaster',
        'Krusk',
        'Keel Bok',
        'Fonkin Timbers',
        'Locutus Song',
        'Kolana Starcaster',
        'Berrian Galanodel',
        'Kang Vindictus',
        'Kor Starwalker',
        'Carric  Galanodel',
        'Yuta Deathstrider',
        'Emen',
        'Draal Grimm',
        'Madislak Domine',
        'Erevan Siannodel',
        'Enina Ignis',
        'Gurdis Gorunn',
        'Audhild Strakeln',
        'Luxia Tyranus',
        'Tak Potter',
        'Chell Deathstrider',
        'Diesa Loderr',
        'Nils Beeblebrox',
        'Alvyn Garrick',
        'Astra Potter',
        'Fala Surtas',
        'Rieta',
        'Malark Sepret',
        'Lucius Lightbringer',
        'Haro Lightbringer',
        'Krag Vura',
        'Loopmottin Raulnor',
        'Caelynn Holimion',
        'Lindal High-hill',
        'Thawn Baltar',
        'Mota Starwalker',
        'Enzu Volatus',
        'Kor Goodfellow',
        'Lander Amblecrown',
        'Morgran Frostbeard',
        'Glim Timbers',
        'Weta Bok',
        'Yevelda',
        'Kitty Cognus',
        'Fala Lunis',
        'Ivan Vura',
        'Kala Vura',
        'Helm Ankhalab',
        'Mas Sidious',
        'Kassia Odis',
        'Shamil Raulnor',
        'Bo Vader',
        'Mumed Iltazyara',
        'Krag Deathbringer',
        'Areta Vader',
        'Imsh',
        'Mival Stormwind',
        'Flora Skywalker',
        'Trixie Deathbringer',
        'Peri Malo',
        'Xerxes Solo',
        'Meng Evenwood',
        'Ptera Malo',
        'Tarhun Kepeshkmolik',
        'Seska Surtas',
        'Rai Abyssi',
        'Tharivol Ilphelkiir',
        'Paelias Galanodel',
        'Glim Ningel',
        'Teka Plagueis',
        'Emi Abyssi',
        'Emen',
        'Krusk',
        'Mika Vura',
        'Varis Holimion',
        'Altos Abyssi',
        'Beata Baltar',
        'Morthos',
        'Data Tyranus',
        'Kethra Tallstag',
        'Tarhun Verthisathurgiesh',
        'Volen',
        'Suki Kenobi',
        'Amnon',
    ];

    public static readonly countryCodes = Object.keys(countries.getAlpha2Codes());

    private currentHorseNames: string[] = [];

    private currentJockeyNames: string[] = [];

    private currentStudFarmNames: string[] = [];

    private currentCountryCodes: string[] = [];

    public getRandomHorseName() {
        if (this.currentHorseNames.length === 0) {
            this.currentHorseNames = this.getRandomHorseNames();
        }

        return this.currentHorseNames.pop() ?? '';
    }

    public getRandomJockeyName() {
        if (this.currentJockeyNames.length === 0) {
            this.currentJockeyNames = this.getRandomJockeyNames();
        }

        return this.currentJockeyNames.pop() ?? '';
    }

    public getRandomStudFarmName() {
        if (this.currentStudFarmNames.length === 0) {
            this.currentStudFarmNames = this.getRandomStudFarmNames();
        }

        return this.currentStudFarmNames.pop() ?? '';
    }

    public getRandomCountryCode() {
        if (this.currentCountryCodes.length === 0) {
            this.currentCountryCodes = this.getRandomCountryCodes();
        }

        return this.currentCountryCodes.pop() ?? 'DE';
    }

    public getRandomHorseNames(count = -1) {
        if (count === -1) {
            count = RandomNames.horseNames.length;
        }

        return RandomNames.getRandomNames(RandomNames.horseNames, count);
    }

    public getRandomJockeyNames(count = -1) {
        if (count === -1) {
            count = RandomNames.jockeyNames.length;
        }

        return RandomNames.getRandomNames(RandomNames.jockeyNames, count);
    }

    public getRandomStudFarmNames(count = -1) {
        if (count === -1) {
            count = RandomNames.studFarmNames.length;
        }

        return RandomNames.getRandomNames(RandomNames.studFarmNames, count);
    }

    public getRandomCountryCodes(count = -1) {
        if (count === -1) {
            count = RandomNames.countryCodes.length;
        }

        return RandomNames.getRandomNames(RandomNames.countryCodes, count);
    }

    private static getRandomNames(names: string[], count: number) {
        const randomNames: string[] = [];

        const numbers = getRandomUniqueNumbers(0, names.length, count);

        for (let i = 0; i < numbers.length; i++) {
            randomNames.push(names[numbers[i]]);
        }

        return randomNames;
    }
}

export default RandomNames;
