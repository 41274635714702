import ISO6391 from 'iso-639-1';
import React from 'react';
import { CellProps, Renderer } from 'react-table';
import ReactCountryFlag from 'react-country-flag';
import { HorseStatistic } from '~/app';

export interface UserCellProps {
    name?: string;
    country?: string;
}

const UserCell: Renderer<CellProps<HorseStatistic, UserCellProps>> = ({
    cell: {
        value: { name, country },
    },
}) => (
    <div className="flex flex-row items-center space-x-4">
        {country && (
            <ReactCountryFlag
                className="w-auto h-5"
                title={ISO6391.getNativeName(country)}
                aria-label={ISO6391.getNativeName(country)}
                countryCode={country}
                svg
            />
        )}

        <span>{name}</span>
    </div>
);

export default UserCell;
