/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import React from 'react';

const CardDeckIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg viewBox="0 0 256 256" width={256} height={256} {...props}>
        <g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round">
            <path
                d="M153.685 160.395l-5.226-26.4a5.333 5.333 0 012.261-5.472l22.4-14.934a5.333 5.333 0 018.192 3.392l5.237 26.4a5.333 5.333 0 01-2.272 5.472l-22.4 14.934a5.333 5.333 0 01-8.192-3.392z"
                strokeWidth={10.66667}
            />
            <path d="M82.675 190.144l61.213-147.84 108.408 44.887-61.212 147.84z" strokeWidth={10.66667} />
            <path
                d="M85.781 182.603H74.667a5.333 5.333 0 01-5.334-5.334V27.936a5.333 5.333 0 015.334-5.333h106.666a5.333 5.333 0 015.334 5.333v32.085"
                strokeWidth={10.66667}
            />
            <path
                d="M113.59 202.944l-43.734 18.133a5.333 5.333 0 01-6.965-2.89L5.739 80.203a5.333 5.333 0 012.89-6.966l60.704-25.152M35.061 85.6a2.667 2.667 0 012.667 2.667M32.395 88.267A2.667 2.667 0 0135.06 85.6M35.061 90.933a2.667 2.667 0 01-2.666-2.666M37.728 88.267a2.667 2.667 0 01-2.667 2.666M93.333 43.936A2.667 2.667 0 0196 46.603M90.667 46.603a2.667 2.667 0 012.666-2.667M93.333 49.27a2.667 2.667 0 01-2.666-2.667M96 46.603a2.667 2.667 0 01-2.667 2.666M156.885 70.997a2.667 2.667 0 012.667 2.667M154.219 73.664a2.667 2.667 0 012.666-2.667M156.885 76.33a2.667 2.667 0 01-2.666-2.666M159.552 73.664a2.667 2.667 0 01-2.667 2.667M178.133 201.003a2.667 2.667 0 012.667 2.666M175.413 203.67a2.667 2.667 0 012.667-2.667M178.133 206.336a2.667 2.667 0 01-2.666-2.667M180.747 203.67a2.667 2.667 0 01-2.667 2.666"
                strokeWidth={10.66667}
            />
        </g>
    </svg>
);

export default CardDeckIcon;
