/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { ForkEffect, put, select, takeLatest } from 'redux-saga/effects';
import { Coordinates, selectActiveHorsesField, selectIsGameRunning, selectMoves } from '~/app';
import { setCurrentState } from '~/game';
import { transformRelX, transformRelY } from '~/utils';
import { moveCamera, setHorsesField, setMoves } from './actions';

const distance = (x1: number, x2: number, y1: number, y2: number): number => {
    return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
};

function* cameraSaga() {
    // if game is not running move camera to center of board and bail out of other calculations
    const isGameRunning: boolean = yield select(selectIsGameRunning);
    if (!isGameRunning) {
        yield put(
            moveCamera({
                x: 0.5,
                y: 0.5,
                scale: 1.2,
            }),
        );
        return;
    }

    // game is running so calculate camera focus to show horses and moves
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const horsesField: Coordinates[] = [...(yield select(selectActiveHorsesField))];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const moves: Coordinates[][] = [...(yield select(selectMoves))];

    // take first horse with a valid field to initialize values
    let someField: Coordinates | undefined = undefined;
    if (horsesField && horsesField.length > 0) while (!someField) someField = horsesField.pop();
    if (!someField) return;

    // determine initial min/max x and y
    let minX = transformRelX(someField.x),
        minY = transformRelY(someField.y),
        maxX = transformRelX(someField.x),
        maxY = transformRelY(someField.y);

    // check against all other horses
    horsesField
        .filter((field) => field)
        .forEach((field) => {
            if (field) {
                const x = transformRelX(field.x);
                const y = transformRelY(field.y);
                // check if x is min or max
                if (x < minX) minX = x;
                else if (x > maxX) maxX = x;
                // check if y is min or max
                if (y < minY) minY = y;
                else if (y > maxY) maxY = y;
            }
        });

    // check against all end fields of moves
    moves.forEach((move) => {
        const x = transformRelX(move[move.length - 1].x);
        const y = transformRelY(move[move.length - 1].y);
        // check if x is min or max
        if (x < minX) minX = x;
        else if (x > maxX) maxX = x;
        // check if y is min or max
        if (y < minY) minY = y;
        else if (y > maxY) maxY = y;
    });

    // determine zoom with greatest distance
    const d = distance(maxX, minX, maxY, minY);
    const dMax = distance(1, 0, 1, 0);
    const zoom = 0.3 * (d - dMax) ** 2 + 0.5;

    // camera position is in the center of min/max
    yield put(
        moveCamera({
            x: minX + (maxX - minX) / 2,
            y: minY + (maxY - minY) / 2,
            scale: zoom,
        }),
    );
}

function* cameraSagaRoot(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest([setHorsesField.type, setMoves.type, setCurrentState.type], cameraSaga);
}

export default cameraSagaRoot;
