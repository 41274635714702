/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { PayloadAction } from '@reduxjs/toolkit';
import { isArray } from 'lodash';
import { EventChannel, Task } from 'redux-saga';
import { all, cancel, delay, fork, put, take } from 'redux-saga/effects';
import { getFirebaseAnalytics } from '~/apis/firebase';
import { config } from '~/app';
import { setConnected, setCurrentState } from '~/game';
import { GameOverview, GameType } from '~/server/src/dtos';
import { Horse, JoinGameRequest, NewGameRequest, Player } from '~/server/src/dtos/controller';
import { Controller, View } from '~/server/src/interfaces';
import Turfmaster from '~/server/src/tmmodel';
import { createFree, CreateGameParameters, joinFree, JoinGameParameters } from '../actions';
import { actions } from '../state';

const isFreeSlotAvailable = (game: GameOverview): boolean => game.horseamount - game.horses.length > 0;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* sagaSetupFreePullGames(view: View, controller: Controller, channel: EventChannel<any>) {
    while (true) {
        controller.receiveMsg(Turfmaster.stateMachine.GETGAMES, {
            view,
        });
        const { state, params } = yield take(channel);
        if (state === Turfmaster.stateMachine.GETGAMES) {
            console.log('controller => client:', 'GETGAMES', params);
            const games = (isArray(params.games) ? params.games : []) as GameOverview[];
            const validGames = games.filter((game) => isFreeSlotAvailable(game));
            yield put(actions.setGames(validGames));
        }
        yield delay(config.freeGamePullInterval);
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function* sagaSetupFree(view: View, controller: Controller, channel: EventChannel<any>) {
    if (!view || !controller || !channel) return;

    const analytics = getFirebaseAnalytics();

    let pullGamesTask: Task | undefined;

    try {
        // endless loop because their could be 'wrong' messages in the channel because of
        // multiple processes using it or the controller just sending wrong messages in between
        // we therefore have listen until the right message is received and then break the loop
        while (true) {
            // fork a separate process constantly pulling free games to join
            pullGamesTask = yield fork(sagaSetupFreePullGames, view, controller, channel);

            // wait for a redux action requesting game creation or join
            const action: PayloadAction<unknown> = yield take([createFree.type, joinFree.type]);

            // cancel pull games because we received creation / join request
            if (pullGamesTask) yield cancel(pullGamesTask);

            // we are in creation mode so send NEWGAME and wait for NEWGAME response
            if (action.type === createFree.type) {
                const gameParams = (action as PayloadAction<CreateGameParameters>).payload;
                const { gameName, playerCount, horseCount, horseSelection } = gameParams;
                const horseAmount = playerCount * horseCount;
                const player: Player = {
                    horses: horseSelection.map<Horse>(({ horseId }) => ({
                        horseid: horseId,
                    })),
                };

                // send game creation request to controller
                const newGameRequest: NewGameRequest = {
                    gameType: GameType.FreeGold, // TODO: remove all free types for a unified type in server and then remove argument here
                    gameName: gameName || 'remote',
                    horseamount: horseAmount,
                    player,
                    computers: [],
                    view,
                };
                controller.receiveMsg(Turfmaster.stateMachine.NEWGAME, newGameRequest);

                // wait for newgame response from controller
                const { state, params } = yield take(channel);
                yield put(setCurrentState(state));
                if (state === Turfmaster.stateMachine.NEWGAME) {
                    const game = controller.getGame();
                    const board = controller.getBoard();

                    console.log('controller => client:', 'NEWGAME', params, game, board);

                    // make sure game and board are valid
                    if (!game || !board) return;

                    yield all([
                        put(actions.setGameId(params.gameid)),
                        put(actions.setGameType(GameType.FreeGold)), // TODO: update to free after server removal
                        put(actions.setPlayerId(params.playerid)),
                        put(setConnected(true)),
                    ]);

                    analytics?.logEvent('start_game', {
                        connectionType: controller.getType(),
                        gameType: GameType.FreeGold, // TODO: update after server removal
                        playerCount,
                        horseCountPerPlayer: horseCount,
                    });

                    // game creation successful so break the 'endless' loop
                    break;
                }
            }

            // we are in join mode so send JOINGAME and wait for JOINGAME response
            else if (action.type === joinFree.type) {
                const joinParams = (action as PayloadAction<JoinGameParameters>).payload;
                const { gameId, gameType, horseSelection } = joinParams;
                const player: Player = {
                    horses: horseSelection.map<Horse>(({ horseId }) => ({
                        horseid: horseId,
                    })),
                };

                controller.receiveMsg(Turfmaster.stateMachine.JOINGAME, {
                    gameid: gameId,
                    player,
                    view,
                } as JoinGameRequest);

                const { state, params } = yield take(channel);
                yield put(setCurrentState(state));
                if (state === Turfmaster.stateMachine.JOINGAME) {
                    const game = controller.getGame();
                    const board = controller.getBoard();

                    console.log('controller => client:', 'JOINGAME', params, game, board);
                    // make sure join was successful and game / board are valid
                    if (!params.error && game && board) {
                        yield all([
                            put(actions.setGameId(gameId)),
                            put(actions.setGameType(gameType)),
                            put(actions.setPlayerId(params.playerid)),
                            put(setConnected(true)),
                        ]);

                        analytics?.logEvent('join_game', {
                            connectionType: controller.getType(),
                            gameType,
                            playerCount: game.players.length,
                            horseCountPerPlayer: game.horses.length / game.players.length,
                        });

                        // join game was successful so break the 'endless' loop
                        break;
                    }
                }
            }
        }
    } finally {
        // make sure pullGamesTask cancellation is guaranteed even if this saga is interrupted
        if (pullGamesTask) yield cancel(pullGamesTask);
    }

    // make sure game and board are valid
    if (!controller.getGame() || !controller.getBoard()) {
        console.log('sagaSetup exit join after game creation because game or board is not defined');
        return;
    }

    // everything worked so notify mainSaga that game started successfully
    return true;
}

export default sagaSetupFree;
