/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { HorseStatistic } from '~/app';
import RandomNames from '~/server/src/randomnames';

const randomNames = new RandomNames();
export const debugHorseData: HorseStatistic[] = [
    {
        userId: '0x0',
        playerName: 'Flo',
        name: randomNames.getRandomHorseName(),
        color: 2,
        jockeyName: 'Jockey Flo',
        points: [50],
        pointsSum: 20,
        totalRank: 6,
        turnPos: 0,
        country: randomNames.getRandomCountryCode(),
    },
    {
        userId: '0x1',
        playerName: 'Micha',
        name: randomNames.getRandomHorseName(),
        color: 3,
        jockeyName: 'Jockey Micha',
        points: [50],
        pointsSum: 50,
        totalRank: 1,
        turnPos: 1,
        country: randomNames.getRandomCountryCode(),
    },
    {
        userId: '0x2',
        playerName: 'Andi',
        name: randomNames.getRandomHorseName(),
        color: 7,
        jockeyName: 'Jockey Andi',
        points: [50],
        pointsSum: 70,
        totalRank: 0,
        turnPos: 2,
        country: randomNames.getRandomCountryCode(),
    },
    {
        userId: '0x3',
        playerName: 'Flo',
        name: randomNames.getRandomHorseName(),
        color: 1,
        jockeyName: 'Jockey Flo',
        points: [50],
        pointsSum: 10,
        totalRank: 7,
        turnPos: 3,
        country: randomNames.getRandomCountryCode(),
    },
    {
        userId: '0x4',
        playerName: 'Michaasfdasfdasfd',
        name: randomNames.getRandomHorseName(),
        color: 4,
        jockeyName: 'Jockey Michaasfdasfdasfd',
        points: [50],
        pointsSum: 5,
        totalRank: 2,
        turnPos: 4,
        country: randomNames.getRandomCountryCode(),
    },
    {
        userId: '0x5',
        playerName: 'Andi',
        name: randomNames.getRandomHorseName(),
        color: 5,
        jockeyName: 'Jockey Andi',
        points: [50],
        pointsSum: 30,
        totalRank: 3,
        turnPos: 5,
        country: randomNames.getRandomCountryCode(),
    },
    {
        userId: '0x6',
        playerName: 'Flo',
        name: randomNames.getRandomHorseName(),
        color: 6,
        jockeyName: 'Jockey Flo',
        points: [50],
        pointsSum: 20,
        totalRank: 5,
        turnPos: 6,
        country: randomNames.getRandomCountryCode(),
    },
    {
        userId: '0x7',
        playerName: 'Micha',
        name: randomNames.getRandomHorseName(),
        color: 0,
        jockeyName: 'Jockey Micha',
        points: [50],
        pointsSum: 40,
        totalRank: 4,
        turnPos: 7,
        country: randomNames.getRandomCountryCode(),
    },
];
