export * from './account';
export * from './debug';
export * from './game';
export * from './horse';
export * from './payment';
export * from './toplist';
export * from './tournament';
export * from './user';

export type Unchecked<T> = T | unknown;

export enum LeagueType {
    Bronze = 'bronze',
    Silver = 'silver',
    Gold = 'gold',
}
