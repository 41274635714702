/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { NavigateOptions } from '@reach/router';
import { navigate as _navigate, prefetchPathname as _prefetchPathname } from 'gatsby';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo } from 'react';
import useLocalStorageState from 'use-local-storage-state';
import { removeTrailingSlash } from '~/utils';

interface LocaleResources {
    [key: string]: string;
}

interface LocaleConfig {
    defaultLang?: boolean;
    path: string;
    country: string;
    identifier: string;
    dateFormat: string;
    currency: string;
}

export type Url =
    | '/'
    | '/404'
    | '/setup/training'
    | '/setup/freegame'
    | '/setup/tournament'
    | '/table'
    | '/toplist'
    | '/training'
    | '/freegame'
    | '/tournament'
    | '/end'
    | '/profile'
    | '/debug'
    | '/play';
type LocaleUrls = { [key in Url]: string };

export const defaultValue = {
    urlPath: '/' as Url,
    locale: 'de',
    localeUrlPath: '/',
    allLocales: [{ locale: 'de', country: 'de' }],
    country: 'de',
    localizedCountryCodes: { DE: 'Deutschland' },
    localeResources: {},
    localeConfig: {
        path: 'de',
        country: 'de',
        identifier: 'de-DE',
        dateFormat: 'DD.MM.YYYY',
        currency: 'EUR',
    },
    allLocaleConfigs: {
        de: {
            path: 'de',
            country: 'de',
            identifier: 'de-DE',
            dateFormat: 'DD.MM.YYYY',
            currency: 'EUR',
        },
    },
    localeUrls: {
        '/': '/',
        '/404': '/404',
        '/setup/training': '/einrichtung/training',
        '/setup/freegame': '/einrichtung/freiesspiel',
        '/setup/tournament': '/einrichtung/turnier',
        '/table': '/tabelle',
        '/toplist': '/rangliste',
        '/training': '/training',
        '/freegame': '/freiesspiel',
        '/tournament': '/turnier',
        '/end': '/ende',
        '/profile': '/profil',
        '/debug': '/debug',
        '/play': '/play',
    },
    allLocaleUrls: {
        de: {
            '/': '/',
            '/404': '/404',
            '/setup/training': '/einrichtung/training',
            '/setup/freegame': '/einrichtung/freiesspiel',
            '/setup/tournament': '/einrichtung/turnier',
            '/table': '/tabelle',
            '/toplist': '/rangliste',
            '/training': '/training',
            '/freegame': '/freiesspiel',
            '/tournament': '/turnier',
            '/end': '/ende',
            '/profile': '/profil',
            '/debug': '/debug',
            '/play': '/play',
        },
    },
};

const LocaleContext = createContext<{
    urlPath: Url;
    locale: string;
    allLocales: { locale: string; country: string }[];
    country: string;
    localizedCountryCodes: Record<string, string>;
    localeResources: LocaleResources;
    localeConfig: LocaleConfig;
    allLocaleConfigs: { [key: string]: LocaleConfig };
    localeUrls: LocaleUrls;
    allLocaleUrls: { [key: string]: LocaleUrls };
}>(defaultValue);

interface WrapWithProviderArgs {
    element: JSX.Element;
    props: {
        pageContext: {
            urlPath: Url;
            locale: string;
            localeUrlPath: string;
            allLocales: { locale: string; country: string }[];
            country: string;
            localizedCountryCodes: Record<string, string>;
            localeResources: LocaleResources;
            localeConfig: LocaleConfig;
            allLocaleConfigs: { [key: string]: LocaleConfig };
            localeUrls: LocaleUrls;
            allLocaleUrls: { [key: string]: LocaleUrls };
        };
    };
}

interface LocaleGuardProps {
    locale: string;
    urlPath: Url;
    children: ReactNode | ReactNode[];
}

const LocaleGuard = ({ children, urlPath, locale }: LocaleGuardProps) => {
    const [storedLocale] = useLocalStorageState('locale', { defaultValue: 'de' });
    const { navigate } = useLocaleNavigation();

    useEffect(() => {
        if (storedLocale !== locale && urlPath !== '/debug' && urlPath !== '/play') {
            navigate(urlPath, { locale: storedLocale });
        }
    }, [locale, navigate, storedLocale, urlPath]);

    return <div>{children}</div>;
};

const wrapWithProvider = ({
    element,
    props: {
        pageContext: {
            urlPath,
            locale,
            allLocales,
            country,
            localizedCountryCodes,
            localeResources,
            localeConfig,
            allLocaleConfigs,
            localeUrls,
            allLocaleUrls,
        },
    },
}: WrapWithProviderArgs): JSX.Element => {
    return (
        <LocaleContext.Provider
            value={{
                urlPath,
                locale,
                allLocales,
                country,
                localizedCountryCodes,
                localeResources,
                localeConfig,
                allLocaleConfigs,
                localeUrls,
                allLocaleUrls,
            }}>
            <LocaleGuard locale={locale} urlPath={urlPath}>
                {element}
            </LocaleGuard>
        </LocaleContext.Provider>
    );
};

interface Translation {
    t: (
        key: string,
        options?:
            | {
                  pluralCount?: number | undefined;
                  placeholders?:
                      | {
                            [key: string]: React.ReactText;
                        }
                      | undefined;
              }
            | undefined,
    ) => string;
    config: LocaleConfig;
    urls: LocaleUrls;
    locale: string;
    allLocales: {
        locale: string;
        country: string;
    }[];
    country: string;
    localizedCountryCodes: Record<string, string>;
    allLocaleUrls: {
        [key: string]: LocaleUrls;
    };
    allLocaleConfigs: {
        [key: string]: LocaleConfig;
    };
    urlPath: Url;
}

export const useTranslation = (): Translation => {
    const {
        urlPath,
        locale,
        allLocales,
        country,
        localizedCountryCodes,
        localeResources,
        localeConfig,
        allLocaleConfigs,
        localeUrls,
        allLocaleUrls,
    } = useContext(LocaleContext);
    const t = useCallback(
        (
            key: string,
            options?: {
                pluralCount?: number;
                placeholders?: { [key: string]: string | number };
            },
        ) => {
            let translation = localeResources[key] || '';

            if (options?.pluralCount !== undefined) {
                if (options.pluralCount === 0 && localeResources[`${key}_empty`])
                    translation = localeResources[`${key}_empty`];
                else if (options.pluralCount > 1 && localeResources[`${key}_plural`])
                    translation = localeResources[`${key}_plural`];
            }

            options &&
                options.placeholders &&
                Object.entries(options.placeholders).forEach(([k, v]) => {
                    translation = translation.replace(`{{${k}}}`, `${v}`);
                });
            return translation;
        },
        [localeResources],
    );
    return {
        t,
        config: localeConfig,
        urls: localeUrls,
        locale,
        allLocales,
        country,
        localizedCountryCodes,
        allLocaleUrls,
        allLocaleConfigs,
        urlPath,
    };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface LocaleNavigateOptions extends NavigateOptions<any> {
    locale?: string;
    openInNewTab?: boolean;
    pathParams?: string[];
    queryParams?: { [key: string]: string };
}

interface LocaleNavigation {
    navigate: (path: Url | number, options?: LocaleNavigateOptions) => Promise<void>;
    prefetchPathname: (path: Url) => void;
}

const expandQueryParams = (path: string, queryParams?: { [key: string]: string }) => {
    if (!queryParams) return path;

    const entries = Object.entries(queryParams);
    if (entries.length === 0) return path;

    const first = entries.pop();
    if (!first) return path;

    let pathWithParams = `${path.endsWith('/') ? path.slice(0, path.length - 2) : path}?${first[0]}=${first[1]}`;
    entries.forEach(([k, v]) => {
        pathWithParams += `&${k}=${v}`;
    });
    return pathWithParams;
};

const expandPathParams = (path: string, pathParams?: string[]) => {
    if (!pathParams || pathParams.length === 0) return path;
    return `${path}/${pathParams.join('/')}`;
};

export const useLocaleNavigation = (): LocaleNavigation => {
    const { config, urls, allLocaleUrls, allLocaleConfigs } = useTranslation();
    const safeConfig: LocaleConfig = config || defaultValue.localeConfig;
    const localizePath = useCallback(
        (path: Url, locale?: string) => {
            const defaultLang = locale ? allLocaleConfigs[locale].defaultLang : safeConfig.defaultLang;
            const urlPath = locale ? allLocaleUrls[locale][path] : urls[path];
            const navPath = locale ? allLocaleConfigs[locale].path : safeConfig.path;
            return removeTrailingSlash(defaultLang ? `${urlPath}` : `${`/${navPath}`}${urlPath}`);
        },
        [allLocaleConfigs, allLocaleUrls, safeConfig.defaultLang, safeConfig.path, urls],
    );

    const navigate = useCallback(
        (path: Url | number, options?: LocaleNavigateOptions) => {
            if (typeof path === 'string') {
                const localizedPath = localizePath(path, options?.locale);
                const localizedPathWithParams = expandQueryParams(
                    expandPathParams(localizedPath, options?.pathParams),
                    options?.queryParams,
                );
                if (options?.openInNewTab) {
                    window.open(localizedPathWithParams, '_blank');
                    return Promise.resolve();
                } else return _navigate(localizedPathWithParams, options);
            } else {
                return _navigate(path);
            }
        },
        [localizePath],
    );

    const prefetchPathname = useCallback((path: Url): void => _prefetchPathname(localizePath(path)), [localizePath]);

    return useMemo(
        () => ({
            navigate,
            prefetchPathname,
        }),
        [navigate, prefetchPathname],
    );
};

export default wrapWithProvider;
