/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { createTheme, ThemeProvider, StyledEngineProvider, Theme } from '@mui/material/styles';
import createSagaMiddleware from '@redux-saga/core';
import { QueryClientProvider } from '@tanstack/react-query';
import React, { createContext, ReactElement, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { applyMiddleware, combineReducers, createStore, Middleware, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { createLogger } from 'redux-logger';
import createRootSaga from 'redux-root-saga';
import { queryClient } from '~/apis';
import { useFirebaseAnalytics } from '~/apis/useFirebase';
import { AuthProvider } from '~/auth';
import { boardReducer, BoardState, cameraSaga, initialBoardState } from '~/board';
import { DebugMenu } from '~/debug';
import { gameReducer, GameState, initialGameState } from '~/game';
import { initialOverlayState, overlayReducer, OverlayState } from '~/overlay';
import { initialState as initialSetupState, reducer as setupReducer, State as SetupState } from '~/setup';
import { config } from './config';
import mainSaga from './mainSaga';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
declare module '@mui/styles' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

if (process.env.NODE_ENV === 'test') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (HelmetProvider as any).canUseDOM = false;
}

export const primary = '#026666';
export const secondary = '#f8dd64';

const theme = createTheme({
    palette: { primary: { main: primary }, secondary: { main: secondary } },
    components: { MuiLink: { styleOverrides: { underlineAlways: false, underlineHover: true } } },
});

export interface State {
    readonly game: GameState;
    readonly board: BoardState;
    readonly overlay: OverlayState;
    readonly setup: SetupState;
}

export const initialState: State = {
    game: initialGameState,
    board: initialBoardState,
    overlay: initialOverlayState,
    setup: initialSetupState,
};

const reducer = combineReducers({
    game: gameReducer,
    board: boardReducer,
    overlay: overlayReducer,
    setup: setupReducer,
});

const sagaMiddleware = createSagaMiddleware();

const configureStore = () => {
    const middlewares: Middleware[] = [sagaMiddleware];
    if (process.env.NODE_ENV === `development` && config.reduxLogEnabled) middlewares.push(createLogger());

    const composeEnhancer = composeWithDevTools({ actionsBlacklist: ['TICK'] });
    const enhancer = composeEnhancer(applyMiddleware(...middlewares));
    return createStore(reducer, initialState, enhancer);
};

const initStore = (): Store => {
    const store = configureStore();
    const rootSaga = createRootSaga([mainSaga, cameraSaga], { onError: (e) => console.log(e) });
    sagaMiddleware.run(rootSaga);
    return store;
};

export const FullscreenContext = createContext({
    isFullscreen: false,
    setIsFullscreen: (fullscreen: boolean) => {},
    toggleIsFullscreen: () => {},
    dontAskAgain: false,
    setDontAskAgain: (dontAskAgain: boolean) => {},
});

const fullscreenDontAskAgain = 'fullscreenDontAskAgain';

const FullscreenComponent = ({ children }: { children: ReactElement }) => {
    const analytics = useFirebaseAnalytics();

    const [dontAskAgain, setDontAskAgain] = useState(() =>
        typeof localStorage !== 'undefined' ? localStorage.getItem(fullscreenDontAskAgain) === 'yes' || false : false,
    );

    const handle = useFullScreenHandle();

    return (
        <FullScreen
            handle={handle}
            onChange={(isFullscreen) => {
                if (isFullscreen) {
                    analytics?.logEvent('use_fullscreen_mode', { isMobile });
                } else {
                    analytics?.logEvent('use_window_mode', { isMobile });
                }
            }}>
            <FullscreenContext.Provider
                value={{
                    isFullscreen: handle.active,
                    setIsFullscreen: (state) => (state ? handle.enter() : handle.exit()),
                    toggleIsFullscreen: () => (handle.active ? handle.exit() : handle.enter()),
                    dontAskAgain,
                    setDontAskAgain: (dontAskAgain) => {
                        localStorage.setItem(fullscreenDontAskAgain, dontAskAgain ? 'yes' : 'no');
                        setDontAskAgain(dontAskAgain);
                    },
                }}>
                {children}
            </FullscreenContext.Provider>
        </FullScreen>
    );
};

// eslint-disable-next-line react/display-name
// eslint-disable-next-line @typescript-eslint/no-explicit-any,@typescript-eslint/explicit-module-boundary-types
const wrapWithProvider = ({ element }: any): JSX.Element => {
    // Instantiating store in `wrapRootElement` handler ensures:
    //  - there is fresh store for each SSR page
    //  - it will be called only once in browser, when React mounts
    const store = initStore();

    return (
        <React.StrictMode>
            <StyledEngineProvider injectFirst>
                <ThemeProvider {...{ theme }}>
                    <QueryClientProvider client={queryClient}>
                        <Provider store={store}>
                            <AuthProvider>
                                <FullscreenComponent>
                                    <>
                                        <HelmetProvider>{element}</HelmetProvider>
                                        <DebugMenu />
                                    </>
                                </FullscreenComponent>
                            </AuthProvider>
                        </Provider>
                    </QueryClientProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </React.StrictMode>
    );
};

export default wrapWithProvider;
