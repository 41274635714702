import { Horse, LeagueType, UserInternal, UserRole, UserSubscription, UserSubscriptionStatus } from '~/server/src/dtos';
import RandomNames from '~/server/src/randomnames';
import { getRandomIntInclusive } from '~/utils';

const genId = () => Math.random().toString(36).substr(2, 9);

const userIds = Array.from({ length: 24 }).map(() => genId());
const userCount = userIds.length;

const horseIds = Array.from({ length: userCount * 4 }).map(() => genId());

const randomNames = new RandomNames();

export const horses = horseIds.map<Horse>((horseId, index) => {
    return {
        horseId,
        name: randomNames.getRandomHorseName(),
        color: getRandomIntInclusive(0, 8),
        jockeyName: randomNames.getRandomJockeyName(),
        owner: {
            userId: '',
            country: 'DE',
            description: '',
            toplistPoints: 0,
            globalToplistPoints: 0,
            toplistPromotion: 0,
            toplistRank: 0,
            toplistLeague: LeagueType.Bronze,
            username: randomNames.getRandomStudFarmName(),
        },
        pointsBronze: index,
        pointsSilver: index,
        pointsGold: index,
        toplistPoints: index,
        globalToplistPoints: index,
    };
});

export const users: UserInternal[] = userIds.map((userId, index) => {
    // toplist points of users are the index
    const toplistPoints = index;

    // globalToplistPoints points of users are the index
    const globalToplistPoints = index;

    // users are ranked by their toplist points
    const toplistRank = userIds.length - index;

    // promotion of user is determined by thirds of the toplistRank
    const toplistPromotion = toplistRank < userCount / 3 ? 1 : toplistRank < (userCount / 3) * 2 ? 0 : -1;

    const today = new Date();
    const yesterday = new Date(today);
    const tomorrow = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    tomorrow.setDate(today.getDate() + 1);
    const registerDate = yesterday.toString();
    const subscriptionValidUntil = tomorrow.toString();

    return {
        role: UserRole.User,
        country: 'DE',
        firebaseUid: '',
        email: '',
        userId,
        username: randomNames.getRandomStudFarmName(),
        description: 'Erlebe die Faszination des Pferderennsports!',
        toplistPoints,
        globalToplistPoints,
        toplistLeague: LeagueType.Silver,
        toplistRank,
        toplistPromotion,
        subscription: UserSubscription.Premium,
        subscriptionStatus: UserSubscriptionStatus.Active,
        subscriptionValidUntil: subscriptionValidUntil,
        registerDate,
        horses: horses.slice(index, index + 4),
    };
});
