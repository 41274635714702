/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { chunk } from 'lodash';
import { EventChannel } from 'redux-saga';
import { all, put, take } from 'redux-saga/effects';
import { accountCacheKey, queryClient } from '~/apis';
import { getFirebaseAnalytics } from '~/apis/firebase';
import { StartTrainingParameters } from '~/game';
import { GameType, UserInternal } from '~/server/src/dtos';
import { Computer, Horse, NewGameRequest, Player } from '~/server/src/dtos/controller';
import { Controller, View } from '~/server/src/interfaces';
import RandomNames from '~/server/src/randomnames';
import Turfmaster from '~/server/src/tmmodel';
import { actions } from '../state';

const gameType = GameType.Training;

function* sagaSetupTraining(
    view: View,
    controller: Controller,
    gameParams: StartTrainingParameters,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    channel: EventChannel<any>,
) {
    const randomNames = new RandomNames();
    const analytics = getFirebaseAnalytics();

    const account = queryClient.getQueryData<UserInternal>([accountCacheKey]); // TODO: might still wanna fetch if not called before

    const { playerCount, horseCount, horseSelection, oppHorseSelection } = gameParams;
    const horseAmount = playerCount * horseCount;

    const player: Player = {
        name: account?.username ?? randomNames.getRandomStudFarmName(),
        country: account?.country ?? randomNames.getRandomCountryCode(),
        horses: horseSelection.map<Horse>(({ horseId }) => {
            const horse = account?.horses?.find((h) => h.horseId === horseId);
            return {
                horseid: horseId,
                color: horse?.color,
                jockeyName: horse?.jockeyName,
                name: horse?.name,
            };
        }),
    };

    const computers: Computer[] = chunk(oppHorseSelection, horseCount).map((horses, botIndex) => ({
        horses: horses.map(({ horseId }) => ({ horseid: `bot_${botIndex}_horse_${horseId}` })),
        strength: Turfmaster.compStrengths.MEDIUM,
    }));

    const newGameRequest: NewGameRequest = {
        gameType,
        gameName: 'training',
        horseamount: horseAmount,
        player,
        computers,
        view,
    };

    // send NEWGAME as a receiver callback for controller messages
    controller.receiveMsg(Turfmaster.stateMachine.NEWGAME, newGameRequest);

    // endless loop because their could be 'wrong' messages in the channel because of
    // multiple processes using it or the controller just sending wrong messages in between
    // we therefore have listen until the right message is received and then break the loop
    while (true) {
        // wait for newgame response to make sure game is setup successfully
        const { state, params } = yield take(channel);
        if (state === Turfmaster.stateMachine.NEWGAME) {
            console.log('controller => client:', 'NEWGAME', params, controller.getGame(), controller.getBoard());
            yield all([put(actions.setPlayerId(params.playerid)), put(actions.setGameType(gameType))]);

            analytics?.logEvent('start_game', {
                connectionType: controller.getType(),
                gameType,
                playerCount,
                horseCountPerPlayer: horseCount,
            });
            break;
        }
    }
}

export default sagaSetupTraining;
