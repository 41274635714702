import { Link } from '@mui/material';
import { nanoid } from '@reduxjs/toolkit';
import cn from 'classnames';
import React, { useState } from 'react';
import { useCreateTournament, useTournaments } from '~/apis';
import { LeagueType } from '~/server/src/dtos';
interface TournamentControlsProps {
    className?: string;
}

const TournamentControls = ({ className }: TournamentControlsProps): JSX.Element => {
    const createTournament = useCreateTournament();

    const tournaments = useTournaments();

    const [request, setRequest] = useState(() => {
        return {
            name: `Debug Turnier ${nanoid(6)}`,
            description: 'Aufregend. Schnell. Purer Nervenkitzel - So geht Turfmaster.',
            lobbyOpenTimeDiff: 10,
            tournamentLeague: LeagueType.Silver,
        };
    });

    return (
        <div className={cn('flex flex-col mb-4 space-y-2', className)}>
            <h2 className="font-bold">Turnier Funktionen</h2>

            <div>{`Anzahl angelegter Turniere: ${tournaments?.data?.length}`}</div>

            <label>
                Name
                <input
                    type="text"
                    value={request.name}
                    onChange={({ target: { value } }) => setRequest((request) => ({ ...request, name: value }))}
                />
            </label>

            <label>
                Zeit zwischen Join und Start
                <input
                    type="number"
                    value={request.lobbyOpenTimeDiff}
                    onChange={({ target: { value } }) =>
                        setRequest((request) => ({ ...request, lobbyOpenTimeDiff: Number.parseInt(value) }))
                    }
                />
            </label>

            <label>
                Turniertyp
                <select
                    value={request.tournamentLeague}
                    onChange={({ target: { value } }) =>
                        setRequest((request) => ({ ...request, tournamentLeague: value as LeagueType }))
                    }>
                    {Object.values(LeagueType).map((type) => (
                        <option key={type} value={type}>
                            {type}
                        </option>
                    ))}
                </select>
            </label>

            <Link
                underline="hover"
                className="cursor-pointer"
                onClick={() => {
                    const now = new Date().getTime();
                    const firstTime = now + 20 * 1000;
                    const secondTime = firstTime + request.lobbyOpenTimeDiff * 1000 + 20 * 1000;
                    const startingTimes = [new Date(firstTime).toISOString(), new Date(secondTime).toISOString()];
                    createTournament.mutate({ ...request, startingTimes });
                }}>
                Erstelle Turnier
            </Link>
        </div>
    );
};

export default TournamentControls;
