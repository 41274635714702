/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import React from 'react';

const HorseIcon = (props: React.SVGProps<SVGSVGElement>): JSX.Element => (
    <svg viewBox="0 0 256 256" width={256} height={256} {...props}>
        <g fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}>
            <path
                d="M168 40h-16v8a32 32 0 01-64 0v-8M152 40H88h0V29.333A21.333 21.333 0 01109.333 8h21.334A21.333 21.333 0 01152 29.333V40h0zM157.696 102.187C172.523 83.253 194.784 56 200 56l8 32 40 24-4.352 13.056A16 16 0 01228.469 136H200a348.8 348.8 0 00-16 96"
                strokeWidth={16.000005}
            />
            <path
                d="M55.99 248a16 16 0 01-14.305-23.157l10.464-20.918a16 16 0 00-6.4-21.045l-29.685-16.992a16 16 0 01-1.664-26.667l64-48a16 16 0 0123.317 4.566l18.454 30.752a16 16 0 007.776 6.624l29.994 12a16.002 16.002 0 01-11.882 29.717l-29.995-12a48.352 48.352 0 01-23.328-19.872l-9.333-15.552-30.326 22.73 8.534 4.907a48 48 0 0119.125 63.136l-10.464 20.928A16 16 0 0155.989 248z"
                strokeWidth={16.000005}
            />
        </g>
    </svg>
);

export default HorseIcon;
