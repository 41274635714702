/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import React, { memo } from 'react';
import { HorseColor, horseColorIndex, HorseColorValues } from '~/app';

export interface Props {
    value: number;
    color?: HorseColor;
    className?: string;
    style?: React.CSSProperties;
    disabled?: boolean;
    onClick?: () => void;
}

const topL = [2, 3, 4, 5, 6];
const topR = [4, 5, 6];
const midL = [6];
const midC = [1, 3, 5];
const midR = [6];
const botL = [4, 5, 6];
const botR = [2, 3, 4, 5, 6];

const disabledOpacity = 0.5;

const Dice = ({
    value,
    color = horseColorIndex.BLUE,
    className = '',
    style = {},
    disabled = false,
    onClick,
}: Props) => (
    <svg
        viewBox="0 0 217.505 217.505"
        opacity={disabled ? disabledOpacity : 1}
        className={className}
        style={style}
        onClick={onClick}
        onKeyPress={onClick}
        role={onClick ? 'button' : 'img'}
        tabIndex={onClick ? 0 : undefined}>
        <path
            fill={HorseColorValues[color]}
            stroke="#FFF"
            strokeWidth={5.669}
            strokeMiterlimit={10}
            d="M214.67 197.034c0 9.74-7.896 17.636-17.636 17.636H20.47c-9.74 0-17.635-7.896-17.635-17.636V20.47c0-9.74 7.895-17.635 17.635-17.635h176.564c9.739 0 17.636 7.895 17.636 17.635v176.564z"
        />
        {topL.includes(value) && <circle fill="#FFF" cx={55.794} cy={55.794} r={17.653} />}
        {topR.includes(value) && <circle fill="#FFF" cx={161.711} cy={55.794} r={17.654} />}
        {midL.includes(value) && <circle fill="#FFF" cx={55.793} cy={108.752} r={17.653} />}
        {midC.includes(value) && <circle fill="#FFF" cx={108.752} cy={108.752} r={17.652} />}
        {midR.includes(value) && <circle fill="#FFF" cx={161.711} cy={108.752} r={17.653} />}
        {botL.includes(value) && <circle fill="#FFF" cx={55.793} cy={161.71} r={17.653} />}
        {botR.includes(value) && <circle fill="#FFF" cx={161.711} cy={161.71} r={17.653} />}
        {disabled && [
            <line
                key="0"
                x1="10"
                x2="207.505"
                y1="10"
                y2="207.505"
                stroke="#FFF"
                strokeWidth={5.669}
                strokeMiterlimit={10}
            />,
            <line
                key="1"
                x1="10"
                x2="207.505"
                y1="207.505"
                y2="10"
                stroke="#FFF"
                strokeWidth={5.669}
                strokeMiterlimit={10}
            />,
        ]}
    </svg>
);

export default memo(Dice);
