import { useEffect, useRef } from 'react';

export const useOnStateChange = <T>(state: T, onChange: (newValue: T, previousValue: T) => void): void => {
    const previous = useRef(state);
    useEffect(() => {
        if (state !== previous.current) {
            onChange(state, previous.current);
            previous.current = state;
        }
    }, [onChange, state]);
};

export const useOnStateChanges = <T extends unknown[]>(
    state: T,
    onChange: (newValue: T, previousValue: T) => void,
): void => {
    const previous = useRef(state);
    useEffect(() => {
        if (state.reduce((prev, part, index) => prev || part !== previous.current[index], false)) {
            onChange(state, previous.current);
            previous.current = state;
        }
    }, [onChange, state]);
};
