import {
    PaymentCreateSubscriptionRequest,
    PaymentCreateSubscriptionResponse,
    PaymentDeleteSubscriptionRequest,
    PaymentInitClientResult,
} from '~/server/src/dtos';
import {
    accountCacheKey,
    deleteFn,
    getFn,
    MessageResponse,
    paymentCacheKey,
    postFn,
    queryClient,
    useMutation,
    useQuery,
    UseQueryOptions,
} from './api';
import { useFirebaseAnalytics } from './useFirebase';

export const getPayment = `/payment`;
export const getPaymentInitClient = `${getPayment}/initClient`;
export const postPaymentSubscription = `${getPayment}/subscriptions`;
export const deletePaymentSubscription = `${getPayment}/subscriptions`;

export const usePaymentInitClient = (options?: UseQueryOptions<PaymentInitClientResult>) => {
    return useQuery<PaymentInitClientResult>([paymentCacheKey], () => getFn(getPaymentInitClient), options);
};

export const useCreateSubscription = () => {
    const analytics = useFirebaseAnalytics();
    return useMutation<PaymentCreateSubscriptionResponse, PaymentCreateSubscriptionRequest>(
        (data) => postFn(postPaymentSubscription, data),
        {
            onSuccess: (_data, variables) => {
                queryClient.resetQueries([paymentCacheKey]);
                queryClient.invalidateQueries([accountCacheKey]);
                analytics?.logEvent('purchase_subscription', {
                    subscription: variables.subscription,
                });
            },
            onError: (error, variables) => {
                queryClient.resetQueries([paymentCacheKey]);
                analytics?.logEvent('exception', {
                    description: `purchase_subscription failed: ${error.message}`,
                    subscription: variables.subscription,
                });
            },
        },
    );
};

export const useDeleteSubscription = () => {
    const analytics = useFirebaseAnalytics();
    return useMutation<MessageResponse, PaymentDeleteSubscriptionRequest>(
        (data) => deleteFn(deletePaymentSubscription, data),
        {
            onSuccess: (_data, variables) => {
                queryClient.resetQueries([paymentCacheKey]);
                queryClient.invalidateQueries([accountCacheKey]);
                analytics?.logEvent('delete_subscription', {
                    subscription: variables.subscription,
                });
            },
            onError: (error, variables) => {
                queryClient.resetQueries([paymentCacheKey]);
                analytics?.logEvent('exception', {
                    description: `delete_subscription failed: ${error.message}`,
                    subscription: variables.subscription,
                });
            },
        },
    );
};
