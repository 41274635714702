/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Dialog, DialogContent, DialogTitle, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { forwardRef, useState } from 'react';
import { useAccount } from '~/apis';
import { DEBUG_enabled } from '~/app';
import { useAuthState } from '~/auth';
import { UserRole } from '~/server/src/dtos';
import AdminControls from './AdminControls';
import DebugControls from './DebugControls';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import iconSrc from './icon.png';
import SnapshotList from './SnapshotList';
import TournamentControls from './TournamentControls';

const Transition = forwardRef(function Transition(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DebugMenu = (): JSX.Element | null => {
    const [isDebugOpen, setIsDebugOpen] = useState(false);
    const { data: account } = useAccount();
    const { isAuthorized } = useAuthState();

    if (!DEBUG_enabled) return null;
    return (
        <>
            <ReactQueryDevtools
                initialIsOpen={false}
                toggleButtonProps={{
                    style: {
                        position: 'absolute',
                        display: 'flex',
                        left: 0,
                        bottom: 152,
                        alignSelf: 'center',
                        width: 40,
                        height: 40,
                        marginLeft: 24,
                        zIndex: 1,
                    },
                }}
            />

            {account && isAuthorized && (
                <>
                    <img
                        src={iconSrc}
                        alt="debug icon"
                        onClick={() => setIsDebugOpen(true)}
                        style={{
                            position: 'absolute',
                            left: 0,
                            bottom: 92,
                            width: 40,
                            height: 40,
                            margin: '0.5rem',
                            marginLeft: 24,
                            cursor: 'pointer',
                            zIndex: 1,
                        }}
                    />

                    <Dialog
                        open={isDebugOpen}
                        onClose={() => setIsDebugOpen(false)}
                        TransitionComponent={Transition}
                        aria-labelledby="debugDialogTitle"
                        maxWidth="xl"
                        fullWidth>
                        <DialogTitle id="debugDialogTitle">Turfmaster Debugger</DialogTitle>
                        <DialogContent className="flex flex-col mb-4 space-y-8">
                            <DebugControls />

                            {account?.role === UserRole.Admin ? (
                                <>
                                    <div className="lg:flex-row lg:space-x-8 lg:space-y-0 flex flex-col space-y-4">
                                        <TournamentControls className="flex-1" />
                                        <AdminControls className="flex-1" />
                                    </div>

                                    <SnapshotList onClose={() => setIsDebugOpen(false)} />
                                </>
                            ) : null}
                        </DialogContent>
                    </Dialog>
                </>
            )}
        </>
    );
};

export default DebugMenu;
