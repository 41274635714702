/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { Fab } from '@mui/material';
import { animated, config, SpringConfig, useSpring } from '@react-spring/web';
import { useGesture } from '@use-gesture/react';
import { easeQuadInOut } from 'd3-ease';
import { StaticImage } from 'gatsby-plugin-image';
import clamp from 'lodash/clamp';
import isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useFirebaseAnalytics } from '~/apis/useFirebase';
import { Config, selectCameraPosition, selectHorses, State, useTranslation } from '~/app';
import { useOnStateChange, vh, vw } from '~/utils';
import { ReactComponent as ImgCamera } from './button-camera.svg';
import FieldMarkerView from './FieldMarkerView';
import Horse from './Horse';
import HorsePathsView from './HorsePathsView';
import HurdleView from './HurdleView';
import { Horse as HorseModel } from './reducer';

/**
 * 1: -50vw = offset from left screen to left board
 * 2: 100vw = absolute width of board
 * 3: + 50vw = half of viewport
 *
 * @param x position as percentage of board width
 */
const calculateCamX = (x: number) => `calc((-50vw - ${x} * 100vw) + 50vw)`;

/**
 * 1: calculate padding between screen top and board top
 * 2: 100vw / 3072 * 2400: absolute height of board
 * 3: + 50vh: half of viewport
 *
 * @param y position as percentage of board width
 */
const calculateCamY = (y: number) =>
    `calc(((200vw / ${vw} * ${vh}) - (100vw / ${vw} * ${vh})) / -2 - ${y} * (100vw / ${vw} * ${vh}) + 50vh)`;

const Board = (): JSX.Element => {
    const analytics = useFirebaseAnalytics();
    const { t } = useTranslation();

    const horses = useSelector<State, HorseModel[]>(selectHorses);

    const transform = (x: number, y: number, scale: number) =>
        `scale3d(${scale}, ${scale}, 1) translate3d(${calculateCamX(x)}, ${calculateCamY(y)}, 0)`;
    const [cameraManualEnabled, setCameraManualEnabled] = useState(false);

    /**
     * Animate camera translation and zoom.
     */
    const { x, y, scale } = useSelector(selectCameraPosition);
    const [queue, setQueue] = useState(() => [{ x, y, scale }]);
    const [manScale, setManScale] = useState(scale);
    const [dragPos, setDragPos] = useState(() => ({ x, y }));

    const boardRootRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (
            !cameraManualEnabled &&
            (!queue.length || (queue.length && !isEqual(queue[queue.length - 1], { x, y, scale })))
        ) {
            setQueue((queue) => [...(queue.slice(0, 1) || []), { x, y, scale }]);
        }
    }, [x, y, scale, cameraManualEnabled, queue]);

    const { cameraTransform } = useSpring({
        from: { cameraTransform: [0.5, 0.5, 0.9] },
        to: async (next) => {
            const { x: nextX, y: nextY, scale: nextScale } = queue && queue.length ? queue[0] : { x, y, scale };
            await next({
                cameraTransform: [
                    cameraManualEnabled ? dragPos.x : nextX,
                    cameraManualEnabled ? dragPos.y : nextY,
                    cameraManualEnabled ? manScale : nextScale,
                ],
            });
        },
        config: cameraManualEnabled ? { duration: 0 } : { duration: 1500, easing: easeQuadInOut },
        onRest: () => {
            if (queue.length > 1) {
                setQueue((queue) => queue.slice(1));
            }
        },
    });

    /**
     * Dragging gestures for manual camera control
     */
    useEffect(() => {
        if (!cameraManualEnabled) setManScale(scale);
    }, [cameraManualEnabled, scale]);

    useEffect(() => {
        if (!cameraManualEnabled) setDragPos({ x, y });
    }, [cameraManualEnabled, x, y]);

    useGesture(
        {
            onDragStart: () => {
                if (!cameraManualEnabled) setCameraManualEnabled(true);
            },
            onWheelStart: () => {
                if (!cameraManualEnabled) setCameraManualEnabled(true);
            },
            onDrag: ({ down, movement, memo = cameraTransform.get() }) => {
                // tbd: make more natural
                if (!down) return;
                const newX = clamp(memo[0] - movement[0] / window.innerWidth / manScale, -0.25, 1.25);
                const newY = clamp(memo[1] - movement[1] / window.innerHeight / manScale, -0.25, 1.25);
                setDragPos({ x: newX, y: newY });
                return memo;
            },
            onWheel: ({ pinching, delta }) => {
                const factor = pinching ? 0.005 : 0.001;
                const dy = -delta[1];
                setManScale((manScale) => clamp(manScale + dy * factor, 0.5, 2));
            },
            onPinch: () => {},
        },
        {
            drag: { delay: true, filterTaps: true, preventDefault: true },
            wheel: { preventDefault: true },
            pinch: { preventDefault: true },
            eventOptions: { passive: false },
            target: boardRootRef,
        },
    );

    /**
     * Return button animation
     */
    const { scaleX } = useSpring<{ scaleX: number; config: SpringConfig }>({
        scaleX: cameraManualEnabled ? 100 : 0,
        config: cameraManualEnabled ? config.wobbly : config.default,
    });

    useOnStateChange(
        cameraManualEnabled,
        useCallback(
            (newCameraManualEnabled) => {
                if (newCameraManualEnabled) {
                    analytics?.logEvent('set_camera_manual');
                } else {
                    analytics?.logEvent('set_camera_auto');
                }
            },
            [analytics],
        ),
    );

    useEffect(() => {
        const callback: EventListener = (e) => e.preventDefault();
        document.addEventListener('gesturestart', callback);
        document.addEventListener('gesturechange', callback);
        return () => {
            document.removeEventListener('gesturestart', callback);
            document.removeEventListener('gesturechange', callback);
        };
    }, []);

    return (
        <>
            <animated.div
                ref={boardRootRef}
                className="touch-none w-[200vw] h-[calc(200vw_/_3072_*_2400)] origin-[50vw_50vh] cursor-grab active:cursor-grabbing flex justify-center items-center overflow-hidden"
                style={{
                    transform: cameraTransform.to(transform),
                }}>
                <StaticImage
                    src="../images/wood.jpg"
                    quality={90}
                    draggable={false}
                    className="inset-0 absolute"
                    objectFit="fill"
                    layout="fixed"
                    placeholder="blurred"
                    alt={t('game_backgroundAlt')}
                />

                <div className="w-[100vw] h-[calc(100vw_/_3072_*_2400)] absolute">
                    <StaticImage
                        draggable={false}
                        quality={90}
                        src="../images/board.jpg"
                        className="absolute w-full h-full "
                        placeholder="blurred"
                        alt={t('game_boardAlt')}
                    />

                    <div className="w-full h-full bg-red-300/20 absolute inset-0">
                        <HurdleView />
                        <HorsePathsView />
                        {horses.map((_horse, index) => (
                            <Horse key={index} index={index} />
                        ))}
                        {process.env.NODE_ENV === 'development' && Config.enableMarkerView && <FieldMarkerView />}
                    </div>
                </div>
            </animated.div>

            <div className="flex absolute top-[15vh] left-0 right-0 bottom-0 m-[32px] flex-col items-center transition-all ease-in-out duration-500 pointer-events-none animate-bouncy-pulse">
                <animated.div style={{ transform: scaleX.to((v) => `scaleX(${v / 100})`) }}>
                    <Fab
                        className="bg-white pointer-events-auto"
                        variant="extended"
                        onClick={() => cameraManualEnabled && setCameraManualEnabled(false)}>
                        <ImgCamera className="pr-[8px] pt-[4px] w-[32px] h-[32px]" />
                        {t('game_buttonCameraMode')}
                    </Fab>
                </animated.div>
            </div>
        </>
    );
};

export default Board;
