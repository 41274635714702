/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

export const cw = 800; // controller board width
export const ch = 600; // controller board height
export const ct = 9; // controller board top padding
export const cl = 8; // controller board left padding
export const cr = 5; // controller board right padding
export const cb = 31; // controller board bottom padding

export const vw = 3072; // view board width
export const vh = 2400; // view board height
export const vt = 145; // view board top padding
export const vl = 68; // view board left padding
export const vr = 77; // view board right padding
export const vb = 177; // view board bottom padding

/**
 * Transform absolute x-coordinate in pixels on the controller board to an absolute x-ccordinate on the new view board
 * @param x absolute x-coordinate in pixels on the controller board
 * @returns percentage representation of x relative to the new view board dimensions
 */
export const transformAbsX = (x: number): number => ((x - cl) / (cw - cl - cr)) * (vw - vl - vr) + vl;

/**
 * Transform absolute y-coordinate in pixels on the controller board to an absolute y-ccordinate on the new view board
 * @param x absolute y-coordinate in pixels on the controller board
 * @returns percentage representation of y relative to the new view board dimensions
 */
export const transformAbsY = (y: number): number => ((y - ct) / (ch - ct - cb)) * (vh - vt - vb) + vt;

/**
 * Transform absolute x-coordinate in pixels on the controller board into a percentage from the new view board
 * @param x absolute x-coordinate in pixels on the controller board
 * @returns percentage representation of x relative to the new view board dimensions
 */
export const transformRelX = (x: number): number => transformAbsX(x) / vw;

/**
 * Transform absolute y-coordinate in pixels on the controller board into a percentage from the new view board
 * @param x absolute y-coordinate in pixels on the controller board
 * @returns percentage representation of y relative to the new view board dimensions
 */
export const transformRelY = (y: number): number => transformAbsY(y) / vh;
