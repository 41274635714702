/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { EventChannel } from 'redux-saga';
import { all, put, take } from 'redux-saga/effects';
import { setConnected, setCurrentState, StartDebugParameters } from '~/game';
import { setLastCardHorseIndex, setLastTurnDicePlayerId, setLastTurnDices, setLastTurnDicesCur } from '~/overlay';
import { GameState } from '~/server/src/dtos';
import { Computer, Horse, NewGameRequest, Player } from '~/server/src/dtos/controller';
import { Controller, View } from '~/server/src/interfaces';
import Turfmaster from '~/server/src/tmmodel';
import { actions as setupActions } from '../state';

const defaultHorseCount = 2;
const defaultPlayerCount = 3;

function* sagaSetupDebug(
    view: View,
    controller: Controller,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    channel: EventChannel<any>,
    gameParams: StartDebugParameters = {
        horseCount: defaultHorseCount,
        playerCount: defaultPlayerCount,
        gameName: 'debug',
    },
) {
    const { playerCount = defaultPlayerCount, horseCount = defaultHorseCount, gameName } = gameParams;
    const horseAmount = playerCount * horseCount;
    const player: Player = {
        horses: [...Array(horseCount).keys()].map<Horse>((horseId) => ({
            horseid: horseId.toString(),
        })),
    };
    const computers: Computer[] = Array.from({ length: playerCount - 1 }, (_v, k) => k).map((playerIndex) => ({
        horses: Array.from({ length: horseCount }, (_v, k) => k).map((horseIndex) => ({
            horseid: `bot_${playerIndex * horseCount + horseIndex}`,
        })),
    }));

    const newGameRequest: NewGameRequest = {
        gameName: gameName ?? 'debug',
        horseamount: horseAmount,
        player,
        computers,
        view,
    };

    // send NEWGAME as a receiver callback for controller messages
    controller.receiveMsg(Turfmaster.stateMachine.NEWGAME, newGameRequest);

    const { state, params } = yield take(channel);

    if (state === Turfmaster.stateMachine.RESTOREGAME) {
        console.log('controller => client:', 'RESTOREGAME', { state, params });
        const restorableGameState: GameState | undefined = params?.game;

        if (restorableGameState) {
            controller.receiveMsg(Turfmaster.stateMachine.REJOINGAME, { view });
            const { state, params } = yield take(channel);
            yield put(setCurrentState(state));

            const game = controller.getGame();
            const board = controller.getBoard();

            if (state === Turfmaster.stateMachine.REJOINGAME && game && board) {
                console.log('controller => client:', 'REJOINGAME', { state, params }, game, board);

                const lastTurnDices = game.turndices.length > 0 ? game.turndices : [-1, -1];

                // rejoin successful
                yield all([
                    put(setupActions.setGameId(restorableGameState.gameId)),
                    put(setupActions.setGameType(restorableGameState.gameType)),
                    put(setupActions.setPlayerId(params.playerid)),
                    put(setCurrentState(game.currentState)),
                    put(setConnected(true)),
                    put(setupActions.setRestoreState('success')),

                    // try to reconstruct lastTurnDice player and array
                    put(setLastTurnDicePlayerId(game.turndiceplayerid)),
                    put(setLastTurnDices(lastTurnDices)),
                    put(setLastTurnDicesCur(game.turndicescur)),

                    // try to reconstruct last horse card index, card value is not possible
                    put(
                        setLastCardHorseIndex(
                            (game.turntype === Turfmaster.turntypes.CARDS
                                ? game.turnorder[(game.turnpos + game.turnpos - 1) % game.turnpos]
                                : -1) ?? -1,
                        ),
                    ),
                ]);

                // if state is selectmove while restoring, send SELECTCARDDICE with -1 to get moves via params again
                if (game.currentState === Turfmaster.stateMachine.SELECTMOVE) {
                    const horseid = game.turnorder[game.turnpos];
                    controller.receiveMsg(Turfmaster.stateMachine.SELECTCARDDICE, {
                        playerid: params.playerid,
                        horseid,
                        carddiceid: -1,
                    });
                }
            }
        }
    }
}

export default sagaSetupDebug;
