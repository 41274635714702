/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Slide,
    Switch,
    TextField,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { forwardRef, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCreateCustomSnapshot, useCreateSnapshot } from '~/apis';
import { useFirebaseAnalytics } from '~/apis/useFirebase';
import {
    DEBUG_enabled,
    loadLocalGameSnapshot,
    selectGameId,
    selectGameType,
    selectRound,
    selectTurnType,
    useTranslation,
} from '~/app';
import { GameState, GameType } from '~/server/src/dtos';
import { useOnStateChange } from '~/utils';
import * as styles from './Report.module.css';

interface ReportProps {
    isOpen: boolean;
    onClose: () => void;
}

const Transition = forwardRef(function Transition(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    props: TransitionProps & { children: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Report = ({ isOpen, onClose }: ReportProps): JSX.Element => {
    const analytics = useFirebaseAnalytics();

    const gameId = useSelector(selectGameId);
    const gameType = useSelector(selectGameType);
    const turnType = useSelector(selectTurnType);
    const round = useSelector(selectRound);

    const { t } = useTranslation();

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const [message, setMessage] = useState('');
    const [customGameState, setCustomGameState] = useState('');
    const [isCustomGameState, setIsCustomGameState] = useState(false);

    const createCustomSnapshot = useCreateCustomSnapshot({
        onSuccess: () => {
            onClose();
            analytics?.logEvent('send_report', { round, turnType, gameType, isCustom: true });
        },
    });
    const createSnapshot = useCreateSnapshot(gameId, {
        onSuccess: () => {
            onClose();
            analytics?.logEvent('send_report', { round, turnType, gameType, isCustom: false });
        },
    });

    const sendReport = () => {
        // send custom snapshot pasted into ui textfield
        if (isCustomGameState && customGameState && customGameState.length > 0) {
            try {
                const parsedGameState = JSON.parse(customGameState) as GameState;
                createCustomSnapshot.mutate({ message, userAgent: navigator.userAgent, gameState: parsedGameState });
            } catch (e) {
                console.log('error parsing custom game state', e);
            }
        }

        // create snapshot on server if current game is a remote game
        else if (gameType !== GameType.Training && gameId !== undefined) {
            // TODO: make sure gameType is set after restore and remove gameId conditional
            createSnapshot.mutate({ message, userAgent: navigator.userAgent });
        }

        // create snapshot from local storage in training mode
        else if (gameType === GameType.Training || gameId === undefined) {
            // TODO: make sure gameType is set after restore and remove gameId conditional
            const parsedGameState = loadLocalGameSnapshot();
            if (parsedGameState)
                createCustomSnapshot.mutate({ message, userAgent: navigator.userAgent, gameState: parsedGameState });
        }
    };

    const isLoading = createSnapshot.isLoading || createCustomSnapshot.isLoading;

    const cancel = useCallback(() => {
        onClose();
        analytics?.logEvent('cancel_report', {
            round,
            turnType,
            gameType,
            isCustom: gameType === GameType.Training,
        });
    }, [analytics, gameType, onClose, round, turnType]);

    useOnStateChange(
        isOpen,
        useCallback(
            (newIsOpen) => {
                if (newIsOpen) {
                    analytics?.logEvent('begin_report', {
                        round,
                        turnType,
                        gameType,
                        isCustom: gameType === GameType.Training,
                    });
                }
            },
            [analytics, gameType, round, turnType],
        ),
    );

    return (
        <Dialog
            fullScreen={fullScreen}
            TransitionComponent={Transition}
            aria-labelledby="reportDialogTitle"
            onClose={cancel}
            open={isOpen}>
            <DialogTitle id="reportDialogTitle">
                {t('report_title')}
                {isLoading && <CircularProgress size={16} className={styles.Progress} />}
            </DialogTitle>
            <DialogContent className={styles.Content}>
                <DialogContentText className={styles.ContentText}>{t('report_content')}</DialogContentText>
                <TextField
                    label={t('report_description')}
                    value={message}
                    onChange={(e) => setMessage(e.currentTarget.value)}
                    multiline
                />

                {DEBUG_enabled && (
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isCustomGameState}
                                onChange={(event) => setIsCustomGameState(event.target.checked)}
                            />
                        }
                        label={isCustomGameState ? t('report_gameStateSwitch_on') : t('report_gameStateSwitch_off')}
                        className={styles.Switch}
                    />
                )}

                {isCustomGameState && (
                    <TextField
                        label={t('report_customGameState')}
                        value={customGameState}
                        onChange={(e) => setCustomGameState(e.currentTarget.value)}
                        multiline
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={cancel} color="primary">
                    {t('report_cancel')}
                </Button>

                <Button onClick={sendReport} color="primary" disabled={message.length === 0}>
                    {t('report_send')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default Report;
