import { GameCreateSnapshotRequest, GameSnapshot, GameSnapshotRequest, GameState } from '~/server/src/dtos';
import {
    gamesCacheKey,
    getFn,
    MessageResponse,
    postFn,
    queryClient,
    snapshotsCacheKey,
    useMutation,
    UseMutationOptions,
    useQuery,
    UseQueryOptions,
    UseQueryResult,
} from './api';

export const getGames = `/games`;
export const postCreateSnapshot = (gameId: string): string => `${getGames}/${gameId}/createSnapshot`;
export const getSnapshots = `${getGames}/snapshots`;
export const getSnapshot = (snapshotId: string): string => `${getSnapshots}/${snapshotId}`;
export const postCustomSnapshot = getSnapshots;

export const useGames = (options: UseQueryOptions<GameState[]> = {}): UseQueryResult<GameState[]> => {
    return useQuery<GameState[]>([gamesCacheKey], () => getFn(getGames), options);
};

export const useSnapshots = (options: UseQueryOptions<GameSnapshot[]> = {}): UseQueryResult<GameSnapshot[]> => {
    return useQuery<GameSnapshot[]>([snapshotsCacheKey], () => getFn(getSnapshots), options);
};

export const useSnapshot = (snapshotId = '', options?: UseQueryOptions<GameSnapshot>): UseQueryResult<GameSnapshot> => {
    return useQuery<GameSnapshot>([snapshotsCacheKey], () => getFn(getSnapshot(snapshotId)), {
        ...options,
        enabled: !!snapshotId && (options?.enabled === undefined || options.enabled === true),
    });
};

export const useCreateSnapshot = (
    gameId?: string,
    options?: UseMutationOptions<MessageResponse, GameCreateSnapshotRequest>,
) => {
    return useMutation<MessageResponse, GameCreateSnapshotRequest>(
        (data) => postFn(postCreateSnapshot(gameId ?? ''), data),
        {
            ...options,
            onSuccess: (...response) => {
                queryClient.invalidateQueries([snapshotsCacheKey]);
                options?.onSuccess?.(...response);
            },
        },
    );
};

export const useCreateCustomSnapshot = (options?: UseMutationOptions<MessageResponse, GameSnapshotRequest>) => {
    return useMutation<MessageResponse, GameSnapshotRequest>((data) => postFn(postCustomSnapshot, data), {
        ...options,
        onSuccess: (...response) => {
            queryClient.invalidateQueries([snapshotsCacheKey]);
            options?.onSuccess?.(...response);
        },
    });
};
