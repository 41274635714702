/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import cn from 'classnames';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useFirebaseAnalytics } from '~/apis/useFirebase';
import {
    horseColorIndex,
    PathColorValues,
    selectCurrentState,
    selectHorses,
    selectMoves,
    selectPlayerId,
    selectSelectedDicesLocal,
    selectTurnDices,
    selectTurnDicesPlayerId,
    selectTurnOrder,
    selectTurnPos,
    selectTurnType,
} from '~/app';
import Turfmaster from '~/server/src/tmmodel';
import { sendSelectCardDice, sendSelectDice, sendSelectMove, setSelectedDicesLocal } from '../actions';
import { ReactComponent as ButtonSuccess } from '../button-icons/button-success.svg';
import * as overlayStyles from '../Overlay.module.css';
import AnimatedDice from './AnimatedDice';
import * as styles from './BarRight.module.css';

interface BarRightProps {
    onCardMoveSelected: (index: number) => void;
    isDiceShuffling: boolean;
}

const BarRight = ({ onCardMoveSelected, isDiceShuffling }: BarRightProps) => {
    const analytics = useFirebaseAnalytics();
    const dispatch = useDispatch();

    const selectedDicesLocal = useSelector(selectSelectedDicesLocal);
    const currentState = useSelector(selectCurrentState);
    const turnType = useSelector(selectTurnType);
    const turnDices = useSelector(selectTurnDices);
    const turnDicesPlayerId = useSelector(selectTurnDicesPlayerId);
    const turnOrder = useSelector(selectTurnOrder);
    const turnPos = useSelector(selectTurnPos);
    const moves = useSelector(selectMoves);
    const playerId = useSelector(selectPlayerId);
    const horses = useSelector(selectHorses);
    const turnDicesPlayerHorses = horses.filter((horse) => horse.playerId === turnDicesPlayerId);

    const currentHorseIndex = turnOrder[turnPos];
    const currentHorse = horses[currentHorseIndex];
    const currentHorsePlayerId = currentHorse ? currentHorse.playerId : -1;

    // we are the dice selecting player
    const isDiceSelectionPlayer =
        turnDices &&
        turnDices.length > 0 &&
        turnType === Turfmaster.turntypes.DICES &&
        playerId === turnDicesPlayerId &&
        currentState === Turfmaster.stateMachine.SELECTDICES;

    // we have to select a single dice because our handicap is too high
    const isDiceSelectionHandi =
        turnDices &&
        turnDices.length > 0 &&
        turnType === Turfmaster.turntypes.DICES &&
        playerId === currentHorsePlayerId &&
        currentState === Turfmaster.stateMachine.SELECTCARDDICE;

    // somebody is selecting a dice
    const isDiceSelectionVisible =
        turnDices &&
        turnDices.length > 0 &&
        turnType === Turfmaster.turntypes.DICES &&
        (currentState === Turfmaster.stateMachine.SELECTDICES || isDiceSelectionHandi);

    // dices pulsating animation
    const [isDicePulsating, setIsDicePulsating] = useState(false);
    useEffect(() => {
        if (isDiceSelectionPlayer || isDiceSelectionHandi) {
            if (!isDicePulsating && selectedDicesLocal < 0) setIsDicePulsating(true);
        } else if (isDicePulsating) {
            setIsDicePulsating(false);
        }
    }, [isDicePulsating, isDiceSelectionHandi, isDiceSelectionPlayer, selectedDicesLocal]);

    const diceColor =
        turnDicesPlayerHorses && turnDicesPlayerHorses.length > 0
            ? turnDicesPlayerHorses[0].color
            : horseColorIndex.BLACK;

    const isDiceSelected = (index: number): boolean => {
        // selecting dice, check against local selection
        if (isDiceSelectionPlayer) return selectedDicesLocal === 2 || selectedDicesLocal === index;
        // selecting dice because of too high handicap, check against local selection
        else if (isDiceSelectionHandi) return selectedDicesLocal === 2 || selectedDicesLocal === index;
        // don't show opponent selection
        else return false;
    };

    /**
     * User clicked on dice in the right bar, determine if dice should be unselected or selected
     * @param index the clicked dice index (0 or 1)
     */
    const onDiceSelected = (index: number) => {
        batch(() => {
            if (isDicePulsating) setIsDicePulsating(false);
            if (isDiceShuffling) return;
            if (isDiceSelectionPlayer) {
                // no selection, just set index
                if (selectedDicesLocal < 0) dispatch(setSelectedDicesLocal(index));
                // we are selected alone
                else if (selectedDicesLocal < 2 && selectedDicesLocal === index) dispatch(setSelectedDicesLocal(-1));
                // other is selected alone
                else if (selectedDicesLocal < 2 && selectedDicesLocal !== index) dispatch(setSelectedDicesLocal(2));
                // both are selected, we don't want to be anymore
                else dispatch(setSelectedDicesLocal(index === 0 ? 1 : 0));
            } else if (isDiceSelectionHandi) {
                if (selectedDicesLocal === index) dispatch(setSelectedDicesLocal(-1));
                else dispatch(setSelectedDicesLocal(index));
            } else dispatch(setSelectedDicesLocal(-1));
        });
    };

    /**
     * If we are the dice selecting player and a dice selection got accepted in the right bar tell the controller
     * @param index index of the selected dice
     */
    const onAccept = () => {
        if (selectedDicesLocal >= 0) {
            if (isDiceSelectionPlayer)
                batch(() => {
                    dispatch(sendSelectDice({ playerid: playerId, dicesid: selectedDicesLocal }));
                    dispatch(setSelectedDicesLocal(-1));
                });
            else if (isDiceSelectionHandi)
                dispatch(
                    sendSelectCardDice({
                        playerid: playerId,
                        horseid: currentHorseIndex,
                        carddiceid: selectedDicesLocal,
                    }),
                );
        }
    };

    // if we are not selecting a dice and we are the current player and there are moves available then show them
    const isMovesVisible = !isDiceSelectionVisible && moves && moves.length > 0 && currentHorsePlayerId === playerId;

    const onDiceMoveSelected = useCallback(
        (index: number) => {
            batch(() => {
                dispatch(
                    sendSelectMove({
                        playerid: playerId,
                        horseid: currentHorseIndex,
                        carddiceid: selectedDicesLocal,
                        moveid: index,
                    }),
                );
                dispatch(setSelectedDicesLocal(-1));
            });
        },
        [currentHorseIndex, dispatch, playerId, selectedDicesLocal],
    );

    const onMoveSelected = useCallback(
        (index: number) => {
            if (turnType === Turfmaster.turntypes.CARDS) {
                onCardMoveSelected(index);
            } else {
                onDiceMoveSelected(index);
            }
            analytics?.logEvent('select_move_from_right_bar', { turnType });
        },
        [analytics, onCardMoveSelected, onDiceMoveSelected, turnType],
    );

    // hide content if we are not the current horse in a card round
    if (turnType === Turfmaster.turntypes.CARDS && (!currentHorse || currentHorse.playerId !== playerId))
        return <div className={cn(overlayStyles.BarRight, styles.BarRightRoot)} />;

    return (
        <div className={cn(overlayStyles.BarRight, styles.BarRightRoot)}>
            {(isDiceSelectionVisible || isDiceShuffling) && [
                turnDices.map((dice, index) => (
                    <AnimatedDice
                        key={index}
                        className={cn(styles.Dice, {
                            [styles.DiceSelectable]:
                                !isDiceShuffling &&
                                (isDiceSelectionPlayer || isDiceSelectionHandi) &&
                                !isDiceSelected(index),
                            [styles.DiceSelected]:
                                !isDiceShuffling &&
                                (isDiceSelectionPlayer || isDiceSelectionHandi) &&
                                isDiceSelected(index),
                        })}
                        value={dice}
                        color={diceColor}
                        isShuffling={isDiceShuffling}
                        isPulsating={isDicePulsating}
                        onClick={() => onDiceSelected(index)}
                    />
                )),
                !isDiceShuffling && (isDiceSelectionPlayer || isDiceSelectionHandi) ? (
                    <ButtonSuccess
                        key="buttonSuccess"
                        className={cn({
                            [styles.AcceptButtonEnabled]: selectedDicesLocal >= 0,
                            [styles.AcceptButtonDisabled]: selectedDicesLocal < 0,
                        })}
                        onClick={onAccept}
                        onKeyPress={onAccept}
                        role="button"
                        tabIndex={0}
                    />
                ) : null,
            ]}
            {!isDiceShuffling &&
                isMovesVisible &&
                moves.map((_, index) => (
                    <div
                        key={uuid()}
                        className={styles.Move}
                        style={{ backgroundColor: PathColorValues[index] }}
                        onClick={() => onMoveSelected(index)}
                        onKeyPress={() => onMoveSelected(index)}
                        role="button"
                        tabIndex={0}
                    />
                ))}
        </div>
    );
};

export default memo(BarRight);
