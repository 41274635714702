import React from 'react';
import { CookieConsent } from 'react-cookie-consent';
import { useFirebaseAnalytics } from '~/apis/useFirebase';
import { useTranslation } from './i18n';

export const cookieConsentName = 'cookieConsentGranted';

const CookieConsentBanner = (): JSX.Element => {
    const { t } = useTranslation();
    const firebaseAnalytics = useFirebaseAnalytics();

    return (
        <CookieConsent
            buttonText={t('start_cookieConsentGrantedAccept')}
            cookieName={cookieConsentName}
            buttonClasses="bg-secondary mx-2 px-3 py-2 rounded-md shadow-sm"
            contentClasses="flex-1 text-gray-50 text-sm"
            containerClasses="fixed px-8 items-center flex flex-row py-4 inset-x-0 bottom-0 z-100 bg-primary-dark backdrop-blur-md"
            disableStyles={true}
            expires={150}
            onAccept={() => {
                if (firebaseAnalytics?.analytics) {
                    firebaseAnalytics.setAnalyticsCollectionEnabled(true);
                }
            }}
            onDecline={() => {
                if (firebaseAnalytics?.analytics) {
                    firebaseAnalytics.setAnalyticsCollectionEnabled(false);
                }
            }}>
            {t('start_cookieConsentGranted_1')}{' '}
            <a href="/" className="text-secondary font-bold underline">
                {t('start_cookieConsentGranted_2')}
            </a>{' '}
            {t('start_cookieConsentGranted_3')}
        </CookieConsent>
    );
};

export default CookieConsentBanner;
