import { LeagueType } from '.';
import { Horse } from './horse';

export interface User {
    userId: string;
    username: string; // = name of stud = "Gestütname"
    country: string; // Alpha-2 country code according to https://en.wikipedia.org/wiki/ISO_3166-1 (e.g. "DE", "AT", "NL", "US")
    description: string;
    pointsBronze?: number; // only for stats, sum of all horses, not persisted
    pointsSilver?: number; // only for stats, sum of all horses, not persisted
    pointsGold?: number; // only for stats, sum of all horses, not persisted
    toplistLeague: LeagueType; // type of toplist the user is located in
    toplistPoints: number; // current sum of all horse points for this toplist season (will be resetted periodically)
    toplistRank: number; // current rank within the toplist league
    toplistPromotion: number; // change between toplists on next season (-1 = move down, 0 = stay, 1 = move up)
    globalToplistPoints: number; // current sum of poiints for the global toplist
    horses?: Horse[];
}

export interface UserInternal extends User {
    role: UserRole;
    firebaseUid: string;
    email: string;
    subscription: UserSubscription;
    subscriptionStatus: UserSubscriptionStatus;
    subscriptionValidUntil: string;
    registerDate: string;
}

export const isUserInternal = (user: User | UserInternal): user is UserInternal => {
    const maybeUserInternal = user as UserInternal;
    return typeof maybeUserInternal.email === 'string';
};

export enum UserRole {
    User = 'user',
    Admin = 'admin',
    Bot = 'bot',
}

export enum UserSubscription {
    Basic = 'basic',
    Premium = 'premium',
}

export enum UserSubscriptionStatus {
    Active = 'active',
    Canceled = 'canceled',
    Unknown = 'unknown',
}

export function filterUserInternalToUser(userInternal: UserInternal): User {
    return {
        userId: userInternal.userId,
        username: userInternal.username,
        country: userInternal.country,
        description: userInternal.description,
        pointsBronze: userInternal.pointsBronze,
        pointsSilver: userInternal.pointsSilver,
        pointsGold: userInternal.pointsGold,
        toplistLeague: userInternal.toplistLeague,
        toplistPoints: userInternal.toplistPoints,
        toplistRank: userInternal.toplistRank,
        toplistPromotion: userInternal.toplistPromotion,
        globalToplistPoints: userInternal.globalToplistPoints,
        horses: userInternal.horses,
    };
}
