/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import React, { memo, useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';
import { useFirebaseAnalytics } from '~/apis/useFirebase';
import {
    Coordinates,
    PathColorValues,
    selectHorses,
    selectMoves,
    selectPlayerId,
    selectSelectedCardIndexLocal,
    selectSelectedDicesLocal,
    selectTurnOrder,
    selectTurnPos,
    selectTurnType,
} from '~/app';
import { sendSelectMove, setSelectedCardIndexLocal, setSelectedDicesLocal } from '~/overlay';
import Turfmaster from '~/server/src/tmmodel';
import { transformAbsX, transformAbsY, vh, vw } from '~/utils';
import * as styles from './HorsePathsView.module.css';

const buildPath = (fields: Coordinates[] /*TODO: , moveIndex*/) => {
    if (!fields || fields.length <= 0) return '';
    let d = '';
    fields.forEach((field, index) => {
        d += `${index === 0 ? 'M' : 'L'}${transformAbsX(field.x)} ${transformAbsY(field.y)}`;
    });
    return d;
};

const pathWidth = vw * 0.004; // 0.04% of view width
const horseSize = vw * 0.03; // 0.3% of view width

const HorsePathsView = () => {
    const analytics = useFirebaseAnalytics();

    const dispatch = useDispatch();

    // invisible if we are not the current horse
    const turnType = useSelector(selectTurnType);
    const horses = useSelector(selectHorses);
    const turnOrder = useSelector(selectTurnOrder);
    const currentTurnPos = useSelector(selectTurnPos);
    const playerId = useSelector(selectPlayerId);
    const moves = useSelector(selectMoves);
    const selectedDicesLocal = useSelector(selectSelectedDicesLocal);
    const selectedCardIndexLocal = useSelector(selectSelectedCardIndexLocal);

    const currentHorseId = turnOrder[currentTurnPos];
    const currentHorse = horses[currentHorseId];

    // horse path interaction
    const onDiceMoveSelected = useCallback(
        (index: number) => {
            batch(() => {
                dispatch(
                    sendSelectMove({
                        playerid: playerId,
                        horseid: currentHorseId,
                        carddiceid: selectedDicesLocal,
                        moveid: index,
                    }),
                );
                dispatch(setSelectedDicesLocal(-1));
            });
        },
        [currentHorseId, dispatch, playerId, selectedDicesLocal],
    );

    const onCardMoveSelected = useCallback(
        (index: number) => {
            batch(() => {
                dispatch(
                    sendSelectMove({
                        playerid: playerId,
                        horseid: currentHorseId,
                        carddiceid: selectedCardIndexLocal,
                        moveid: index,
                    }),
                );
                dispatch(setSelectedCardIndexLocal(-1));
            });
        },
        [currentHorseId, dispatch, playerId, selectedCardIndexLocal],
    );

    const onMoveSelected = useCallback(
        (index: number) => {
            if (turnType === Turfmaster.turntypes.CARDS) onCardMoveSelected(index);
            else onDiceMoveSelected(index);
            analytics?.logEvent('select_move_from_right_bar', { turnType });
        },
        [analytics, onCardMoveSelected, onDiceMoveSelected, turnType],
    );

    if (!currentHorse || currentHorse.playerId !== playerId) return null;
    return (
        <svg className={styles.HorsePathsViewRoot} viewBox={`0 0 ${vw} ${vh}`}>
            {moves.map((move, moveIndex) => {
                const cx = transformAbsX(move[move.length - 1].x);
                const cy = transformAbsY(move[move.length - 1].y);
                return [
                    <path
                        key={uuid()}
                        d={buildPath(move /*, moveIndex*/)}
                        fill="transparent"
                        stroke={PathColorValues[moveIndex]}
                        strokeWidth={pathWidth}
                        strokeLinejoin="round"
                        strokeLinecap="round"
                    />,
                    <circle
                        key={uuid()}
                        cx={cx}
                        cy={cy}
                        r={horseSize / 3}
                        style={{ transformOrigin: `${cx}px ${cy}px` }}
                        fill={PathColorValues[moveIndex]}
                        onClick={() => onMoveSelected(moveIndex)}
                        className={styles.HorsePathsCircle}
                    />,
                ];
            })}
        </svg>
    );
};

export default memo(HorsePathsView);
