import { sortBy } from 'lodash';
import { useMemo } from 'react';
import { LeagueType, User } from '~/server/src/dtos';
import { getFn, tableCacheKey, toplistsCacheKey, useQuery, UseQueryOptions } from './api';

export const getTable = (tableLeague: LeagueType) => {
    switch (tableLeague) {
        case LeagueType.Bronze:
            return 'toplistBronze';
        case LeagueType.Silver:
            return 'toplistSilver';
        case LeagueType.Gold:
            return 'toplistGold';
    }
};

export type TypeFilter = 'horses' | 'users';

export const useUserTable = (
    tableLeague: LeagueType,
    month: number,
    year: number,
    options?: UseQueryOptions<User[]>,
) => {
    const { data = [] } = useQuery<User[]>(
        [tableCacheKey, 'users', tableLeague, month, year],
        () => getFn(`/${getTable(tableLeague)}?m=${month}&y=${year}`),
        options,
    );
    return useMemo<User[]>(
        () =>
            sortBy(
                data.map(({ username, ...userProps }) => ({
                    ...userProps,
                    username: username.length ? username : '---',
                })),
                [
                    (user) => (user.toplistRank >= 0 ? 0 : Number.MAX_SAFE_INTEGER),
                    (user) => user.toplistRank,
                    (user) => user.userId,
                ],
            ),
        [data],
    );
};

export type GlobalToplistRankedUser = User & { globalToplistRank: number };

export const useUserGlobalToplist = (options?: UseQueryOptions<GlobalToplistRankedUser[]>) => {
    const { data = [] } = useQuery<GlobalToplistRankedUser[]>(
        [toplistsCacheKey, 'users'],
        () => getFn<GlobalToplistRankedUser[]>(`/toplistGlobal`),
        {
            select: (users) => {
                return users.map((user, index) => ({
                    ...user,
                    username: user.username.length ? user.username : '---',
                    globalToplistRank: index + 1,
                }));
            },
            ...options,
        },
    );
    return data;
};
