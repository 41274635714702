import seedrandom from 'seedrandom';

// custom entropy pool filled by true random values from https://www.random.org/strings/?num=50&len=20&digits=on&upperalpha=on&loweralpha=on&unique=on&format=html&rnd=new
const entropyPool = [
    'btJAfMTjhKueYB6jfYIU',
    'emXn6VUs4Sx9dFDjP3KM',
    'N4S7oMx53PRRnqXmqlPb',
    'bv5GbiF8S2E4AwumCm1G',
    'zew90lbFNYTPTmXhRlSd',
    'own7BlT1oiNnSnf6oJsc',
    'tcRKTy0pf0Mcar24ZtM9',
    'fWU0d6bcBXASZXnidT8Y',
    '75CdJ5KyPVgNFKMFGFB1',
    'yqUj6HzRXAFx8ZDHcEij',
    'vO8PPVQS7p2PWq8UfNBD',
    'bEXdgHGoZ2iyZyfWgYf5',
    'sEPlwkIZ7soc4FDcHAFK',
    'RYqgbzSXl6DbPObADUia',
    'Tf8voLcKYBn9DqKhIFdY',
    'Ks2qh9ApunKBemGHsF2D',
    'le96f4xxvJmtXhqpuavt',
    'RbpdGf9A86XU5ojEPgZ9',
    'YRhiVeIH4P6HE75Pc6NN',
    'b78VU8YYun3EqoyR42xs',
    'WX33rzQRlzhVo1EEO5yH',
    'hJyok4gvsNWhbIl8ENz6',
    'DELhOvK4lPc4tR3EmsqV',
    'nJMZJZv014TczA5QlB7v',
    'bqMP8l3c44MS4FK4ZzRy',
    'pzGCshDc9XZQBduYS1sY',
    'qKztUoi4hNOeRNqZizST',
    'g0q6b2ME2Arkm2a3SmFk',
    'OIZl7le009Twxoe8rYuh',
];
// select one of the entropy strings on startup
const customEntropyString = entropyPool[Math.floor(Math.random() * entropyPool.length)];
// initialize random number generator library with custom entropy string as seed and add local accumulated entropy
const getRandomNumber = seedrandom('customTurfMasterEntropy' + customEntropyString, {
    entropy: true,
});

// random integer including bounds
export const randomInt = (min: number, max: number) => {
    return Math.floor(getRandomNumber() * (max - min + 1)) + min;
};

export const randomBool = () => {
    // https://stackoverflow.com/a/28763727/2254480
    return getRandomNumber() >= 0.5;
};

export const getRandomUniqueNumbers = (min: number, max: number, count: number): number[] => {
    const numbers: number[] = [];

    if (min < 0 || max <= min || max - min < count) {
        return numbers;
    }

    while (numbers.length < count) {
        const r = randomInt(min, max);
        if (numbers.indexOf(r) === -1) numbers.push(r);
    }

    return numbers;
};
