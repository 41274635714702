import MockAdapter from 'axios-mock-adapter';
import {
    AccountChangeDescriptionRequest,
    AccountChangeDescriptionResponse,
    AccountChangeUserNameRequest,
    AccountChangeUserNameResponse,
} from '~/server/src/dtos';
import { getAccount, putAccountDescription, putAccountUsername } from './apiAccount';
import { users } from './apiMockData';

const account = users[users.length / 2];

const accountMock = (mock: MockAdapter): void => {
    mock.onGet(getAccount).reply(() => {
        return [200, account];
    });

    mock.onPost(putAccountUsername).reply((config) => {
        const { username } = JSON.parse(config.data) as AccountChangeUserNameRequest;
        account.username = username;

        const response: AccountChangeUserNameResponse = { username };
        return [200, response];
    });

    mock.onPost(putAccountDescription).reply((config) => {
        const { description } = JSON.parse(config.data) as AccountChangeDescriptionRequest;
        account.description = description;

        const response: AccountChangeDescriptionResponse = { description };
        return [200, response];
    });
};

export default accountMock;
