/**
 * @author       Andreas Bayer <andreas@plusbyte.de>
 * @copyright    2015 Plusbyte UG (haftungsbeschränkt)
 * @license      {@link https://plusbyte.de Plusbyte License}
 */

import Turfmaster, { Horse, StateMachineKey } from './tmmodel';
import { View } from './interfaces';
import { randomBool, randomInt } from './utilRandom';

function stateToString(state: number) {
    let stateString = 'UNKNOWN';
    Object.keys(Turfmaster.stateMachine).forEach((key) => {
        if (Turfmaster.stateMachine[key as StateMachineKey] === state) {
            stateString = key;
        }
    });
    return stateString;
}

/**
 * Computer
 * @class Turfmaster.Computer
 * @constructor
 * @param {Number} playerid - ID of the player
 * @param {Turfmaster.compStrengths} strength - Strength of the Computer
 * @param {Turfmaster.Controller} controller - Controller object
 */
class Computer implements View {
    /**
     * @property {Turfmaster.Controller} controller - Controller object
     */
    public controller: any;

    /**
     * @property {Number} playerid - ID of the player
     */
    public playerid: number;

    /**
     * @property {Turfmaster.compStrengths} strength - Strength of the Computer
     */
    public strength: number;

    /**
     * @property {Number} delay - Delay for each event of the Computer
     */
    public delay = 1000;

    public delayRandomShort() {
        return randomInt(1, 2) * this.delay;
    }

    public delayRandomMedium() {
        return randomInt(2, 4) * this.delay;
    }

    public constructor(playerid: number, strength: number, controller: any) {
        this.controller = controller;
        this.playerid = playerid;
        this.strength = strength;

        // TODO: create timeout that repeatedly checks if something can be done, i.e. reacted to a message
        // TODO: add logic for starting a game within receiveMsg
    }

    /**
     * ReceiveMsg
     * @method Turfmaster.Controller#receiveMsg
     * @param {Turfmaster.stateMachine} state - State
     * @param {Object} params - Params
     */
    public receiveMsg = (state: any, params: any) => {
        console.log('<' + this.playerid + ':receive> ' + stateToString(state) + ' ' + JSON.stringify(params));

        switch (state) {
            case Turfmaster.stateMachine.MERGECARDS:
                setTimeout(() => {
                    this.mergeCardsEvent();
                }, this.delayRandomShort());
                break;
            case Turfmaster.stateMachine.SELECTCARDDICE:
                setTimeout(() => {
                    this.selectCardDiceEvent();
                }, this.delayRandomMedium());
                break;
            case Turfmaster.stateMachine.SELECTMOVE:
                setTimeout(() => {
                    this.selectMoveEvent(params.moves);
                }, this.delayRandomMedium());
                break;
            case Turfmaster.stateMachine.SELECTDICES:
                setTimeout(() => {
                    this.selectDicesEvent();
                }, this.delayRandomMedium());
                break;
        }
    };

    /**
     * SendMsg
     * @method Turfmaster.Controller#sendMsg
     * @param {Turfmaster.stateMachine} state - State
     * @param {Object} params - Params
     */
    public sendMsg = (state: any, params: any) => {
        console.log('<' + this.playerid + ':send> ' + stateToString(state) + ' ' + JSON.stringify(params));

        switch (state) {
            case Turfmaster.stateMachine.MERGECARDS:
            case Turfmaster.stateMachine.SELECTCARDDICE:
            case Turfmaster.stateMachine.SELECTMOVE:
            case Turfmaster.stateMachine.SELECTDICES:
                params.playerid = this.playerid;

                this.controller.receiveMsg(state, params);
                break;
        }
    };

    /**
     * MergeCardsEvent
     * @method Turfmaster.Controller#mergeCardsEvent
     */
    public mergeCardsEvent = () => {
        const _this = this;
        const horseid = this.controller.getHorseId();

        if (this.controller.getPlayerId() !== this.playerid) {
            return false;
        }

        setTimeout(() => {
            _this.sendMsg(Turfmaster.stateMachine.MERGECARDS, { horseid: horseid, added: [], removed: [] });
        }, this.delayRandomShort());
    };

    /**
     * SelectCardDiceEvent
     * @method Turfmaster.Controller#selectCardDiceEvent
     */
    public selectCardDiceEvent = () => {
        const _this = this;
        const horseid = this.controller.getHorseId();

        if (this.controller.getPlayerId() !== this.playerid) {
            return false;
        }

        let carddiceid = 0;

        if (this.controller.game.turntype === Turfmaster.turntypes.CARDS) {
            const cards = this.controller.getCurrentCards(horseid);
            switch (this.strength) {
                case Turfmaster.compStrengths.EASY:
                    carddiceid = randomInt(0, cards.length - 1);
                    break;
                case Turfmaster.compStrengths.MEDIUM:
                    carddiceid = 0;
                    let found = false;
                    // On first line horse must use cards smaller or equal than 6 or joker on line 7 or 8
                    if (
                        this.controller.game.horses[horseid].field.prog < 15 &&
                        this.controller.game.horses[horseid].field.lane > 5
                    ) {
                        out: for (let i = 0; i < cards.length; i++) {
                            if (cards[i] <= 6) {
                                const moves = this.controller.getMovesLogic(horseid, i, false);
                                for (let j = 0; j < moves.length; j++) {
                                    if (!moves[j].drop) {
                                        carddiceid = i;
                                        found = true;
                                        break out;
                                    }
                                }
                            }
                        }
                    }
                    // On first line horse must use cards 6, 7 or 8 on line 1 to 6
                    if (
                        this.controller.game.horses[horseid].field.prog < 15 &&
                        this.controller.game.horses[horseid].field.lane <= 5
                    ) {
                        const cardSelection: number[] = [];

                        for (let i = 0; i < cards.length; i++) {
                            if (cards[i] >= 6 && cards[i] <= 8) {
                                const moves = this.controller.getMovesLogic(horseid, i, false);
                                for (let j = 0; j < moves.length; j++) {
                                    if (!moves[j].drop) {
                                        cardSelection.push(i);
                                        break;
                                    }
                                }
                            }
                        }

                        if (cardSelection.length > 0) {
                            carddiceid = cardSelection[randomInt(0, cardSelection.length - 1)];

                            found = true;
                        }
                    }
                    // beyond field 60, horse will randomly prefer joker
                    if (this.controller.game.horses[horseid].field.prog > 60 && randomBool()) {
                        out: for (let i = 0; i < cards.length; i++) {
                            if (cards[i] < 0) {
                                const moves = this.controller.getMovesLogic(horseid, i, false);
                                for (let j = 0; j < moves.length; j++) {
                                    if (!moves[j].drop) {
                                        carddiceid = i;
                                        found = true;
                                        break out;
                                    }
                                }
                            }
                        }
                    }
                    // Normally horse will use highest card with has no handicap and will not drop the horse
                    if (!found) {
                        out: for (let i = cards.length - 1; i >= 0; i--) {
                            if (!this.controller.isHandi(horseid, cards[i])) {
                                const moves = this.controller.getMovesLogic(horseid, i, false);
                                for (let j = 0; j < moves.length; j++) {
                                    if (!moves[j].drop) {
                                        carddiceid = i;
                                        found = true;
                                        break out;
                                    }
                                }
                            }
                        }
                    }
                    // May be not used
                    if (!found) {
                        out: for (let i = cards.length - 1; i >= 0; i--) {
                            const moves = this.controller.getMovesLogic(horseid, i, false);
                            for (let j = 0; j < moves.length; j++) {
                                if (!moves[j].drop) {
                                    carddiceid = i;
                                    break out;
                                }
                            }
                        }
                    }
                    break;
            }
        } else {
            carddiceid = randomInt(0, this.controller.getDices().length - 1);
        }

        const moves = this.controller.getMovesLogic(horseid, carddiceid, false);
        let moveid: number;

        switch (this.strength) {
            case Turfmaster.compStrengths.EASY:
                moveid = randomInt(0, moves.length - 1);
                break;
            case Turfmaster.compStrengths.MEDIUM:
                moveid = 0;

                const movesSelected: { [key: number]: any } = {};

                for (let i = 0; i < moves.length; i++) {
                    if (!moves[i].drop) {
                        if (Object.keys(movesSelected).length === 0) {
                            movesSelected[i] = moves[i];
                        } else {
                            const moveField = moves[i].fields[moves[i].fields.length - 1];

                            for (const key in movesSelected) {
                                const moveSelectedField =
                                    movesSelected[key].fields[movesSelected[key].fields.length - 1];

                                if (
                                    moveField.lane < moveSelectedField.lane ||
                                    moveField.prog > moveSelectedField.prog
                                ) {
                                    movesSelected[i] = moves[i];
                                }
                            }
                        }
                    }
                }

                moveid = Number(Object.keys(movesSelected)[randomInt(0, Object.keys(movesSelected).length - 1)]);
                break;
        }

        setTimeout(() => {
            _this.sendMsg(Turfmaster.stateMachine.SELECTMOVE, {
                horseid: horseid,
                carddiceid: carddiceid,
                moveid,
            });
        }, this.delayRandomMedium());
    };

    /**
     * SelectMoveEvent
     * @method Turfmaster.Controller#selectMoveEvent
     * @param {Array} moves - Moves
     */
    public selectMoveEvent = (moves: any) => {
        const _this = this;
        const horseid = this.controller.getHorseId();

        if (this.controller.getPlayerId() !== this.playerid) {
            return false;
        }

        let moveid: number;

        switch (this.strength) {
            case Turfmaster.compStrengths.EASY:
                moveid = randomInt(0, moves.length - 1);
                break;
            case Turfmaster.compStrengths.MEDIUM:
                moveid = 0;

                const movesSelected: { [key: number]: any } = {};

                for (let i = 0; i < moves.length; i++) {
                    if (!moves[i].drop) {
                        if (Object.keys(movesSelected).length === 0) {
                            movesSelected[i] = moves[i];
                        } else {
                            const moveField = moves[i].fields[moves[i].fields.length - 1];

                            for (const key in movesSelected) {
                                const moveSelectedField =
                                    movesSelected[key].fields[movesSelected[key].fields.length - 1];

                                if (
                                    moveField.lane < moveSelectedField.lane ||
                                    moveField.prog > moveSelectedField.prog
                                ) {
                                    movesSelected[i] = moves[i];
                                }
                            }
                        }
                    }
                }

                moveid = Number(Object.keys(movesSelected)[randomInt(0, Object.keys(movesSelected).length - 1)]);

                break;
        }

        setTimeout(() => {
            _this.sendMsg(Turfmaster.stateMachine.SELECTMOVE, { horseid: horseid, carddiceid: -1, moveid: moveid });
        }, this.delayRandomMedium());
    };

    /**
     * SelectDicesEvent
     * @method Turfmaster.Controller#selectDicesEvent
     */
    public selectDicesEvent = () => {
        const _this = this;

        if (this.controller.game.turndiceplayerid !== this.playerid) {
            return false;
        }

        let dicesid: number;

        switch (this.strength) {
            case Turfmaster.compStrengths.EASY:
                dicesid = randomInt(0, this.controller.game.turndices.length); // will be 0, 1 or 2 (as turndices.length is usually 2)
                break;
            case Turfmaster.compStrengths.MEDIUM:
                const horseWithHandi = this.controller.game.horses.reduce(
                    (prev: boolean, horse: Horse, horseIndex: number) => {
                        return prev || this.controller.isHandi(horseIndex, this.controller.calcDicessum());
                    },
                    false,
                );

                // if spieler hat mindestens 1 pferd mit handicap > würfelsumme )
                if (horseWithHandi) {
                    // falls ja: nimm nur 1 würfel
                    dicesid = this.getOneDice();
                } else {
                    // falls nein: nimm 50% beide würfel und 50% einen
                    if (randomBool()) {
                        dicesid = this.controller.game.turndices.length;
                    } else {
                        dicesid = this.getOneDice();
                    }
                }
                break;
        }

        setTimeout(() => {
            _this.sendMsg(Turfmaster.stateMachine.SELECTDICES, { dicesid: dicesid });
        }, this.delayRandomShort());
    };

    private getOneDice = () => {
        // "einen würfel nehmen" = 75% größeren würfel nehmen, 25% kleineren
        const smalerDice = randomInt(0, 3) === 0;
        if (smalerDice) {
            return this.controller.game.turndices[0] < this.controller.game.turndices[1] ? 0 : 1;
        } else {
            return this.controller.game.turndices[0] > this.controller.game.turndices[1] ? 0 : 1;
        }
    };
}

export default Computer;
