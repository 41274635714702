/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import React, { memo } from 'react';
import { v4 as uuid } from 'uuid';
import { Board, Field } from '~/server/src/tmmodel';
import { transformAbsX, transformAbsY, vh, vw } from '~/utils';
import * as styles from './FieldMarkerView.module.css';

const horseSize = vw * 0.03; // 0.3% of view width

const FieldMarkerView = () => {
    const fields: Field[] = new Board(0).fields.flat();

    return (
        <svg className={styles.FieldMarkerViewRoot} viewBox={`0 0 ${vw} ${vh}`}>
            {fields.map((field) => {
                const cx = transformAbsX(field.x);
                const cy = transformAbsY(field.y);
                return (
                    <circle
                        key={uuid()}
                        cx={cx}
                        cy={cy}
                        r={horseSize / 10}
                        fill={'red'}
                        opacity={0.3}
                        stroke="black"
                        strokeWidth={5}
                        color="#000000"
                    />
                );
            })}
        </svg>
    );
};

export default memo(FieldMarkerView);
