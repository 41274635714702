import { horseColorIndex } from '~/app';
import Turfmaster, { Horse, Player } from '~/server/src/tmmodel';

const calculateClientHorseColors = (horses: Horse[], players: Player[], ownPlayerId: number): number[] => {
    const ownHorses = horses.filter((horse) => horse.playerid === ownPlayerId);
    const humanOppHorses = horses.filter(
        (horse) => horse.playerid !== ownPlayerId && players[horse.playerid].type === Turfmaster.playerTypes.PLAYER,
    );

    let freeColors = Object.values(horseColorIndex);

    // initialize color results array with -1 to determine which color is already set
    const horseColors: number[] = horses.map(() => -1);

    // 1. reserve colors of own horses so that they are always shown correctly
    for (let i = 0; i < ownHorses.length; i++) {
        const curHorse = ownHorses[i];
        const curHorseIndex = horses.indexOf(curHorse);

        // use color set by server if this is a remote / real horse
        horseColors[curHorseIndex] = curHorse.color;
        freeColors = freeColors.filter((freeColor) => freeColor !== curHorse.color);

        // if horse color was not set we might be in anonymous training mode, so try to parse horseId as color index
        if (horseColors[curHorseIndex] === -1) {
            try {
                const potentialHorseColor = Number.parseInt(curHorse.horseid);
                if (potentialHorseColor >= 0 && potentialHorseColor < Object.keys(horseColorIndex).length) {
                    horseColors[curHorseIndex] = potentialHorseColor;
                    freeColors = freeColors.filter((freeColor) => freeColor !== potentialHorseColor);
                }
            } catch (e) {
                console.log('own horse color was -1 but horseId is not a valid color index', e);
            }
        }
    }

    // 2. try to use real human opponent horse colors as well, as long as they are different from ours
    for (let i = 0; i < humanOppHorses.length; i++) {
        const curHorse = humanOppHorses[i];
        const curHorseIndex = horses.indexOf(curHorse);

        if (freeColors.some((freeColor) => freeColor === curHorse.color)) {
            horseColors[curHorseIndex] = curHorse.color;
            freeColors = freeColors.filter((freeColor) => freeColor !== curHorse.color);
        } else {
            console.log(`real horse color ${curHorse.color} of oppHumanHorse is already used`);
        }
    }

    // 3. fallback to remaining free colors and therefore guarantee unique looking horses
    // this includes bots because step 1 & 2 is only assigning human horse colors
    for (let i = 0; i < horses.length; i++) {
        const curHorse = horses[i];
        const curHorseIndex = horses.indexOf(curHorse);

        if (horseColors[curHorseIndex] === -1) {
            horseColors[curHorseIndex] = freeColors.pop() ?? -1;
        }
    }

    return horseColors;
};

export default calculateClientHorseColors;
