import { delay, put, select } from 'redux-saga/effects';
import { gameActionWatchdogTimeout, selectGameType } from '~/app';
import { GameType } from '~/server/src/dtos';
import { notifyConnectionTimeout, resetConnectionTimeout } from './actions';

function* sagaWatchdog() {
    const gameType: GameType = yield select(selectGameType);

    // skip connection timeout watchdog in training / offline mode
    if (gameType === GameType.Training) {
        return;
    }

    yield put(resetConnectionTimeout());
    yield delay(gameActionWatchdogTimeout);
    yield put(notifyConnectionTimeout());
}

export default sagaWatchdog;
