/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import { graphql, useStaticQuery } from 'gatsby';
import React, { memo } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from '~/app';

interface Props {
    description?: string;
    lang?: string;
    title?: string;
}

const SEO = ({ description = ``, title }: Props) => {
    const { locale, t } = useTranslation();
    const { site } = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        author
                    }
                }
            }
        `,
    );

    const metaDescription = description || t('website_defaultDescription');

    return (
        <Helmet
            htmlAttributes={{
                lang: locale,
            }}
            defaultTitle={t('website_defaultTitle')}
            title={title}
            titleTemplate={`%s | ${t('website_defaultTitle')}`}
            meta={[
                {
                    name: 'robots',
                    content: 'noindex, nofollow',
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:card`,
                    content: `summary`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
            ]}
        />
    );
};

export default memo(SEO);
