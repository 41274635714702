import React from 'react';
import './node_modules/react-grid-layout/css/styles.css';
import './node_modules/react-resizable/css/styles.css';
import { getFirebaseAnalytics } from './src/apis/firebase';
import { DEBUG_logWhyDidYouRender, isAnalyticsEnabled } from './src/app/config';
import wrapWithI18n from './src/app/i18n';
import wrapWithProvider from './src/app/store';
import './src/global.css';

export const wrapRootElement = wrapWithProvider;
export const wrapPageElement = wrapWithI18n;

export const onClientEntry = () => {
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const whyDidYouRender = require('@welldone-software/why-did-you-render');
        whyDidYouRender(React, {
            trackAllPureComponents: DEBUG_logWhyDidYouRender,
        });
    }
};

// https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-gtag/src/gatsby-browser.js
export const onRouteUpdate = ({ location }) => {
    // save history in window.previousPath
    if (typeof window !== 'undefined') {
        window.locations = window.locations || [document.referrer];
        if (window.locations[window.locations.length - 1] !== window.location.href) {
            window.locations.push(window.location.href);
        }
        window.previousPath = window.locations[window.locations.length - 2];
    }

    if (!isAnalyticsEnabled) return;

    const analytics = getFirebaseAnalytics();

    const pathIsExcluded =
        location &&
        typeof window.excludeGtagPaths !== `undefined` &&
        window.excludeGtagPaths.some((rx) => rx.test(location.pathname));

    if (pathIsExcluded) return;

    // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
    const sendPageView = () => {
        const pagePath = location ? location.pathname + location.search + location.hash : undefined;
        analytics.logEvent('page_view', { page_path: pagePath });
    };

    if (`requestAnimationFrame` in window) {
        requestAnimationFrame(() => {
            requestAnimationFrame(sendPageView);
        });
    } else {
        // simulate 2 rAF calls
        setTimeout(sendPageView, 32);
    }
};

// setup api mocks for all web requests if not in production mode
// has to be controlled via environment variables when real api is available
// to enable a switch between local and remote api
if (process.env.NODE_ENV !== 'production') {
    (async () => {
        // eslint-disable-next-line import/no-unresolved
        const setupRuntimeApiMock = (await import('~/apis/apiMock')).default;
        setupRuntimeApiMock();
        console.log('!!! mock api enabled !!!');
    })();
}
