/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { createAction } from '@reduxjs/toolkit';
import { SetEntityPropertyParams } from 'app';
import { HorseSelectionData } from '~/components/HorseSelection';
import { Player } from './reducer';

export interface StartTrainingParameters {
    gameName?: string;
    playerCount: number;
    horseCount: number;
    horseSelection: HorseSelectionData;
    oppHorseSelection: HorseSelectionData;
    timeout: number;
}

export interface StartDebugParameters {
    playerCount?: number;
    horseCount?: number;
    gameName?: string;
}

export const setConnected = createAction<boolean>('setConnected');
export const resetConnectionTimeout = createAction('resetConnectionTimeout');
export const notifyConnectionTimeout = createAction('notifyConnectionTimeout');

export const startTraining = createAction<StartTrainingParameters>('startTraining');
export const startFree = createAction('startFree');
export const startTournament = createAction('startTournament');
export const startDebug = createAction<StartDebugParameters | undefined>('startDebug');

export const setCurrentState = createAction<number>('setCurrentState');
export const setRound = createAction<number>('setRound');

export const setTurnPos = createAction<number>('setTurnPos');
export const setTurnOrder = createAction<number[]>('setTurnOrder');
export const setTurnDices = createAction<number[]>('setTurnDices');
export const setTurnDicesCur = createAction<number>('setTurnDicesCur');
export const setTurnType = createAction<number>('setTurnType');
export const setTurnDicePlayerId = createAction<number>('setTurnDicePlayerId');

export const setPlayerId = createAction<number>('setPlayerId');
export const setPlayers = createAction<Player[]>('setPlayers');

export type HorseRanks = { rank: number; totalRank: number };
export const setHorsesRanks = createAction<HorseRanks[]>('setHorsesRanks');
export const setHorseRanks = createAction<SetEntityPropertyParams<HorseRanks>>('setHorseRanks');
export const setHorsesHandi = createAction<number[]>('setHorsesHandi');
export const setHorseHandi = createAction<SetEntityPropertyParams<number>>('setHorseHandi');
export const setHorsesPoints = createAction<number[][]>('setHorsesPoints');
export const setHorsePoints = createAction<SetEntityPropertyParams<number[]>>('setHorsePoints');
export const setHorsesFinished = createAction<boolean[]>('setHorsesFinished');
export const setHorseFinished = createAction<SetEntityPropertyParams<boolean>>('setHorseFinished');
export const setHorsesDropped = createAction<boolean[]>('setHorsesDropped');
export const setHorseDropped = createAction<SetEntityPropertyParams<boolean>>('setHorseDropped');
export const setHorsesStarted = createAction<boolean[]>('setHorsesStarted');
export const setHorsesBehindGoal = createAction<boolean[]>('setHorsesBehindGoal');
