/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { createAction } from '@reduxjs/toolkit';
import { SetEntityPropertyParams } from 'app';

export const setHandiFailureHorseIndex = createAction<number>('setHandiFailureHorseIndex');
export const setCardDice = createAction<number>('setCardDice');
export const setLastCardHorseIndex = createAction<number>('setLastCardHorseIndex');
export const setLastCard = createAction<number>('setLastCard');
export const setLastTurnDicePlayerId = createAction<number>('setLastTurnDicePlayerId');
export const setLastTurnDices = createAction<number[]>('setLastTurnDices');
export const setLastTurnDicesCur = createAction<number>('setLastTurnDicesCur');

export const setHorsesCards = createAction<number[][][]>('setHorsesCards');
export const setHorseCards = createAction<SetEntityPropertyParams<number[][]>>('setHorseCards');
export const setHorsesBonusCards = createAction<number[]>('setHorsesBonusCards');
export const setHorseBonusCards = createAction<SetEntityPropertyParams<number>>('setHorseBonusCards');
export const setOwnHorseRemovedCards = createAction<number[]>('setOwnHorseRemovedCards');

export const setSelectedDicesLocal = createAction<number>('setSelectedDicesLocal');
export const setSelectedCardIndexLocal = createAction<number>('setSelectedCardIndexLocal');

/**
 * client messages to controller
 */
export interface SendSelectCardDice {
    playerid: number;
    horseid: number;
    carddiceid: number;
}

export const sendSelectCardDice = createAction<SendSelectCardDice>('sendSelectCardDice');

export interface SendSelectDice {
    playerid: number;
    dicesid: number;
}

export const sendSelectDice = createAction<SendSelectDice>('sendSelectDice');

export interface SendSelectMove {
    playerid: number;
    horseid: number;
    carddiceid: number;
    moveid: number;
}

export const sendSelectMove = createAction<SendSelectMove>('sendSelectMove');

export interface SendMergeCards {
    playerid: number;
    horseid: number;
    added: number[];
    removed: number[];
}

export const sendMergeCards = createAction<SendMergeCards>('sendMergeCards');

export interface SendAddBonusCards {
    playerid: number;
    horseid: number;
}

export const sendAddBonusCards = createAction<SendAddBonusCards>('sendAddBonusCards');
