/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import React from 'react';
import { HorseColor, HorseColorValues } from '~/app';

const CardBonus = (color: HorseColor): JSX.Element => {
    const cardWidth = 158.2;
    const cardHeight = 249;
    const iconSize = cardWidth / 3;
    const iconX = (cardWidth - iconSize) / 2;
    const iconY = (cardHeight / 2 - iconSize) / 2;
    const textHeight = iconY / 2;
    const textX = cardWidth / 2;
    const textY = iconY + iconSize + iconY / 2;
    return (
        <g>
            <svg x={iconX} y={iconY} width={iconSize} height={iconSize} viewBox="0 0 42 42">
                <polygon
                    fill={HorseColorValues[color]}
                    points="42,20 22,20 22,0 20,0 20,20 0,20 0,22 20,22 20,42 22,42 22,22 42,22"
                />
            </svg>
            <text
                width={cardWidth}
                x={textX}
                y={textY}
                textAnchor="middle"
                alignmentBaseline="central"
                fontSize={textHeight}
                fill={HorseColorValues[color]}>
                Bonus
            </text>
        </g>
    );
};

export default CardBonus;
