/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import React, { memo } from 'react';
import { HorseColor, horseColorIndex, HorseColorValues } from '~/app';
import CardBonus from './CardBonus';
import CardJoker, { JokerBasis, JokerDeko, JokerNumberBackground } from './CardJoker';
import CardNumbers from './CardNumbers';

export type NumberCardValue = 3 | 4 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
export type JokerCardValue = -12 | -11 | -10 | -9;
export type CardValue = NumberCardValue | JokerCardValue | 0;

export type FromNumberCardValue<T = React.ReactElement<SVGGElement>> = { [k in NumberCardValue]: T };
export type FromNumberCardValueColored<T = React.ReactElement<SVGGElement>> = {
    [k in NumberCardValue]: (color: HorseColor) => T;
};
export type FromJokerCardValue<T = React.ReactElement<SVGGElement>> = { [k in JokerCardValue]: T };
export type FromJokerCardValueColored<T = React.ReactElement<SVGGElement>> = {
    [k in JokerCardValue]: (color: HorseColor) => T;
};
export type FromCardValue<T = React.ReactElement<SVGGElement>> = { [k in CardValue]: T };
export type FromCardValueColored<T = React.ReactElement<SVGGElement>> = {
    [k in CardValue]: (color: HorseColor) => T;
};

export const AllCards: CardValue[] = [-12, -11, -10, -9, 3, 4, 6, 7, 8, 9, 10, 11, 12, 0];

interface Props {
    value: CardValue;
    color: HorseColor;
    className?: string;
    style?: React.CSSProperties;
    onClick?: () => void;
    removed?: boolean;
}

const Card = ({ color = horseColorIndex.BLUE, value, removed = false, ...props }: Props) => (
    <svg baseProfile="basic" viewBox="0 0 158.2 249" {...props}>
        <g opacity={removed ? 0.3 : 1}>
            <path
                d="M12.6 0h133.1c6.9 0 12.6 5.6 12.6 12.6v223.8c0 6.9-5.6 12.6-12.6 12.6H12.6c-7 0-12.6-5.7-12.6-12.6V12.6C0 5.6 5.6 0 12.6 0z"
                fillRule="evenodd"
                clipRule="evenodd"
                fill="#fff"
            />
            <path
                d="M139.7 239.7c5.3 0 9.6-4.3 9.6-9.6V17.9c0-5.3-4.3-9.6-9.6-9.6H18.5c-5.3 0-9.6 4.3-9.6 9.6V230c0 5.3 4.3 9.6 9.6 9.6h121.2v.1z"
                fill="none"
                stroke={HorseColorValues[color]}
                strokeWidth={3.889}
            />

            {/* Joker */}
            {value < 0 && [
                JokerNumberBackground,
                JokerBasis,
                JokerDeko(color),
                CardJoker[value as JokerCardValue](color),
            ]}

            {/* Number */}
            {value > 0 && CardNumbers[value as NumberCardValue]}

            {/* Bonus */}
            {value === 0 && CardBonus(color)}
        </g>

        {/* Removed */}
        {removed && (
            <svg viewBox="0 0 256 256" x="16.6" width="125" height="249">
                <g transform="matrix(10.666666666666666,0,0,10.666666666666666,0,0)">
                    <path
                        d="M12,23.5A11.5,11.5,0,1,0,.5,12,11.513,11.513,0,0,0,12,23.5ZM7.05,8.465A1,1,0,1,1,8.464,7.051l3.359,3.359a.25.25,0,0,0,.353,0l3.359-3.359a1,1,0,1,1,1.414,1.414L13.59,11.824a.25.25,0,0,0,0,.353l3.359,3.359a1,1,0,1,1-1.414,1.414l-3.359-3.359a.25.25,0,0,0-.353,0L8.464,16.95A1,1,0,0,1,7.05,15.536l3.359-3.359a.25.25,0,0,0,0-.353Z"
                        fill="#ffffff"
                        stroke="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="0"
                    />
                </g>
            </svg>
        )}
    </svg>
);

export default memo(Card);
