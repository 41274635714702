// extracted by mini-css-extract-plugin
export var AcceptButtonDisabled = "n_W";
export var AcceptButtonEnabled = "n_X";
export var BarRightRoot = "n_M";
export var Dice = "n_N";
export var DicePulsating = "n_T";
export var DiceSelectable = "n_P";
export var DiceSelected = "n_Q";
export var DiceShuffling = "n_R";
export var Move = "n_Z";
export var barRightAcceptButtonBounceIn = "n_Y";
export var barRightMoveBounceIn = "n_0";
export var dicePulseAnimation = "n_V";
export var diceShuffleAnimation = "n_S";