/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import {
    selectCurrentHorse,
    selectCurrentState,
    selectHorses,
    selectOwnHorses,
    selectRound,
    selectTurnDicesPlayerId,
} from '~/app';
import HorseIcon from '~/components/HorseIcon';
import Turfmaster from '~/server/src/tmmodel';
import { useKeyPress } from '~/utils';
import * as overlayStyles from '../Overlay.module.css';
import * as styles from './BarLeft.module.css';

interface BarLeftProps {
    selectedOwnHorse: number;
    onOwnHorseSelected: (ownHorse: number) => void;
}

const BarLeft = ({ onOwnHorseSelected, selectedOwnHorse }: BarLeftProps) => {
    const currentRound = useSelector(selectRound);
    const currentState = useSelector(selectCurrentState);
    const turnDicesPlayerId = useSelector(selectTurnDicesPlayerId);
    const horses = useSelector(selectHorses);
    const ownHorses = useSelector(selectOwnHorses);
    const currentHorse = useSelector(selectCurrentHorse);

    const currentHorseIndex = currentHorse ? currentHorse.index : -1;
    const turnDicesPlayerHorsesId = horses
        .filter((horse) => horse.playerId === turnDicesPlayerId)
        .map((horse) => horse.index);

    // arrow up: select lower own horse
    useKeyPress(38, () => {
        selectedOwnHorse > 0 && onOwnHorseSelected(selectedOwnHorse - 1);
    });

    // arrow down: select higher own horse
    useKeyPress(40, () => {
        selectedOwnHorse < ownHorses.length - 1 && onOwnHorseSelected(selectedOwnHorse + 1);
    });

    // const [nextTimeoutDateString, setNextTimeoutDate] = useState('');
    // useEffect(() => {
    //     const timeout = setInterval(
    //         () => setNextTimeoutDate(new Date(new Date().getTime() + 5 * 1000).toString()),
    //         10 * 1000,
    //     );
    //     return () => clearInterval(timeout);
    // }, []);

    return (
        <div className={overlayStyles.BarLeft}>
            <div className={styles.BarLeftRoot}>
                {ownHorses.map((horse, index) => {
                    const selected =
                        // prevent horse from being selected while last turn in round is shown with a delay
                        currentState !== Turfmaster.stateMachine.SELECTMOVEOPP &&
                        // prevent horse being selected before first and after last round
                        currentRound >= 0 &&
                        currentRound < Turfmaster.rounds &&
                        currentState === Turfmaster.stateMachine.SELECTDICES
                            ? turnDicesPlayerHorsesId.includes(horse.index)
                            : horse.index === currentHorseIndex;
                    return (
                        <HorseIcon
                            key={horse.index}
                            className={selectedOwnHorse === index ? styles.BarLeftSelectedHorse : styles.BarLeftHorse}
                            selected={selected}
                            color={horse.color}
                            // nextTimeoutDateString={nextTimeoutDateString}
                            onClick={() => onOwnHorseSelected(index)}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default memo(BarLeft);
