import { signOut } from 'firebase/auth';
import { useCallback } from 'react';
import { LoginRequest, LoginResponse, RegistrationRequest } from '~/server/src/dtos';
import { postFn, queryClient, retry, useMutation, UseMutationOptions } from './api';
import { useFirebaseAnalytics, useFirebaseAuth } from './useFirebase';

export const postRegister = '/register';
export const postLogin = '/login';

export const useRegister = (options?: UseMutationOptions<LoginResponse, RegistrationRequest>) => {
    const analytics = useFirebaseAnalytics();
    return useMutation<LoginResponse, RegistrationRequest>((data) => postFn(postRegister, data), {
        ...options,
        onSuccess: (...response) => {
            queryClient.invalidateQueries({ predicate: () => true });
            options?.onSuccess?.(...response);
            analytics?.logEvent('sign_up', { method: 'email' });
        },
    });
};

export const useLogin = (options?: UseMutationOptions<LoginResponse, LoginRequest>) => {
    const analytics = useFirebaseAnalytics();
    return useMutation<LoginResponse, LoginRequest>((data) => postFn(postLogin, data), {
        ...options,
        retry:
            options?.retry ??
            ((failureCount, error) => {
                // don't retry not found response for login which is indicator for a necessary registration
                if (error.status === 404) {
                    return false;
                }
                return retry(failureCount, error);
            }),
        onSuccess: (...response) => {
            queryClient.invalidateQueries({ predicate: () => true });
            options?.onSuccess?.(...response);
            analytics?.logEvent('login', { method: 'email' });
        },
    });
};

export const useLogout = (onLogout: () => void) => {
    const auth = useFirebaseAuth();
    const analytics = useFirebaseAnalytics();
    return useCallback(async () => {
        try {
            if (auth) {
                await signOut(auth);
            }
            onLogout();
            analytics?.logEvent('logout', { method: 'email' });
        } catch (e) {
            console.log('logout error: ', e);
        }
    }, [analytics, auth, onLogout]);
};
