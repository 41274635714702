/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { createAction } from '@reduxjs/toolkit';
import { Coordinates, SetEntityPropertyParams } from 'app';
import { CameraMode, Horse } from './reducer';

/**
 * Set coordinates of all hurdles on the board. A hurdle is represented by an array
 * of {@link Coordinates}, so all hurdles are represented in a 2-dimensional array.
 */
export const setHurdles = createAction<Coordinates[][]>('setHurdles');

/**
 * Set coordinates of all possible moves the current horse could select. A move represented by an array
 * of {@link Coordinates}, so all moves are represented in a 2-dimensional array.
 */
export const setMoves = createAction<Coordinates[][]>('setMoves');

/**
 * Sets basic information about all horses on the board with an array of type {@link Horse}.
 */
export const setHorses = createAction<Horse[]>('setHorses');

/**
 * Sets basic information about a specific horse on the board type {@link Horse}.
 */
export const setHorse = createAction<Horse>('setHorse');

export interface HorseField extends Coordinates {
    move?: Coordinates[];
    pos: number;
    prog: number;
}

/**
 * Sets location of all horses on the board with an array of type {@link Coordinates}.
 */
export const setHorsesField = createAction<HorseField[]>('setHorsesField');

/**
 * Sets location a specific hors on the board with type {@link Coordinates}.
 */
export const setHorseField = createAction<SetEntityPropertyParams<HorseField>>('setHorseField');

export interface MoveCameraPayload {
    readonly x: number;
    readonly y: number;
    readonly scale: number;
}
export const moveCamera = createAction<MoveCameraPayload>('moveCamera');

export const setCameraMode = createAction<CameraMode>('setCameraMode');
