export const mockedAuthToken =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJqb2lucmFkYXIuY29tIiwiZXhwIjoxNjgxMzcxNzAyLCJ1c2VySWQiOiI3OGEzYzgwZS01ODc1LTQwZTMtYTA0My1jMjA2OGE1NmVmYzciLCJpYXQiOjE1OTQ5NzE3MDJ9.4yVXDP_hyDbVKsD1X3OCbLDuocnvpDgmV8Ow931JpOg';

const apiUrl = process.env.GATSBY_API;
const siteUrl = process.env.GATSBY_URL;

export const DEBUG_logWhyDidYouRender = false;

export const config = {
    baseUrl: apiUrl,
    siteUrl,

    // log every http request and response object with console.log
    apiLogEnabled: true,

    reduxLogEnabled: false,

    // enable / disable mock api per entity. disabled means requests will actually call the remote api
    useMockedAuthToken: false,
    accountApiMockEnabled: false,
    authApiMockEnabled: false,
    horsesApiMockEnabled: false,
    usersApiMockEnabled: false,
    toplistsApiMockEnabled: false,
    gamesApiMockEnabled: false,

    mockApiFallThroughRemote: true,

    // debug configuration
    enableMarkerView: false,

    // setup configuration
    freeGamePullInterval: 10 * 1000, // in ms
    tournamentHorseCount: 2,
    tournamentPlayerCount: 3,
    tournamentPullInterval: 10 * 1000, // in ms
    tournamentNextInterval: 12 * 60 * 60 * 1000, // in ms
};

export const gameActionWatchdogTimeout = 30 * 1000; // in ms

export const DEBUG_enabled =
    process.env.NODE_ENV === 'development' ||
    apiUrl?.includes('localhost') ||
    apiUrl?.includes('api-testing.turfmaster.de');

export enum Environments {
    LOCAL = 'local',
    TESTING = 'testing',
    STAGING = 'staging',
    PRODUCTION = 'production',
    UNKNOWN = 'unknown',
}

export const environment: Environments = (() => {
    switch (process.env.GATSBY_ENV) {
        case 'local':
            return Environments.LOCAL;
        case 'testing':
            return Environments.TESTING;
        case 'staging':
            return Environments.STAGING;
        case 'production':
            return Environments.PRODUCTION;
        default:
            return Environments.UNKNOWN;
    }
})();

export const isLocalEnvironment = environment === Environments.LOCAL;
export const isTestingEnvironment = environment === Environments.TESTING;
export const isStagingEnvironment = environment === Environments.STAGING;
export const isProductionEnvironment = environment === Environments.PRODUCTION;
export const isUnknownEnvironment = environment === Environments.UNKNOWN;
export const isAnalyticsEnabled = isProductionEnvironment;

export default config;
