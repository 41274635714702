import { FormControl, InputLabel, Link, MenuItem, Select, TextField } from '@mui/material';
import cn from 'classnames';
import React, { useState } from 'react';
import { useAccount, useSetUserRole, useSetUserSubscription, useSetUserToplistLeague, useUser } from '~/apis';
import { LeagueType, UserRole, UserSubscription } from '~/server/src/dtos';

interface DebugControlsProps {
    className?: string;
}

const DebugControls = ({ className }: DebugControlsProps): JSX.Element => {
    const { data: account } = useAccount();

    const [toplistLeague, setToplistLeague] = useState(account?.toplistLeague ?? LeagueType.Bronze);
    const [subscription, setSubscription] = useState(account?.subscription ?? UserSubscription.Basic);

    const [otherUserId, setOtherUserId] = useState('');
    const [otherToplistLeague, setOtherToplistLeague] = useState(LeagueType.Bronze);
    const [otherSubscription, setOtherSubscription] = useState(UserSubscription.Basic);

    const { data: otherUser } = useUser(otherUserId, { enabled: otherUserId.trim() !== '' });

    const setUserRoleApi = useSetUserRole();
    const setUserToplistLeague = useSetUserToplistLeague();
    const setUserSubscriptionApi = useSetUserSubscription();

    const setAccountRole = (value: UserRole) => {
        if (!account) return;
        setUserRoleApi.mutate({ userId: account.userId, role: value });
    };

    const setAccountToplistLeague = (value: LeagueType) => {
        if (!account) return;
        setToplistLeague(value as LeagueType);
        setUserToplistLeague.mutate({ userId: account.userId, toplistLeague: value });
    };

    const setAccountSubscription = (value: UserSubscription) => {
        if (!account) return;
        setSubscription(value as UserSubscription);
        setUserSubscriptionApi.mutate({
            userId: account.userId,
            subscription: value,
            subscriptionValidUntil: new Date(new Date().getTime() + 1000000000000).toString(),
        });
    };

    const setOtherUserStatus = (value: LeagueType) => {
        if (!otherUser) return;
        setOtherToplistLeague(value);
        setUserToplistLeague.mutate({ userId: otherUserId, toplistLeague: value });
    };

    const setOtherUserSubscription = (value: UserSubscription) => {
        if (!otherUser) return;
        setOtherSubscription(value);
        setUserSubscriptionApi.mutate({
            userId: otherUserId,
            subscription: value,
            subscriptionValidUntil: new Date(new Date().getTime() + 1000000000000).toString(),
        });
    };

    return (
        <div className={cn('flex flex-col space-y-4 lg:space-y-0 lg:flex-row lg:space-x-8', className)}>
            <div className="lg:flex-1 flex flex-col space-y-2">
                <h2 className="font-bold">Eigener Account</h2>
                <div className="flex flex-col">
                    <div className="mb-4 text-sm">
                        {account?.role === UserRole.Admin ? (
                            <div>
                                <span>Du bist Admin.</span>{' '}
                                <Link
                                    underline="hover"
                                    className="cursor-pointer"
                                    onClick={() => setAccountRole(UserRole.User)}>
                                    Werde wieder normal
                                </Link>
                            </div>
                        ) : (
                            <div>
                                <span className="text-red-600">Du bist kein Admin.</span>{' '}
                                <Link
                                    underline="hover"
                                    className="cursor-pointer"
                                    onClick={() => setAccountRole(UserRole.Admin)}>
                                    Werde Admin
                                </Link>
                            </div>
                        )}
                    </div>

                    <FormControl>
                        <InputLabel>Setze eigenen Toplist Typen</InputLabel>
                        <Select
                            value={toplistLeague}
                            onChange={({ target: { value } }) => setAccountToplistLeague(value as LeagueType)}
                            disabled={!account}>
                            <MenuItem value={LeagueType.Bronze}>Bronze</MenuItem>
                            <MenuItem value={LeagueType.Silver}>Silber</MenuItem>
                            <MenuItem value={LeagueType.Gold}>Gold</MenuItem>
                        </Select>
                    </FormControl>

                    <div className="h-2" />

                    <FormControl>
                        <InputLabel>Setze Subscription Level</InputLabel>
                        <Select
                            value={subscription}
                            onChange={({ target: { value } }) => setAccountSubscription(value as UserSubscription)}
                            disabled={!account}>
                            <MenuItem value={UserSubscription.Basic}>Basis</MenuItem>
                            <MenuItem value={UserSubscription.Premium}>Premium</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>

            <div className="flex flex-col flex-1 space-y-2">
                <h2 className="font-bold">Anderer Account</h2>
                <div className="flex flex-col">
                    <TextField
                        value={otherUserId}
                        onChange={({ target: { value } }) => setOtherUserId(value)}
                        label="UserID"
                        placeholder="0de7e905-cc4d-4db7-8d4c-15976c9940b2"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />

                    <div className="h-2" />

                    <FormControl>
                        <InputLabel>
                            {otherUser?.username
                                ? `Setze Toplist Typen von Nutzer "${otherUser.username ?? '???'}"`
                                : 'Setze Toplist Typen *Bitte gültige UserID eingeben*'}
                        </InputLabel>

                        <Select
                            style={
                                otherUserId.length === 0 && otherUser?.username !== undefined ? { opacity: 0.2 } : {}
                            }
                            disabled={otherUserId.length === 0 || otherUser?.username === undefined}
                            value={otherToplistLeague}
                            onChange={({ target: { value } }) => setOtherUserStatus(value as LeagueType)}>
                            <MenuItem value={LeagueType.Bronze}>Bronze</MenuItem>
                            <MenuItem value={LeagueType.Silver}>Silber</MenuItem>
                            <MenuItem value={LeagueType.Gold}>Gold</MenuItem>
                        </Select>
                    </FormControl>

                    <div className="h-2" />

                    <FormControl>
                        <InputLabel>
                            {otherUser?.username
                                ? `Setze Subscription von Nutzer "${otherUser.username ?? '???'}"`
                                : 'Setze Subscription *Bitte gültige UserID eingeben*'}
                        </InputLabel>

                        <Select
                            style={
                                otherUserId.length === 0 && otherUser?.username !== undefined ? { opacity: 0.2 } : {}
                            }
                            disabled={otherUserId.length === 0 || otherUser?.username === undefined}
                            value={otherSubscription}
                            onChange={({ target: { value } }) => setOtherUserSubscription(value as UserSubscription)}>
                            <MenuItem value={UserSubscription.Basic}>Basis</MenuItem>
                            <MenuItem value={UserSubscription.Premium}>Premium</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </div>
        </div>
    );
};

export default DebugControls;
