/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

export interface RouteMatchParams {
    mode: string;
}

export interface Coordinates {
    readonly x: number;
    readonly y: number;
}

export interface Orientation {
    readonly scale: number;
    readonly rotation: number;
}

export type Position = Coordinates & Orientation;

export type HorseColor = number;

export const horseColorIndex = {
    GREEN: 0,
    RED: 1,
    YELLOW: 2,
    BLUE: 3,
    PURPLE: 4,
    GREY: 5,
    BLACK: 6,
    ORANGE: 7,
};

export const HorseColorValues = {
    [horseColorIndex.GREEN]: '#3aaa35',
    [horseColorIndex.RED]: '#dd0e1b',
    [horseColorIndex.YELLOW]: '#d9cc3f',
    [horseColorIndex.BLUE]: '#488ccb',
    [horseColorIndex.PURPLE]: '#8d5ba1',
    [horseColorIndex.GREY]: '#737373',
    [horseColorIndex.BLACK]: '#000000',
    [horseColorIndex.ORANGE]: '#f39200',
};

export const PathColorValues: { [key: number]: string } = {
    0: '#3aaa35',
    1: '#dd0e1b',
    2: '#d9cc3f',
    3: '#488ccb',
    4: '#8d5ba1',
    5: '#737373',
    6: '#22691f',
    7: '#08407f',
    8: '#f39200',
    9: '#850d14',
};

export const AllHorseColorValues = [...Object.values(HorseColorValues)];

export interface SetEntityPropertyParams<T> {
    index: number;
    value: T;
}
