import { FirebaseApp } from '@firebase/app';
import { Auth } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { FirebaseAnalytics, getFirebase, getFirebaseAnalytics, getFirebaseAuth } from './firebase';

export const useFirebase = (): FirebaseApp | undefined => {
    const [instance, setInstance] = useState<FirebaseApp | undefined>(undefined);

    useEffect(() => {
        const firebase = getFirebase();
        if (firebase) setInstance(firebase);
    }, []);

    return instance;
};

export const useFirebaseAuth = (): Auth | undefined => {
    const [instance, setInstance] = useState<Auth | undefined>(undefined);

    useEffect(() => {
        const auth = getFirebaseAuth();
        if (auth) setInstance(auth);
    }, []);

    return instance;
};

export const useFirebaseAnalytics = (): FirebaseAnalytics | undefined => {
    const [analytics, setAnalytics] = useState<FirebaseAnalytics | undefined>(undefined);

    useEffect(() => {
        const analytics = getFirebaseAnalytics();
        if (analytics) setAnalytics(analytics);
    }, []);

    return analytics;
};
