/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { useCopyToClipboard } from '@uidotdev/usehooks';
import React, { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';
import { useFirebaseAnalytics } from '~/apis/useFirebase';
import {
    config,
    selectCurrentHorse,
    selectCurrentHorseIndex,
    selectCurrentState,
    selectGameId,
    selectGameType,
    selectHorses,
    selectIsOwnHorseIndexCurrent,
    selectOwnHorses,
    selectPlayerId,
    selectRound,
    selectSelectedCardIndexLocal,
    selectTurnType,
    useLocaleNavigation,
    useSelector,
} from '~/app';
import { GameType } from '~/server/src/dtos';
import Turfmaster from '~/server/src/tmmodel';
import { useOnStateChange } from '~/utils';
import ActionUI from './action-ui/ActionUI';
import { sendSelectCardDice, sendSelectMove, setSelectedCardIndexLocal } from './actions';
import BarBottom from './bar-bottom/BarBottom';
import BarLeft from './bar-left/BarLeft';
import BarRight from './bar-right/BarRight';
import BarTop from './bar-top/BarTop';
import { FloatingMenuItemKey } from './bar-top/FloatingMenu';
import MergeView from './merge/MergeView';
import * as styles from './Overlay.module.css';
import Report from './report/Report';
import Statistics, { StatisticsModalState } from './statistics/Statistics';

interface OverlayProps {
    onCloseHandler: () => void;
}

const Overlay = ({ onCloseHandler }: OverlayProps): JSX.Element => {
    const analytics = useFirebaseAnalytics();

    const { navigate } = useLocaleNavigation();

    const currentState = useSelector(selectCurrentState);
    const playerId = useSelector(selectPlayerId);
    const turnType = useSelector(selectTurnType);
    const gameId = useSelector(selectGameId);
    const gameType = useSelector(selectGameType);
    const round = useSelector(selectRound);
    const horses = useSelector(selectHorses);
    const ownHorses = useSelector(selectOwnHorses);
    const currentHorse = useSelector(selectCurrentHorse);
    const currentHorseIndex = useSelector(selectCurrentHorseIndex);

    const selectedCardIndexLocal = useSelector(selectSelectedCardIndexLocal);

    const [statisticsModalState, setStatisticsModalState] = useState<StatisticsModalState>('closed');
    const [reportModalOpen, setReportModalOpen] = useState(false);
    const [selectedOwnHorseIndex, setSelectedOwnHorseIndex] = useState(0);

    const isOwnHorseIndexCurrent = useSelector((state) => selectIsOwnHorseIndexCurrent(state, selectedOwnHorseIndex));

    const [, copyToClipboard] = useCopyToClipboard();

    const dispatch = useDispatch();

    /**
     * Helper function to wrap sendSelectCardDice event to fill in all necessary parameters
     */
    const setSelectedCardDice = useCallback(
        (index) => {
            dispatch(sendSelectCardDice({ playerid: playerId, horseid: currentHorseIndex, carddiceid: index }));
        },
        [currentHorseIndex, dispatch, playerId],
    );

    /**
     * If it is our turn to select a card and the right card deck is shown then
     * tell the controller when a card selection takes place
     * @param index index of the selected card
     */
    const onCardSelected = (index: number) => {
        if (
            horses &&
            currentHorse &&
            ![Turfmaster.stateMachine.MERGECARDS, Turfmaster.stateMachine.MERGECARDSOPP].includes(currentState)
        ) {
            const selectedHorse = horses.filter((horse) => horse.playerId === playerId)[selectedOwnHorseIndex];
            if (
                turnType === Turfmaster.turntypes.CARDS &&
                currentHorse.playerId === playerId &&
                currentHorse === selectedHorse
            ) {
                batch(() => {
                    if (selectedCardIndexLocal !== index) {
                        dispatch(setSelectedCardIndexLocal(index));
                        setSelectedCardDice(index);
                    }
                });
            }
        }
    };

    /**
     * Selection of own horse in left bar to switch between card decks
     */
    const onOwnHorseSelected = useCallback(
        (ownHorse) => {
            batch(() => {
                setSelectedOwnHorseIndex(ownHorse);
                dispatch(setSelectedCardIndexLocal(-1));
                if (turnType === Turfmaster.turntypes.CARDS) setSelectedCardDice(-1);
            });
            analytics?.logEvent('view_horse_cards', {
                round,
                turnType,
                gameType,
                isCurrent: isOwnHorseIndexCurrent,
            });
        },
        [analytics, dispatch, gameType, isOwnHorseIndexCurrent, round, setSelectedCardDice, turnType],
    );

    const onCardMoveSelected = (index: number) => {
        batch(() => {
            dispatch(
                sendSelectMove({
                    playerid: playerId,
                    horseid: currentHorseIndex,
                    carddiceid: selectedCardIndexLocal,
                    moveid: index,
                }),
            );
            dispatch(setSelectedCardIndexLocal(-1));
        });
    };

    // someone is currently throwing the dices
    const isDiceSelection =
        turnType === Turfmaster.turntypes.DICES && currentState === Turfmaster.stateMachine.SELECTDICES;

    // dices shuffle animation
    const [isDiceShuffling, setIsDiceShuffling] = useState(false);
    useEffect(() => {
        if (isDiceSelection) {
            setIsDiceShuffling(true);
            setTimeout(() => setIsDiceShuffling(false), 3000);
        }
    }, [isDiceSelection]);

    // if the current horse is ours, auto switch to it's cards
    useEffect(() => {
        if (currentHorse && currentHorse.playerId === playerId)
            setSelectedOwnHorseIndex(ownHorses.indexOf(currentHorse));
    }, [currentHorse, ownHorses, playerId]);

    useOnStateChange(
        currentState,
        useCallback((newCurrentState) => {
            if (newCurrentState === Turfmaster.stateMachine.ENDROUND) {
                setStatisticsModalState('timed');
            }
        }, []),
    );

    const onFloatingMenuItemClicked = useCallback(
        (key: FloatingMenuItemKey) => {
            switch (key) {
                case 'table':
                    navigate('/table', { openInNewTab: true });
                    analytics?.logEvent('open_table');
                    break;
                case 'worldRankings':
                    navigate('/toplist', { openInNewTab: true });
                    analytics?.logEvent('open_worldRankings');
                    break;
                case 'statistics':
                    setStatisticsModalState('open');
                    analytics?.logEvent('show_statistics');
                    break;
                case 'link':
                    if (gameId) {
                        if (
                            gameType === GameType.FreeBronze ||
                            gameType === GameType.FreeSilver ||
                            gameType === GameType.FreeGold
                        ) {
                            copyToClipboard(`${config.siteUrl}/play/game/${gameId}`);
                        } else if (
                            gameType === GameType.TournamentBronze ||
                            gameType === GameType.TournamentSilver ||
                            gameType === GameType.TournamentGold
                        ) {
                            copyToClipboard(`${config.siteUrl}/play/tournament/${gameId}`);
                        }
                    }
                    break;
                case 'report':
                    setReportModalOpen(true);
                    break;
                case 'close':
                    onCloseHandler();
                    break;
            }
        },
        [analytics, copyToClipboard, gameId, gameType, navigate, onCloseHandler],
    );

    return (
        <div className={styles.OverlayContainer}>
            <ActionUI />
            <div className={styles.Overlay}>
                <BarBottom
                    selectedCardLocalIndex={selectedCardIndexLocal}
                    selectedOwnHorseIndex={selectedOwnHorseIndex}
                    onCardSelected={onCardSelected}
                />
                <BarLeft selectedOwnHorse={selectedOwnHorseIndex} onOwnHorseSelected={onOwnHorseSelected} />
                <BarRight onCardMoveSelected={onCardMoveSelected} isDiceShuffling={isDiceShuffling} />
                <BarTop isDiceShuffling={isDiceShuffling} onFloatingMenuItemClicked={onFloatingMenuItemClicked} />
            </div>

            <Statistics state={statisticsModalState} onClose={() => setStatisticsModalState('closed')} />
            <Report isOpen={reportModalOpen} onClose={() => setReportModalOpen(false)} />
            <MergeView />
        </div>
    );
};

export default Overlay;
