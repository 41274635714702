/**
 * @author      Michael Hettmer <mail@michael-hettmer.de>
 * @copyright   2019 Plusbyte UG (haftungsbeschränkt)
 * @license     {@link https://plusbyte.de Plusbyte License}
 */

import { useCallback, useEffect, useRef } from 'react';
import { usePreviousBooleanArray, usePreviousNumberArray } from 'react-hooks-use-previous';
import { useSelector } from 'react-redux';
import {
    selectCurrentHorseIndex,
    selectCurrentRoundWinnerHorse,
    selectCurrentState,
    selectHandiFailureHorseIndex,
    selectHorses,
    selectHorsesBonuscards,
    selectHorsesDropped,
    selectPlayerId,
    selectRound,
} from '~/app';
import Turfmaster from '~/server/src/tmmodel';
import { useOnStateChange } from '~/utils';

/**
 * Checks the given arrays for changes and makes a comparison between the values.
 * If a change is detected the specified callback function gets called with the index
 * as a function parameter.
 *
 * @param callback function that gets executed when an array change is detected
 */
export const useArrayChangeTrigger = <T>(first: T[], second: T[], callback: (index: number[]) => void): void => {
    useEffect(() => {
        // make sure used properties are valid and we are not in the initial effect execution
        if (first && second && first.length && second.length) {
            // fire if different length or if something changed
            if (first.length !== second.length) {
                callback([]);
            } else {
                // check each property against the previous and fire with all changes detected
                const result: number[] = [];
                first.forEach((dropped, index) => {
                    if (dropped !== second[index]) result.push(index);
                });
                callback(result);
            }
        }
    }, [callback, first, second]);
};

/**
 * Checks the local redux game state for changes in horsesDropped and makes a comparison
 * with the previous version. If a change is detected the specified callback function gets
 * called with the horseId as the function parameter.
 *
 * @param callback function that gets executed when a horse is dropped with the horse id
 */
export const useHorseDroppedTrigger = (callback: (horseIndex: number) => void): void => {
    const horsesDropped = useSelector(selectHorsesDropped);
    const prevHorsesDropped = usePreviousBooleanArray(horsesDropped);
    useArrayChangeTrigger(horsesDropped, prevHorsesDropped, (horseIndices: number[]) => {
        if (horseIndices.length && horsesDropped[horseIndices[0]]) callback(horseIndices[0]);
    });
};

/**
 * Checks the local redux game state for changes in horsesFinished and makes a comparison
 * with the previous version. If a change is detected the specified callback function gets
 * called with the horseId if the horse is actually the winner of the current round
 * as the function parameter.
 *
 * @param callback function that gets executed when a horse has finished with the horse id
 */
export const useHorseWinsTrigger = (callback: (horseIndex: number) => void): void => {
    const currentRoundWinnerHorse = useSelector(selectCurrentRoundWinnerHorse);
    const prevCurrentRoundWinnerHorse = useRef(currentRoundWinnerHorse);

    useEffect(() => {
        if (prevCurrentRoundWinnerHorse.current !== currentRoundWinnerHorse && currentRoundWinnerHorse >= 0)
            callback(currentRoundWinnerHorse);
    }, [callback, currentRoundWinnerHorse]);

    useEffect(() => {
        prevCurrentRoundWinnerHorse.current = currentRoundWinnerHorse;
    }, [currentRoundWinnerHorse]);
};

/**
 * Checks the local redux game state if the bonus cards of a horse got automatically merged in the last round because
 * they weren't used before by the player.
 *
 * @param callback gets exectuted when the bonus cards of one of the players' horses get automatically merged with the
 * associated horseid as a parameter
 */
export const useOwnHorseAutomatedBonusCardsInLastRoundTrigger = (callback: (horseIndex: number) => void): void => {
    const playerId = useSelector(selectPlayerId);
    const horses = useSelector(selectHorses);
    const round = useSelector(selectRound);
    const horseBonusCards = useSelector(selectHorsesBonuscards);
    const prevHorseBonusCards = usePreviousNumberArray(horseBonusCards);

    useArrayChangeTrigger(horseBonusCards, prevHorseBonusCards, (horseIndices: number[]) => {
        if (
            round === Turfmaster.rounds - 1 &&
            horseIndices.length &&
            horses[horseIndices[0]].playerId === playerId &&
            horseBonusCards[horseIndices[0]] === round
        )
            callback(horseIndices[0]);
    });
};

/**
 * Checks the local redux game state for changes in the round property and triggers the callback if a new round started.
 * Ignores the first (index = 0) and last (index = 3) round.
 *
 * @param callback gets executed when a new round starts with the round index as a paramter
 */
export const useStartRoundTrigger = (callback: (round: number) => void): void => {
    const round = useSelector(selectRound);
    const prevRound = useRef(round);

    useEffect(() => {
        if (prevRound.current !== round && round > 0 && round < Turfmaster.rounds) callback(round);
    }, [callback, round]);

    useEffect(() => {
        prevRound.current = round;
    }, [round]);
};

/**
 * Checks the local redux game state for changes in the handicap failure flag and fires if it is true.
 * Evaluation of flag is executed in gameUpdateSaga.ts.
 *
 * @param callback gets executed when a horse has used a card which is higher or equal than the horse's handicap
 */
export const useCardAboveHandiTrigger = (callback: (horseIndex: number) => void): void => {
    const handiFailureHorseIndex = useSelector(selectHandiFailureHorseIndex);
    const prevHandiFailureHorseIndex = useRef(handiFailureHorseIndex);

    useEffect(() => {
        if (prevHandiFailureHorseIndex.current !== handiFailureHorseIndex && handiFailureHorseIndex >= 0) {
            callback(handiFailureHorseIndex);
        }
    }, [callback, handiFailureHorseIndex, prevHandiFailureHorseIndex]);

    useEffect(() => {
        prevHandiFailureHorseIndex.current = handiFailureHorseIndex;
    }, [handiFailureHorseIndex]);
};

/**
 * Triggers when ADDBONUSCARDSOPP event is fired.
 *
 * @param callback gets executed when a horse has used a card which is higher or equal than the horse's handicap
 */
export const useBonusCardTrigger = (callback: (horseIndex: number) => void): void => {
    const currentState = useSelector(selectCurrentState);
    const currentHorseIndex = useSelector(selectCurrentHorseIndex);

    useOnStateChange(
        currentState,
        useCallback(
            (newState) => {
                console.log(newState, Turfmaster.stateMachine.ADDBONUSCARDSOPP);
                if (newState === Turfmaster.stateMachine.ADDBONUSCARDSOPP) callback(currentHorseIndex);
            },
            [callback, currentHorseIndex],
        ),
    );
};
